import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Link, useLocation } from 'react-router-dom';
import TextField from '../../components/common/form/texField/textField';

import './signinPage.scss'

import SuspenseView from '../../components/suspense-view';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import useStyles from './styles';

const btnStyles = {
  boxShadow: 'none',
  background: 'rgb(26, 80, 161)',
  color: 'white',
  padding: '.8rem 1.3rem'
}

export default function SignInPage() {
  const classes = useStyles();
  const location  = useLocation();

  const { goToPage, resendSignUp, signIn } = useAuthDataContext();
  const { t, ready } = useTranslation([
    'authentication',
    'common',
    'error',
    'form',
  ]);
  let { from } = location.state || { from: { pathname: '/dashboard' } };

  const initialValues = { email: '', password: '' };
  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(t('form:required')),
    password: Yup.string().required(t('form:required')),
  });

  const [confirmUsername, setConfirmUsername] = useState('');

  const onSubmit = async (values, actions) => {
    const { success, awsErrorCode, challengeName } = await signIn(
      values.email,
      values.password
    );
    if (success) {
      goToPage(from);
    } else if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      goToPage(`/set-password/${values.email}`);
    } else {
      if (awsErrorCode === 'UserNotConfirmedException') {
        setConfirmUsername(values.email);
      } else {
        actions.setStatus({ msg: t('error:invalidCredentials') });
      }
    }
  };

  const handleConfirmAccount = () => {
    resendSignUp(confirmUsername);
    goToPage(`/signup/${confirmUsername}`);
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className='SignInPage-Container'>
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, isValid }) => (
          <Form className='SignInPage-Container__form'>
            <div className='SignInPage-Container__form__title'>
              {t('authentication:signIn')}
            </div>

            <Field
              name='email'
              required
              component={TextField}
              placeholder={t('authentication:email')}
            />

            <Field
              name='password'
              required
              component={TextField}
              placeholder={t('authentication:password')}
              type={'password'}
            />
            {status && status.msg && (
              <Typography className={classes.error}>{status.msg}</Typography>
            )}

            <Button
              style={btnStyles}
              id='btn-back'
              variant='contained'
              type='submit'
              disabled={!isValid || isSubmitting}
            >
              {t('form:submit')}
            </Button>
          </Form>
        )}
      </Formik>
      <div className='SignInPage-Container__forgot-pass'>
        <Link to={'/forgot-password'}>
          {t('authentication:forgotPassword')}
        </Link>
      </div>

      {/* <div className={classes.formLabelLinkContainer}>
        <label htmlFor='noAccount'>{t('authentication:noAccount')}</label>
        <Link to={'/signup'}>{t('authentication:signUp')}</Link>
      </div> */}

      <Dialog
        open={!!confirmUsername}
        onClose={handleConfirmAccount}
        aria-labelledby='confirm-account-dialog-title'
        aria-describedby='confirm-account-dialog-description'
      >
        <DialogTitle id='confirm-account-dialog-title' disableTypography={true}>
          <Typography variant='h6' color='inherit'>
            {t('authentication:dialogTitleAccountNotConfirmedYet')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-account-dialog-description'>
            {t('authentication:dialogMessageAccountNotConfirmedYet')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmAccount} color='primary' autoFocus>
            {t('common:ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SignInPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
