import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { AiOutlineSearch } from 'react-icons/ai';

import UserServiceListItemView from '../user-service-list-item-view';
import { useMediaQuery } from '../../../company-details/mediaQueries';
import { divStyles, inputStyles, typographyStyles, divStylesMedia } from './styles';
import { useTranslation } from 'react-i18next';
import { getUserNeedsList } from '../../../../../api/user-need';



export default function UserServiceListView({ userId }) {

  const [data, setData] = useState(null)

  useEffect(async () => {
    const res = await getUserNeedsList(userId)
    setData(res.data)
  }, []);


  const [searchText, setSearchText] = useState('')
  const isMedia = useMediaQuery("(max-width: 900px)");
  // let numberOfServices = _.get(services, 'length', 0);

  const { t } = useTranslation(['common', 'error', 'profile']);

  return (
    <div>
      <div style={isMedia ? divStylesMedia : divStyles}>
        <Typography
          style={typographyStyles}
          variant='h6'>
          {t('profile:lblMyServices', {
            num: data?.length,
          })}
        </Typography>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <input
            className='ServiceTeamTab__input'
            style={inputStyles}
            type='text'
            placeholder='Search Services'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <AiOutlineSearch
            style={{
              fontSize: '2rem',
              transform: 'translateX(-28px)',
              color: 'rgb(26, 80, 161)',
              opacity: '.3'
            }} />
        </div>
      </div>
      <div className='ServiceTeamTab__container'>
        {data && data
          .filter((entry) => {
            if (entry.name.toLowerCase().includes(searchText.toLowerCase())) {
              return (
                <UserServiceListItemView
                  key={entry.id}
                  details={entry}
                />
              );
            } else if (searchText === '') {
              return (
                <UserServiceListItemView
                  key={entry.id}
                  details={entry}
                />
              )
            }
          }).map((entry) => (
            <UserServiceListItemView
              key={entry.id}
              details={entry}
            />
          ))}
      </div>
    </div>
  );
}

UserServiceListView.propTypes = {
  services: PropTypes.array.isRequired,
  userId: PropTypes.number
};
