import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import useStyles, { btnStyles, notActiveStyles } from './styles';
import ClientServiceNeeds from './clientServiceNeeds'
import ClientDetails from './clientDetails';

import ClientSummary from './clientSummary';
import PropTypes from 'prop-types';
import { useMediaQuery } from '../../company-details/mediaQueries';

import { useNavigate } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';



const ClientOnboardingForm = ({ client }) => {
  const navigate = useNavigate();
  const { email } = client;
  const isMobile = useMediaQuery('(max-width: 550px)')
  const { t } = useTranslation(['common', 'client']);

  const steps = [t('client:lblProfileDetails'), t('client:lblSelectNeeds'), t('client:lblReviewProfile')];

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: email,
    address: '',
    dateOfBirth: {
      month: '',
      day: '',
      year: ''
    },
    phoneNumber: '',
    gender: 'Female',
    needs: [],
    language: '',
    medicareNumber: '',
  })

  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const [errors, setErrors] = useState(null)
  const [dateErr, setDateErr] = useState('')
  const [dateErrObj, setDateErrObj] = useState(null)

  const handleFirstForm = () => {
    if (values.firstName === '' || values.lastName === '' || values.address === '' || values.phoneNumber === '') {
      validateForms()
      validateDate()
    }
    else if (validateDate()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setErrors(null)

    } else {
      setErrors(null)
      validateForms()
      validateDate()
    }
  }

  const validateForms = () => {
    let errors = {};
    for (const value in values) {
      if (values[value] === '') {
        errors[value] = 'Field is required*'
      }
      window.scrollTo(0, 300)
      setErrors(errors)
    }
  }

  const validateDate = () => {
    if (dateErr !== '' || dateErrObj !== null) {
      setDateErr('')
      setDateErrObj(null)
    }
    let errors = {};

    const { dateOfBirth } = values;
    const { month, day, year } = dateOfBirth;
    if (month === '' || day === '' || year === '') {
      for (const value in dateOfBirth) {
        errors[value] = dateOfBirth[value] === '' ? `${value} is a required field` : ''
      }
      setDateErr('')
      setDateErrObj(errors)
    } else {
      setDateErrObj(null)
      return moment(`${month}/${day}/${year}`, "MM/DD/YYYY", true).isValid()
        ? true
        : setDateErr(`${month}/${day}/${year} is not a valid date.`);
    }

  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleReset = () => {
    setActiveStep(0);
  };


  const handleFinish = (e) => {
    e.preventDefault()
    navigate('/profile', { replace: true })
  }
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1rem'
    }}>
      <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel id='label'>
                {isMobile ? null : (
                  <p style={notActiveStyles}>{label}</p>
                )}

              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 ? (
            <ClientDetails
              values={values}
              setValues={setValues}
              errors={errors}
              dateErr={dateErr}
              dateErrObj={dateErrObj}
            />
          ) : null}
          {activeStep === 1 ? (
            <ClientServiceNeeds
              values={values}
              setValues={setValues}
            />
          ) : null}
          {activeStep === 2 ? (
            <ClientSummary
              values={values}
              setValues={setValues} />
          ) : null}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginBottom: '2rem', width: '100%', maxWidth: '53rem' }}>
            {activeStep === 0 ? (
              null
            ) : (<Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              id='btn-back'
            >
              {t('common:btnBack')}
            </Button>)}

            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === 0 ? (
              <Button style={btnStyles} onClick={handleFirstForm}>
                {t('common:btnNext')}
              </Button>
            ) : null}
            {activeStep === 1 ? (
              <Button style={btnStyles} onClick={handleNext}>
                {t('common:btnNext')}
              </Button>
            ) : null}
            {activeStep === steps.length - 1 ? (
              <Button style={btnStyles} onClick={(e) => handleFinish(e)}>
                {t('common:btnSubmit')}
              </Button>
            ) : (
              null
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

ClientOnboardingForm.propTypes = {
  client: PropTypes.object.isRequired
};

export default ClientOnboardingForm;