import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { removeClientFromProvider } from '../../../../api/provider-client';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { buttonStyles, dialogContentStyles } from '../../../dialogs/customStyles/customStyles';

import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { HiDotsHorizontal } from 'react-icons/hi';
import { FaRegTrashAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';


const divStyles = {
  display: 'flex', alignItems: 'center'
}

const textStyles = {
  marginRight: '1rem',
  color: 'gray'
}

const iconStyles = {
  fontSize: '1.2rem',
  marginLeft: '1rem',
  color: 'gray',
  opacity: '.8'
}

export default function MoreOptionsDialog({ providerId, userId, setLastUpdated }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation('common', 'error');

  const [openDelete, setOpenDelete] = useState(false);

  const openConfirmDeleteModel = () => {
    setOpenDelete(true);
  };

  const closeConfirmDeleteModel = () => {
    setOpenDelete(false);
  };

  const handleConfirmYes = async (providerId, userId) => {
    const { success, errorMessage } = await removeClientFromProvider(
      providerId,
      userId
    );
    if (success) {
      setLastUpdated();
    } else {
      // TODO: Show error message on the screen
      console.log(errorMessage);
    }
  };

  // Menu Handling Events
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <React.Fragment>
      <div>
        <IconButton
          aria-label={t('common:lblAccount')}
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <HiDotsHorizontal style={{ color: 'rgb(56, 172, 201)' }} />
        </IconButton>
        <Menu
          id='menu-appbar'
          elevation={0}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem>
            <div
              style={divStyles}
              onClick={openConfirmDeleteModel}
            >
              <p style={textStyles}>{t('provider:lblRemoveClient')}</p>
              <FaRegTrashAlt style={iconStyles} />
            </div>
          </MenuItem>

        </Menu>
      </div>
      <Dialog
        open={openDelete}
        onClose={closeConfirmDeleteModel}
        maxWidth={'xs'}
        aria-labelledby='user-removal-dialog-title'
        aria-describedby='user-removal-dialog-description'
      >

        <DialogContent>
          <DialogContentText style={dialogContentStyles} id='user-removal-dialog-description'>
            {t('provider:lblConfirmRemoveClientMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={buttonStyles}
            onClick={handleConfirmYes.bind(this, providerId, userId)}>
            {t('common:yes')}
          </Button>
          <Button
            style={buttonStyles}
            onClick={closeConfirmDeleteModel} autoFocus>
            {t('common:no')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

MoreOptionsDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  providerId: PropTypes.number.isRequired,
  setLastUpdated: PropTypes.func.isRequired
};
