import React from 'react';
import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';

const PhoneNumberMask = ({ inputRef, ...props }) => (
  <InputMask
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask='(999) 999-9999'
  />
);

PhoneNumberMask.propTypes = {
  inputRef: PropTypes.func,
};

export default PhoneNumberMask;
