import React from 'react';

import SuspenseView from '../../../components/suspense-view';
import TierCard from './TierCard';
import { useTranslation } from 'react-i18next';
import { AiFillStar } from 'react-icons/ai'


const tiers = [
  {
    title: 'Seniors',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Service Provider',
    subheader: 'Most popular',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise Clients',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

const h1Styles = {
  fontSize: '3.5rem',
  color: 'rgb(26, 80, 161)',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: '600'
}

const containerStyles = {
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap-reverse'
}

function HomePage() {
  const { t, ready } = useTranslation('common');

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <React.Fragment>

      <div style={{ marginTop: '1rem' }}>
        <h1 style={h1Styles}>
          {t('common:lblServUsHealth')}
        </h1>
      </div>
      <div style={containerStyles}>

        <TierCard className={'TierCard ord-2'} tier={tiers[0]} />
        <TierCard icon={<AiFillStar />} className={'TierCard xtra ord-1'} tier={tiers[1]} />
        <TierCard className={'TierCard ord-3'} tier={tiers[2]} />

      </div>

    </React.Fragment>
  );
}

export default HomePage;
