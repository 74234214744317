import React from 'react';
import PropTypes from 'prop-types';

// TODO: Add code that prompts the user that if they move the map marker and the address changes that
//they have to confirm the new address details.
//As of right now the new address is not saved to the DB it is just displayed on the info card
export default function CustomInfoDisplay({
  // lat,
  // lng,
  branch,
  addressDetails,
  // status,
  flag,
}) {

  const titleStyles = {
    fontSize: '1.5rem',
    fontWeight: '600',
    textTransform: 'capitalize',
    color: 'rgb(26, 80, 161)',
    marginBottom: '.5rem'
  }

  const textStyles = {
    color: 'black',
    marginBottom: '.5rem'
  }
  return (
    <div style={{ padding: '1rem' }}>
      {flag ? (
        <React.Fragment>
          <p style={titleStyles}>{branch}</p>
          <p style={textStyles}>{addressDetails}</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p style={titleStyles}>{branch}</p>
          <p style={textStyles}>{addressDetails}</p>
        </React.Fragment>
      )}
    </div>
  );
}

CustomInfoDisplay.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  branch: PropTypes.string,
  addressDetails: PropTypes.string,
  status: PropTypes.string,
  flag: PropTypes.bool,
};
