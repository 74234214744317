import React from 'react';
import PropTypes from 'prop-types';
import ClientListItemView from '../client-list-item-view';
import useStyles from './styles';
import { useMediaQuery } from '../../company-details/mediaQueries'

export default function ClientListView({ clients, setLastUpdated, searchText, providerServices }) {
  const isMobile = useMediaQuery('(max-width: 785px)');
  const classes = useStyles();

  return (
    <div className={!isMobile ? classes.root : classes.rootMobile}>
      {clients
        .filter((entry) => {

          const fullName = entry.first_name.toLowerCase() + ' ' + entry.last_name.toLowerCase() + ' '.repeat(50);

          if (fullName.includes(searchText.toLowerCase())) {
            return (
              <ClientListItemView
                key={entry.id}
                details={entry}
                setLastUpdated={setLastUpdated}
                providerServices={providerServices}
              />
            );

          } else if (searchText === '') {
            return (
              <ClientListItemView
                key={entry.id}
                details={entry}
                setLastUpdated={setLastUpdated}
                providerServices={providerServices}


              />
            )
          }
        }).map((entry) => (
          <ClientListItemView
            key={entry.id}
            details={entry}
            setLastUpdated={setLastUpdated}
            providerServices={providerServices}

          />
        ))}
    </div>
  );
}

ClientListView.propTypes = {
  clients: PropTypes.array.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  providerId: PropTypes.number,
  providerServices: PropTypes.array,
  userServices: PropTypes.object,
  setUserServices: PropTypes.func
};
