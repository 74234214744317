import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { MdLocationOn } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';

import SquareIcon from '../common/icons/Square-Icon-white.svg';
import LanguageSelector from './language-select';
import AccountOptions from './account-options';

import SubHeader from './sub-header/subHeader';
import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import './header.scss'

// Custom Styles
const avatarStyles = {
  height: '35px',
  width: '35px',
  fontFamily: 'Poppins, sans-serif',
}

const buttonStyles = {
  color: '#fff',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.1rem'
}

const divStyles = {
  display: 'flex',
  alignItems: 'center'
}

const textStyles = {
  color: '#fff',
  fontSize: '1.2rem',
  marginLeft: '.5rem',
  fontWeight: '300',
  marginRight: '.5rem',
}

const setAvatarInitials = (firstName, lastName) => {
  if (firstName && lastName) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  }

  return '';
};

const SignedInControls = ({ userDetails, signOut }) => {
  if (userDetails) {
    let firstName = userDetails.first_name;
    let lastName = userDetails.last_name;
    let fullName = firstName + ' ' + lastName;
    let avatarInitials = setAvatarInitials(firstName, lastName);

    return (
      <div style={divStyles}>
        <Avatar style={avatarStyles}>{avatarInitials}</Avatar>
        <p style={textStyles}>{fullName}</p>
        <AccountOptions signOut={signOut} />
      </div>
    );
  }

  return null;
};

SignedInControls.propTypes = {
  userDetails: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

export default function Header() {
  const { isSignedIn, userDetails, signOut } = useAuthDataContext();
  const { t } = useTranslation(['common', 'form', 'authentication']);
  const navigate = useNavigate();

  return (
    <header>
      <div className='Header'>

        <div className='Header__col-1'>
          <img style={{ height: '4.5rem' }} src={SquareIcon} alt={'common:svLogoIcon'} />
          <h3><span>S</span>erv<span>U</span>s</h3>

          <div style={{ visibility: 'hidden' }} className='Header__col-1__location'>
            <MdLocationOn style={{ fontSize: '1.5rem' }} />
            <p>{t('lblTempLocation')}</p><span><IoIosArrowDown className='align' /></span>
          </div>
        </div>

        <div className='Header__col-2'>
          <p className='pushRight'><LanguageSelector /></p>

          <div>
            {isSignedIn ? (
              <SignedInControls userDetails={userDetails} signOut={signOut} />
            ) : (
              <Button
                style={buttonStyles}
                onClick={() => navigate('/signin')}>
                {t('authentication:signIn')}
              </Button>
            )}
          </div>
        </div>
      </div>
      {isSignedIn ? <SubHeader /> : null}
    </header>
  );
}
