import React from 'react';

import Button from '@material-ui/core/Button';

import useAuthDataContext from '../../../../auth/hooks/useAuthDataContext';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { AiOutlineHome, AiOutlineUser, } from 'react-icons/ai';
import { BiMessageSquareDetail, BiDonateHeart } from 'react-icons/bi';
import { RiCompassDiscoverLine } from 'react-icons/ri';


export default function ClientOptions() {
  const classes = useStyles();
  const { t } = useTranslation('menuItems');
  const { goToPage } = useAuthDataContext();

  function handleClickDashboard() {
    goToPage('/dashboard');
  }

  function handleClickProfile() {
    goToPage('/profile');
  }

  function handleMessageClick() {
    goToPage('/messages');
  }

  function handleServiceDiscoveryClick() {
    goToPage('/service-discovery');
  }

  function handleOnboarding() {
    goToPage('/onboarding')
  }

  return (
    <div className={classes.root}>
      <div hidden>
        <Button
          color='primary'
          className={classes.linkButton}
          startIcon={<AiOutlineHome />}
          onClick={handleClickDashboard}
        >
          {t('menuItems:lblHome')}
        </Button>
      </div>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<AiOutlineUser />}
        onClick={handleClickProfile}
      >
        {t('menuItems:lblProfile')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<BiMessageSquareDetail />}
        onClick={handleMessageClick}
      >
        {t('menuItems:lblMessages')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<RiCompassDiscoverLine />}
        onClick={handleServiceDiscoveryClick}
      >
        {t('menuItems:lblDiscover')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<BiDonateHeart />}
        onClick={handleOnboarding}
      >
        {'Your Needs'}
      </Button>
    </div>
  );
}
