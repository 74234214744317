import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    width: '100%',
    marginBottom: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyles;
