import { awsApiRequest } from '../utils/func';

export async function getProviderServiceList(providerId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/providers/${providerId}/services`,
    });
  } catch (error) {
    return error;
  }
}

export async function addServiceForProvider(providerId, values) {
  try {
    const body = {
      service_details: {
        name: values.name,
        summary: values.summary,
        insurable_service: values.insurableService,
        insurable_details: values.insurableDetails,
        hours_of_service: values.hoursOfService,
        eligibility_criteria: values.eligibilityCriteria,
      },
      location_ids: values.locationIds,
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/providers/${providerId}/services`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function removeService(providerId, serviceId) {
  try {
    return await awsApiRequest({
      method: 'DELETE',
      path: `/providers/${providerId}/services/${serviceId}`,
    });
  } catch (error) {
    return error;
  }
}

export async function editProviderService(providerId, serviceId, values) {
  try {
    const body = {
      name: values.name,
      summary: values.summary,
      insurable_details: values.insurable_details,
      hours_of_service: values.hours_of_service,
      eligibility_criteria: values.eligibility_criteria,
    };

    return await awsApiRequest({
      method: 'PUT',
      path: `/providers/${providerId}/services/${serviceId}`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}
