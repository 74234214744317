import React, { useState, useEffect } from 'react'
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../serviceDiscoveryPage.scss';


const SearchFilters = ({ userDistance, setUserDistance, filteredProvidersByDistance, active, setActive }) => {
  const [showSlider, setShowSlider] = useState(false)
  const [distance, setDistance] = useState(userDistance)

  const { t } = useTranslation('discoveryPage');

  const handleChange = (event, newValue) => {
    setDistance(newValue)
  }

  const handleDistanceChange = () => {
    setUserDistance(distance)
    setShowSlider(false)
  }

  useEffect(() => {
    if (active !== null && filteredProvidersByDistance.includes(active)) {
      return
    } else {
      setActive(null)
    }
  }, [handleDistanceChange])

  return (
    <div className='ServiceDiscoveryPage__filters'>
      <p>{t('lblFilterBy')}:</p>
      <button>{t('lblCategories')}</button>
      <button
        id={showSlider ? 'btn-wrapped' : ''}
        onClick={() => setShowSlider(!showSlider)}>{t('lblRadius')}: {userDistance}km</button>
      {showSlider ? (
        <div style={{ backgroundColor: 'rgb(234, 239, 247)' }}>
          <Slider value={distance} onChange={handleChange} onMouseUp={handleDistanceChange} />
          <p style={{ width: '40%' }}>{distance} km</p>
        </div>
      ) : null}
    </div>
  )
}

SearchFilters.propTypes = {
  userDistance: PropTypes.number,
  setUserDistance: PropTypes.func,
  setActive: PropTypes.func,
  filteredProvidersByDistance: PropTypes.array,
  active: PropTypes.object
}

export default SearchFilters;
