import React from 'react'
import '../clientOnBoarding.scss'
import PropTypes from 'prop-types';
import logo from '../assets/Icons-03 2.svg'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormControl } from '@material-ui/core';
import { errorStyles, errorStylesDate, errorStylesDiv, imageStyles, divStyles, textStyles } from './styles';
import { useTranslation } from 'react-i18next';

const ClientDetails = ({ values, setValues, errors, dateErr, dateErrObj }) => {

  const { t } = useTranslation(['client']);

  const handleOnChange = (e) => {
    let { name, value } = e.target;

    if (name === 'phoneNumber' && value !== '') {
      value = formatPhoneNumber(value)
    }
    setValues({
      ...values,
      [name]: value
    })
  }

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const handleDateChange = (e) => {
    let { name, value } = e.target;

    if ((name === "month" && value > 2) || (name === "day" && value > 2)) {
      value = value.slice(0, 2);
    }

    if (name === "year" && value > 4) {
      value = value.slice(0, 4);
    }

    setValues({
      ...values,
      dateOfBirth: {
        ...values.dateOfBirth,
        [name]: value
      }
    })
  }

  return (
    <div className='ClientOnBoarding'>
      <img style={imageStyles} src={logo} alt='logo' />
      <form className='ClientOnBoarding__form'>
        <h6>{t('client:lblFirstName')}</h6>
        <input
          className={errors?.firstName ? 'input-error' : ''}
          value={values.firstName}
          onChange={(e) => handleOnChange(e)}
          name='firstName'
          type='text'
          placeholder={t('client:lblFirstName')} />
        <p style={errorStyles}>{errors?.firstName}</p>
        <h6>{t('client:lblLastName')}</h6>
        <input
          className={errors?.lastName ? 'input-error' : ''}
          value={values.lastName}
          onChange={(e) => handleOnChange(e)}
          name='lastName'
          type='text'
          placeholder={t('client:lblLastName')}
        />
        <p style={errorStyles}>{errors?.lastName}</p>
        <h6>{t('client:lblEmail')}</h6>
        <input
          style={{ color: 'rgba(51, 91, 128, 0.7)', fontFamily: 'Poppins, sans-serif' }}
          readOnly value={values.email}
        />
        <h6>{t('client:lblDateOfBirth')}</h6>
        <div className='ClientOnBoarding__form__birth-date'>
          <div className='ClientOnBoarding__form__birth-date__row'>
            <p>MM</p>
            <input
              className={dateErr || dateErrObj?.month ? 'input-error' : ''}
              name='month'
              value={values.dateOfBirth.month}
              onChange={(e) => handleDateChange(e)}
              type='number'
              placeholder='MM'
            />
          </div>
          <div className='ClientOnBoarding__form__birth-date__row'>
            <p>DD</p>
            <input
              className={dateErr || dateErrObj?.day ? 'input-error' : ''}
              name='day'
              value={values.dateOfBirth.day}
              onChange={(e) => handleDateChange(e)}
              type='number'
              placeholder='DD'
            />
          </div>
          <div className='ClientOnBoarding__form__birth-date__row'>
            <p>YYYY</p>
            <input
              className={dateErr || dateErrObj?.year ? 'input-error' : ''}
              name='year'
              style={{ width: '5rem' }}
              type='number'
              placeholder='YYYY'
              value={values.dateOfBirth.year}
              onChange={(e) => handleDateChange(e)}
            />
          </div>
        </div>

        <p style={errorStylesDate}>{dateErr}</p>
        <div style={errorStylesDiv}>
          <p>{dateErrObj?.month}</p>
          <p>{dateErrObj?.day}</p>
          <p>{dateErrObj?.year}</p>
        </div>

        <h6>{t('client:lblPhoneNumber')}</h6>
        <input
          className={errors?.phoneNumber ? 'input-error' : ''}
          type='text'
          name='phoneNumber'
          placeholder={'(xxx) xxx-xxxx'}
          value={values.phoneNumber}
          onChange={(e) => handleOnChange(e)}
        />
        <p style={errorStyles}>{errors?.phoneNumber}</p>
        <h6>{t('client:lblAddress')}</h6>
        <input
          className={errors?.address ? 'input-error' : ''}
          type='text'
          placeholder={'Address'}
          name='address'
          value={values.address}
          onChange={(e) => handleOnChange(e)}
        />
        <p style={errorStyles}>{errors?.address}</p>
        <h6>{t('client:lblGender')}</h6>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            value={values.gender}
            name="radio-buttons-group"
          >
            <div style={divStyles}>
              <FormControlLabel

                value={'Female'}
                control={<Radio />}
                onChange={(e) => setValues({
                  ...values,
                  gender: e.target.value
                })}
              />
              <p style={textStyles}>{t('client:lblFemale')}</p>
            </div>
            <div style={divStyles}>
              <FormControlLabel
                value={'Male'}
                control={<Radio />}
                onChange={(e) => setValues({
                  ...values,
                  gender: e.target.value
                })}
              />
              <p style={textStyles}>{t('client:lblMale')}</p>
            </div>
            <div style={divStyles}>
              <FormControlLabel
                value={'Other'}
                control={<Radio />}
                onChange={(e) => setValues({
                  ...values,
                  gender: e.target.value
                })}
              />
              <p style={textStyles}>{t('client:lblOther')}</p>
            </div>

          </RadioGroup>
        </FormControl>
        <h6 style={{ marginTop: '1rem' }}>{t('client:lblLanguage')}</h6>
        <input
          value={values.language}
          name='language'
          type='text'
          placeholder={'Language'}
          onChange={(e) => handleOnChange(e)}
        />
        <h6>{t('client:lblMedicare')}</h6>
        <input
          name='medicareNumber'
          value={values.medicareNumber}
          type='text'
          placeholder={'Medicare Number'}
          onChange={(e) => handleOnChange(e)}
        />
      </form>
    </div>
  )
}

ClientDetails.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  errors: PropTypes.object,
  dateErr: PropTypes.string,
  dateErrObj: PropTypes.object
};

export default ClientDetails
