import React from 'react';
import { Navigate } from 'react-router-dom'; 

import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import { getUserRole } from '../../../utils/userUtils';

import {
  VALUE_ROLE_NAME_ADMIN,
  VALUE_ROLE_NAME_STAFF,
  VALUE_ROLE_NAME_SUPPORT,
} from '../../common/constants';

export default function DashboardPage() {
  const { userDetails } = useAuthDataContext();
  
  let userType = getUserRole(userDetails);

  if (
    userType === VALUE_ROLE_NAME_ADMIN ||
    userType === VALUE_ROLE_NAME_STAFF
  ) {
    return <Navigate location={location} to={{
      pathname: '/company-details',
      state: { from: location },
    }} />
  }

  if (userType === VALUE_ROLE_NAME_SUPPORT) {
    return <Navigate location={location} to={{
      pathname: '/my-clients',
      state: { from: location },
    }} />
  }

  return <Navigate location={location} to={{
    pathname: '/profile',
    state: { from: location },
  }} />
}
