import React from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocationContext } from '../locationContext';

import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import * as Yup from 'yup';


import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ProvinceList from '../../../common/lists/province-list';
// import PostalCodeMask from '../../../common/form/postal-code-mask';
import AddressSearch from './addressSearch';


import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { fieldTitleStyles, moreMarginStyles } from '../../customStyles/customStyles';

import { VALUE_SELECT } from '../../../common/constants';


export default function AddressForm({ handleNext, isLoaded, loadError }) {


  const { t } = useTranslation(['location', 'common', 'error']);

  const classes = useStyles();

  const { state, setState } = useContext(LocationContext)


  const initialValues = {
    branch: state.address ? state.address.branch : '',
    address1: state.address ? state.address.address1 : '',
    address2: state.address ? state.address.address2 : '',
    city: state.address ? state.address.city : '',
    province: state.address ? state.address.province : '',
    postalCode: state.address ? state.address.postalCode : '',
    country: 'Canada',
    latitude: state.address ? state.address.latitude : '',
    longitude: state.address ? state.address.longitude : '',
  }


  const requiredTranslated = t('form:required');
  const LocationAddressSchema = Yup.object().shape({
    branch: Yup.string().required(requiredTranslated),
    address1: Yup.string().required(requiredTranslated),
    city: Yup.string().required(requiredTranslated),
    province: Yup.string()
      .notOneOf([VALUE_SELECT], requiredTranslated)
      .required(requiredTranslated),
    postalCode: Yup.string()
      .matches(
        /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][ ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/gi,
        t('error:msgIncorrectFormatPostalCode')
      )
      .required(requiredTranslated),
  });

  const onSubmit = async (values) => {

    values.postalCode = values.postalCode.toUpperCase();
    setState({
      ...state,
      address: values
    })

    handleNext();
  };


  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={LocationAddressSchema}
        onSubmit={onSubmit}

      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Grid
              container
              spacing={1}
              direction='column'
              justifyContent='center'
              alignItems='stretch'
            >
              <Grid item>
                <h3 style={fieldTitleStyles}>{t('location:lblBranch')}</h3>
                <Field
                  component={FormikTextField}
                  className={classes.textField}
                  type='text'
                  name='branch'
                  margin='normal'
                  variant='outlined'
                  placeholder={t('location:phBranch')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <h3 style={fieldTitleStyles}>{t('location:lblAddress1')}</h3>
                {/*       <Field
                  component={FormikTextField}
                  className={classes.textField}
                  type='text'
                  name='address1'
                  margin='normal'
                  variant='outlined'
                  placeholder={t('location:phAddress1')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}

                <div style={{ marginTop: '1.5rem' }}>
                  <AddressSearch
                    isLoaded={isLoaded}
                    loadError={loadError}
                  />

                </div>
                {/*  <h3 style={fieldTitleStyles}>{t('location:lblAddress2')} {'(optional)'}</h3>
                <Field
                  component={FormikTextField}
                  className={classes.textField}
                  type='text'
                  name='address2'
                  margin='normal'
                  variant='outlined'
                  placeholder={t('location:phAddress2')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                <h3 style={fieldTitleStyles}>{t('location:lblCity')}</h3>
                <Field
                  component={FormikTextField}
                  className={classes.textField}
                  type='text'
                  name='city'
                  margin='normal'
                  variant='outlined'
                  placeholder={t('location:lblCity')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></Field>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction='row'
              justifyContent='space-between'
              alignItems='flex-end'
            >
              <Grid item>
                <h3 style={fieldTitleStyles}>{t('location:lblProvince')}</h3>
                <Field
                  select
                  component={FormikTextField}
                  name='province'
                  variant='outlined'
                  className={classes.textField}
                  placeholder={t('location:lblProvince')}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin='normal'
                >
                  <ProvinceList />
                </Field>
              </Grid>
              <Grid item>
                <h3 style={fieldTitleStyles}>{t('location:lblPostalCode')}</h3>
                <Field

                  className={classes.textField}
                  type='text'
                  name='postalCode'
                  margin='normal'
                  variant='outlined'
                  component={FormikTextField}
                  placeholder={t('location:lblPostalCode')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                // InputProps={{ inputComponent: PostalCodeMask }}
                />
              </Grid>

              <br />

              <Grid style={moreMarginStyles} container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    className={classes.button}
                  >
                    {t('common:btnNext')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

AddressForm.propTypes = {
  handleNext: PropTypes.func,
  isLoaded: PropTypes.bool,
  loadError: PropTypes.object
};