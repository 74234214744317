import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  tabItem: {
    textTransform: 'none',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1.2rem',
    color: 'black',
    padding: '1.5rem'
  },
}));

export default useStyles;
