import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { specialCharsArr } from './specialChars'
import '../signin/signinPage.scss';

import '../signin/signinPage.scss';
import Button from '@material-ui/core/Button';

import SuspenseView from '../../components/suspense-view';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import PasswordMustContain from './PasswordMustContain';
import RevealPassword from '../../components/customIcons/RevealPassword';

const inputStyles = {
  padding: '1rem',
  marginTop: '1rem',
  borderRadius: '20px',
  border: 'none',
  fontSize: '1.3rem',
}

export default function SetPasswordPage() {
  const { completeNewPassword, goToPage } = useAuthDataContext();
  const { t, ready } = useTranslation([
    'authentication',
    'common',
    'error',
    'form',
  ]);

  const params = useParams();

  //hook for setting password visibility//
  const [passwordVis, setPasswordVis] = useState(true)
  const [passwordVisConfirm, setPasswordVisConfirm] = useState(true)

  //===== Changing label color onFocus ===== \\
  const [focusedPassword, setFocusedPassword] = useState(false)
  const [focusedPasswordConfirm, setFocusedPasswordConfirm] = useState(false)

  const onFocusPassword = () => setFocusedPassword(true)
  const onBlurPassword = () => setFocusedPassword(false)

  const onFocusPasswordConfirm = () => setFocusedPasswordConfirm(true)

  const onBlurPasswordConfirm = () => {
    setFocusedPasswordConfirm(false)
  }
  //=========================================\\

  const [passwordErr, setPasswordErr] = useState('')

  const [input, setInput] = useState({
    password: '',
    confirmPassword: ''
  })

  const [validated, setValidated] = useState(false);

  const [valid, setValid] = useState({
    number: false,
    uppercase: false,
    specialChars: false,
    length: false,
    match: false
  });

  useEffect(() => {
    valid.uppercase === true &&
      valid.specialChars === true &&
      valid.number === true &&
      valid.length === true &&
      valid.match === true
      ? setValidated(true)
      : setValidated(false);
  }, [valid]);

  const email = params.confirmEmail;

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { success, errorMessage } = await completeNewPassword(
      input.password
    );
    if (success) {
      goToPage(`/signin/${email}`);
    } else {
      setPasswordErr({ msg: t(errorMessage) });
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }

  const validateLength = (e) => (e.length >= 8 ? true : false);
  const validateNums = (e) => /\d/.test(e);
  const validateCaps = (e) => e.toLowerCase() !== e;
  const validateSpecialChars = (e) => specialCharsArr.some((char) => e.includes(char));

  const validatePasswordsMatch = (e) => (input.password !== '' && e === input.password ? true : false);
  const validateConfirmPasswordsMatch = (e) => (input.confirmPassword !== '' && e === input.confirmPassword ? true : false);

  const validate = (e) => {
    setValid({
      ...valid,
      length: validateLength(e),
      number: validateNums(e),
      uppercase: validateCaps(e),
      specialChars: validateSpecialChars(e),
      match: validateConfirmPasswordsMatch(e)
    });
  };

  const validatePasswords = (e) => {
    setValid({
      ...valid,
      match: validatePasswordsMatch(e)
    });
  };

  const handleChange = (e) => {
    setInput({ ...input, password: e })
    validate(e)
  }

  const handlePasswordsMatch = (e) => {
    setInput({ ...input, confirmPassword: e })
    validatePasswords(e)
  }

  return (
    <div className='SignInPage-Container'>
      <form className='SignInPage-Container__form'>
        <div style={{ marginBottom: '2rem' }} className='SignInPage-Container__form__title'>
          {t('authentication:setPassword')} for: <br />{email}
        </div>

        <div className='setPasswordPage-container'>
          <div className='setPasswordPage-container__col'>

            <div className='setPasswordPage-container__row'>
              <input
                style={inputStyles}
                name='password'
                id='password'
                required
                placeholder={t('authentication:password')}
                type={passwordVis ? 'password' : 'text'}
                value={input.password}
                onChange={(e) => handleChange(e.target.value)}
                onFocus={onFocusPassword}
                onBlur={onBlurPassword}
              />
              <RevealPassword
                transform={'translateX(-36px) translateY(5px)'}
                passwordVis={passwordVis}
                setPasswordVis={setPasswordVis}
              />
            </div>
            <label style={focusedPassword ? { color: 'rgb(26, 80, 161)' } : { color: 'rgb(146, 146, 146)' }} htmlFor='password'>{t('authentication:password')}</label>
          </div>
          {focusedPassword ? (
            <hr className='span-bar' />
          ) : null}
        </div>

        <div style={focusedPassword ? { marginTop: '-3px' } : null} className='setPasswordPage-container'>
          <div className='setPasswordPage-container__col'>
            <div className='setPasswordPage-container__row'>

              <input
                style={inputStyles}
                name='confirmPassword'
                id='confirmPassword'
                value={input.confirmPassword}
                onChange={(e) => handlePasswordsMatch(e.target.value)}
                placeholder={t('authentication:confirmPassword')}
                type={passwordVisConfirm ? 'password' : 'text'}
                onFocus={onFocusPasswordConfirm}
                onBlur={onBlurPasswordConfirm}
              />

              <RevealPassword
                transform={'translateX(-36px) translateY(5px)'}
                passwordVis={passwordVisConfirm}
                setPasswordVis={setPasswordVisConfirm} />
            </div>
            <span className='bar' />
            <label style={focusedPasswordConfirm ? { color: 'rgb(26, 80, 161)', marginTop: '1rem' } : { color: 'rgb(146, 146, 146)', marginTop: '1rem' }} htmlFor='confirmPassword'>{t('authentication:confirmPassword')}</label>
          </div>
          {focusedPasswordConfirm ? (
            <hr className='span-bar' />
          ) : null}
        </div>

        {validated ? (
          <div style={focusedPasswordConfirm ? { marginTop: '-3px' } : null}>
            <Button
              onClick={handleSubmit}
              id='btn-back'
              type='submit'
              style={{ marginTop: '2rem', boxShadow: 'none', background: 'rgb(26, 80, 161)', color: 'white', padding: ' .6rem 1.2rem' }}
            >
              {t('form:submit')}
            </Button>
          </div>
        ) : (
          <div style={focusedPasswordConfirm ? { marginTop: '-3px' } : null}>
            <Button
              disabled
              id='btn-back-disabled'
            >
              {t('form:submit')}
            </Button>
          </div>
        )}
      </form>
      <div>
        <PasswordMustContain err={passwordErr.msg} valid={valid} />
      </div>
    </div>
  );
}

SetPasswordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      confirmEmail: PropTypes.string,
    }),
  }),
};
