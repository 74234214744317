import React from 'react';
import PropTypes from 'prop-types';
import { AiFillHome } from 'react-icons/ai'

import { Link } from 'react-router-dom';


const divStyles = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const titleStyles = {
  fontSize: '2rem',
  fontWeight: '600',
  color: 'rgb(26, 80, 161)'
}

export default function ForgotPasswordDetailsSentForm({ t }) {
  return (
    <form>
      <div style={divStyles}>
        <h3 style={titleStyles}>{t('authentication:forgotPasswordRequestSuccess')}</h3>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AiFillHome style={{ color: 'rgb(26, 80, 161)', fontSize: '2rem', transform: 'translateY(-2px)' }} />
          <Link to='/'><p style={{ fontSize: '1.5rem' }}>{t('common:home')}</p></Link>
        </div>
      </div>
    </form>
  );
}

ForgotPasswordDetailsSentForm.propTypes = {
  t: PropTypes.func,
};
