import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocationContext } from '../locationContext';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import HealthZoneSelect from './healthZoneSelect';
import * as healthZoneOptions from './healthZoneOptions';
import './mapFormModal.scss';

import {
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SuspenseView from '../../../../components/suspense-view';
import CustomInfoDisplay from './customInfoDisplay';

import { getEnvVariable } from '../../../../utils/environmentVariables';
import { useTranslation } from 'react-i18next';
import { moreMarginStyles } from '../../customStyles/customStyles';


const style = {
  position: 'absolute',
  top: '70%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius: '25px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

const mapContainerStyle = {
  height: '50vh',
  width: '60vw',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

export default function MapForm({ handleNext, handleBack, isLoaded, loadError }) {

  const { state, setState } = useContext(LocationContext)
  const { t } = useTranslation(['location', 'common']);

  const [openInfo, setOpenInfo] = useState(false);
  const [flag, setFlag] = useState(false);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  Geocode.setApiKey(getEnvVariable('REACT_APP_API_MAP_KEY'));

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng)
      const address = response.results[0].formatted_address;
      return address
    } catch (err) {
      console.log(err.message)
    }
  }

  const resetHealthZonesServedArray = () => {
    setState({
      ...state,
      healthZonesServed: []
    })
  }

  const selectOptions = useMemo(() => {
    const option = state.address.province + '_OPTIONS'
    return healthZoneOptions[option]
  }, [])

  useEffect(async () => {
    // const { lat, lng } = await getLatLngFromAddress()
    // setState({
    //   ...state,
    //   address: {
    //     ...state.address,
    //     latitude: lat,
    //     longitude: lng
    //   }
    // })
    if (selectOptions.some((option) => option.value === state.healthZonesServed[0])) {
      return
    } else {
      resetHealthZonesServedArray();
    }
  }, []);

  const openInfoWindow = () => {
    setOpenInfo(true);
  };

  const closeInfoWindow = () => {
    setOpenInfo(false);
  };


  const [isDrag, setIsDrag] = useState(false)

  const [markerNewPosition, setMarkerNewPosition] = useState({
    lat: '',
    lng: ''
  })

  const [addressObj, setAddressObj] = useState(null)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsDrag(false)
  }

  const handleDragEnd = async (e) => {
    isDrag ? setIsDrag(false) : null
    const address = await getAddressFromLatLng(e.latLng.lat(), e.latLng.lng())

    const newAddress = address.split(',')

    setAddressObj({
      address1: newAddress[0],
      city: newAddress[1].replace(/\s/g, ""),
      postalCode: newAddress[2].slice(4),
      province: newAddress[2][1] + newAddress[2][2],
      country: newAddress[3].replace(/\s/g, ""),
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng()
    })

    setMarkerNewPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    })
    setIsDrag(true)
    setFlag(true);
    handleOpen()
  };

  const handleAddressChangeConfirm = () => {
    setState({
      ...state,
      address: {
        ...state.address,
        address1: addressObj.address1,
        city: addressObj.city,
        postalCode: addressObj.postalCode,
        province: addressObj.province,
        country: addressObj.country,
        latitude: addressObj.latitude,
        longitude: addressObj.longitude
      }
    })
    handleClose()
  }

  const [healthZoneErr, setHealthZoneErr] = useState('')

  const onSubmit = () => {
    if (state.healthZonesServed.length === 0) {
      setHealthZoneErr('Please enter at least one health zone*')
    } else {
      handleNext();
    }
  };

  if (loadError) {
    return 'Error';
  }

  if (!isLoaded) {
    return <SuspenseView />;
  }

  return (
    <React.Fragment>
      <HealthZoneSelect err={healthZoneErr} setErr={setHealthZoneErr} options={selectOptions} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <GoogleMap
          id='map'
          mapContainerStyle={mapContainerStyle}
          zoom={16}
          center={isDrag ? (
            { lat: parseFloat(markerNewPosition.lat), lng: parseFloat(markerNewPosition.lng) }
          ) : (
            { lat: parseFloat(state.address?.latitude), lng: parseFloat(state.address?.longitude) }
          )}
          options={options}
          onLoad={onMapLoad}
        >
          <Marker
            position={isDrag ? (
              { lat: parseFloat(markerNewPosition.lat), lng: parseFloat(markerNewPosition.lng) }
            ) : (
              { lat: parseFloat(state.address?.latitude), lng: parseFloat(state.address?.longitude) }
            )}
            draggable={true}
            onClick={openInfoWindow}
            onLoad={() => {
              setOpenInfo(true);
            }}
            onDragEnd={handleDragEnd}
          >
            {openInfo ? (
              <InfoWindow onCloseClick={closeInfoWindow}>
                {isDrag ? (
                  <CustomInfoDisplay
                    flag={flag}
                    status='Changed'
                    branch={state.address.branch}
                    addressDetails={`${addressObj.address1} ${addressObj?.postalCode} ${addressObj?.city}, ${addressObj?.province} ${addressObj?.country}`}
                  />
                ) : (
                  <CustomInfoDisplay
                    flag={flag}
                    branch={state.address.branch}
                    addressDetails={
                      state.address.address1 +
                      ', ' +
                      state.address.city +
                      ', ' +
                      state.address.province +
                      ', ' +
                      state.address.postalCode
                    }
                  />
                )}
              </InfoWindow>
            ) : null}
          </Marker>
        </GoogleMap>
      </div>
      <br />
      <Grid style={moreMarginStyles} container justifyContent='flex-end'>
        <Grid item>
          <Button
            style={{ padding: '.5rem 0' }}
            id='btn-back'
            onClick={handleBack}>{t('common:btnBack')}</Button>
          <Button variant='contained' color='primary' onClick={onSubmit}>
            {t('common:btnNext')}
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='MapFormModal'>
            <h3>
              Confirm new Address:
            </h3>
            <p>{addressObj?.address1}</p>
            <p>{addressObj?.postalCode} {addressObj?.city}, {addressObj?.province} {addressObj?.country} </p>
          </div>
          <div className='MapFormModal__buttons'>
            <button
              onClick={handleClose}
              id='btn-back'>Cancel
            </button>
            <button
              onClick={handleAddressChangeConfirm}
              id='btn-back'>Confirm
            </button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

MapForm.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  isLoaded: PropTypes.bool,
  loadError: PropTypes.object
};
