import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getEnvVariable } from '../../../../utils/environmentVariables';

import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import {
  useLoadScript,
} from '@react-google-maps/api';

import AddLocationForm from '../../../../components/dialogs/location-wizard';
import LocationListItemView from './location-list-item-view';

import SuspenseView from '../../../suspense-view';
import Toast from '../../../common/snackbar/toast';

import { getProviderLocationList } from '../../../../api/provider-location';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../utils/toastUtils';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { typographyStyles, divStyles, divStylesMedia } from './styles';
import { BsPlusLg } from 'react-icons/bs'

import { useMediaQuery } from '../mediaQueries';
import './locationTab.scss'

//CONST Items Per Page
const ITEMSPERPAGE = 6; //needs to be hard coded

const libraries = ['places']

const useFetch = (providerId, lastUpdated) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderLocationList(
        providerId
      );
      if (success) {
        setData(data);
      } else {
        setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId, lastUpdated]);

  return { data, error };
};

export default function LocationsTab({ providerId }) {
  const isMedia = useMediaQuery("(max-width: 900px)");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: getEnvVariable('REACT_APP_API_MAP_KEY'),
    libraries,
  });

  const { t, ready } = useTranslation(['common', 'location', 'error']);
  const classes = useStyles();

  // State values
  const [showLocationDialog, setShowLocationDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [locationState, setLocationState] = useState({
    lastUpdated: moment(),
  });

  const setLastUpdated = () => {
    setLocationState((locationState) => ({
      ...locationState,
      lastUpdated: moment(),
    }));
  };

  // ADD Dialog Methods
  const openAddLocationDialog = () => {
    setShowLocationDialog(true);
  };

  const closeAddLocationDialog = () => {
    setShowLocationDialog(false);
  };

  // Pagination back and forth functionality
  const handleChange = (event, value) => {
    setPage(value);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:lblMsgAddedSuccess')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:lblMsgLocationNotAdded')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  // Retrieve the locations list data
  const response = useFetch(providerId, locationState.lastUpdated);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const locations = response.data;
  let numberOfLocations = _.get(locations, 'length', 0);
  const numberOfPages = Math.ceil(numberOfLocations / ITEMSPERPAGE);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <React.Fragment>

      <div style={isMedia ? divStylesMedia : divStyles}>
        <Typography style={typographyStyles} variant='h6' color='primary'>
          {t('location:lblNumLocations', {
            num: numberOfLocations,
          })}
        </Typography>
      </div>

      <div className='ServiceTeamTab__container'>
        {locations
          .slice((page - 1) * ITEMSPERPAGE, page * ITEMSPERPAGE)
          .map((entry) => (
            <Grid item key={locations.indexOf(entry)}>
              <br />
              <LocationListItemView
                key={entry.id + '-' + entry.name}
                details={entry}
                setLastUpdated={setLastUpdated}
                isLoaded={isLoaded}
                loadError={loadError}
              />
            </Grid>
          ))}

        <div className='LocationCard__Add-New'>
          <BsPlusLg onClick={openAddLocationDialog} className='LocationCard__Add-New__Plus' />
          <p>{t('location:lblAddLocation')}</p>
        </div>

        <div hidden>
          <AddLocationForm
            show={showLocationDialog}
            handleClose={closeAddLocationDialog}
            providerId={providerId}
            setLastUpdated={setLastUpdated}
            success={handleSuccess}
            error={handleError}
            isLoaded={isLoaded}
            loadError={loadError}
          />
        </div>

      </div>
      <div style={{ width: '100%', marginTop: '-5rem' }}>
        <Pagination
          count={numberOfPages}
          page={page}
          onChange={handleChange}
          defaultPage={1}
          color='primary'
          size='large'
          justify='center'
          classes={isMedia ? { ul: classes.paginatorMedia } : { ul: classes.paginator }}
        />
      </div>
      <Toast toastList={list} />

    </React.Fragment>
  );
}

LocationsTab.propTypes = {
  providerId: PropTypes.number,
};
