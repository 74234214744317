import React from 'react';
import { Link } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';

function TermsPage() {
  return (
    <form>
      <div>
        <h1>Terms</h1>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
          ultrices gravida mi in gravida. Sed lobortis consectetur lacus.
          Maecenas sed eleifend purus, sit amet eleifend elit. Maecenas in nunc
          in massa dictum egestas non id dui. Aenean lacinia sapien id lobortis
          vulputate. Aliquam eget justo non leo finibus bibendum. Praesent in
          nunc sagittis, ultrices diam ut, vehicula nisl. Vivamus sed facilisis
          nisi. Vivamus consectetur, velit ut consectetur efficitur, orci sem
          convallis orci, ut tristique dolor sem eu velit. Suspendisse consequat
          nunc magna, quis sodales turpis pulvinar nec. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Nullam vel tortor et felis tincidunt rutrum quis a dolor.
          Etiam molestie pulvinar mi eget facilisis. Ut consectetur, est nec
          sollicitudin vestibulum, quam nisi dapibus nisl, sit amet rhoncus elit
          ipsum sit amet velit. Donec egestas, eros ac semper consequat, urna
          justo cursus massa, a pharetra eros risus non dolor. Fusce eu turpis
          sed neque placerat suscipit eu vitae nibh. Fusce ut nunc quis nisl
          tincidunt aliquet. In hac habitasse platea dictumst. Cras at lorem
          est. Phasellus mattis augue nisi, vel lobortis leo varius sit amet.
          Donec vulputate lectus magna, vitae maximus velit volutpat at.
          Suspendisse pretium enim eu ante malesuada hendrerit. Proin ante
          ligula, aliquam eu mollis vitae, euismod ac purus. Curabitur volutpat
          fringilla metus vitae aliquam. Duis at justo vel mi ultrices auctor.
          Maecenas ipsum mauris, gravida eu libero non, condimentum dictum leo.
          Pellentesque ornare dui quis sem ultricies posuere. Praesent lobortis
          est sit amet sem posuere viverra. In nibh arcu, fermentum in velit
          sed, tristique venenatis nisi. Fusce hendrerit nunc at feugiat
          posuere. Fusce lobortis tempus purus at pretium. Duis vel dui feugiat,
          pulvinar lorem et, pellentesque magna. Aliquam erat volutpat. Donec
          vel leo id nunc ullamcorper tincidunt eget ac quam. Phasellus
          scelerisque blandit ullamcorper. Proin porttitor vitae mauris vel
          tempor. Mauris auctor, ipsum et ultricies congue, metus nunc feugiat
          mi, id dapibus sapien turpis quis lectus. Mauris blandit, enim eget
          porta bibendum, magna neque lobortis eros, bibendum gravida odio justo
          id orci. Nunc malesuada, est eget finibus condimentum, felis odio
          vulputate nisl, eget commodo diam erat at est. Fusce finibus justo
          neque, a eleifend neque fermentum ac. Nulla venenatis turpis sit amet
          neque vestibulum sollicitudin. Nam porttitor nibh id porta pharetra.
          Vestibulum eu iaculis eros, eu blandit sapien. Curabitur facilisis
          finibus ullamcorper. Fusce est enim, placerat eu nibh eu, consectetur
          finibus turpis. Suspendisse sed mattis metus. In commodo ex ut cursus
          venenatis. Aliquam mollis et massa quis consequat. Aenean mi dolor,
          mattis nec nulla sed, suscipit ullamcorper diam. Nulla imperdiet
          lectus lectus. Cras eu velit malesuada, lacinia urna at, sollicitudin
          elit. Aenean accumsan consequat pretium. Quisque vitae nulla posuere,
          iaculis nulla id, scelerisque nulla. Nunc commodo sollicitudin
          efficitur. Quisque orci arcu, accumsan quis aliquet id, hendrerit id
          lacus. In venenatis malesuada ipsum, non blandit nibh tristique id.
          Etiam aliquam cursus lacus in faucibus. In quis urna scelerisque,
          tempor dui a, tempor dui. Generated 5 paragraphs, 486 words, 3268
          bytes of Lorem Ipsum
        </p>

        <Link to='/'>Home</Link>
      </div>
    </form>
  );
}

export default TermsPage;
