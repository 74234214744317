import { bounceInUp, bounceInLeft, tada, zoomInLeft, fadeIn } from "react-animations";
import { keyframes } from "radium";

const animSpeed = "x 1.5s";

export const animations = {
    bounceInUp: {
        animation: animSpeed,
        animationName: keyframes(bounceInUp)
    },
    bounceInLeft: {
        animation: animSpeed,
        animationName: keyframes(bounceInLeft)
    },
    tada: {
        animation: animSpeed,
        animationName: keyframes(tada)
    },
    zoomInLeft: {
        animation: animSpeed,
        animationName: keyframes(zoomInLeft)
    },
    fadeIn: {
        animation: animSpeed,
        animationName: keyframes(fadeIn)
    },
};