import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './privateRoute';

import CompanyDetailsPage from '../../components/pages/company-details';
import DashboardPage from '../../components/pages/dashboard';
import HomePage from '../../components/pages/home';
import MessagesPage from '../../components/pages/messages';
import MyClientsPage from '../../components/pages/my-clients';
import OnBoardingPage from '../../components/pages/onboarding';
import Privacy from '../../components/pages/privacy';
import ProviderClientsPage from '../../components/pages/provider-clients';
import ProfilePage from '../../components/pages/profile';
import TermsPage from '../../components/pages/terms';
import ServiceDiscoveryPage from '../../components/pages/service-discovery';


function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path='/dashboard' element={<PrivateRoute/>}>
          <Route exact path='/dashboard' element={<DashboardPage />}/>
      </Route>
      <Route exact path='/profile' element={<PrivateRoute/>}>
          <Route exact path='/profile' element={<ProfilePage />}/>
      </Route>
      <Route exact path='/onboarding' element={<PrivateRoute/>}>
          <Route exact path='/onboarding' element={<OnBoardingPage />}/>
      </Route>
      <Route exact path='/company-details' element={<PrivateRoute/>}>
          <Route exact path='/company-details' element={<CompanyDetailsPage />}/>
      </Route>
      <Route exact path='/company-clients' element={<PrivateRoute/>}>
          <Route exact path='/company-clients' element={<ProviderClientsPage />}/>
      </Route>
      <Route exact path='/my-clients' element={<PrivateRoute/>}>
          <Route exact path='/my-clients' element={<MyClientsPage />}/>
      </Route>
      <Route exact path='/service-discovery' element={<PrivateRoute/>}>
          <Route exact path='/service-discovery' element={<ServiceDiscoveryPage />}/>
      </Route>
      <Route exact path='/messages' element={<PrivateRoute/>}>
          <Route exact path='/messages' element={<MessagesPage />}/>
      </Route>

      <Route exact path='/privacy' component={Privacy} />
      <Route exact path='/terms' component={TermsPage} />
    </Routes>
  );
}

export default Router;
