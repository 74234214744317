import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './footer.scss';
import moment from 'moment'
import { useMediaQuery } from '../../components/pages/company-details/mediaQueries';

function Footer() {
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery('(max-width:600px)')

  const footerText = t('common:lblCopyright', { year: moment(new Date()).format('YYYY') })
  const footerTextMobile = footerText.split(".")[0]

  return (
    <footer className='Footer__Copyright'>
      <div className='Footer__Copyright__col-1'>
        {!isMobile ? (
          <p style={{ marginTop: '.4rem' }}>{footerText}</p>
        ) : (
          <p style={{ marginTop: '.4rem' }}>{footerTextMobile}.</p>
        )}
      </div>
      <div className='Footer__Copyright__col-2'>
        <Link to={'/privacy'}><p className='Footer__Copyright__col-2__text'>{t('common:lnkPrivacy')}</p></Link>
        <Link to={'/terms'}><p className='Footer__Copyright__col-2__text'>{t('common:lnkTerms')}</p></Link>
      </div>
    </footer>
  );
}

export default Footer;
