import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SuspenseView from '../../../suspense-view';
import UserServiceListView from './user-service-list-view';

import { getUserServicesList } from '../../../../api/user-service';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const useFetch = (userId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getUserServicesList(userId);
      if (success) {
        setData(data);
      } else {
        setError(errorMessage);
      }
    };

    fetchData();
  }, [userId]);

  return { data, error };
};

export default function ServicesTab({ userId }) {
  const { t, ready } = useTranslation(['common', 'error', 'profile']);
  const classes = useStyles();

  // Retrieve the user's service list data
  const response = useFetch(userId);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const servicesList = response.data;

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <UserServiceListView services={servicesList} userId={userId} />
    </div>
  );
}

ServicesTab.propTypes = {
  userId: PropTypes.number,
};
