import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '1rem',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useStyles;
