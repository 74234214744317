import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { BsFillTelephoneFill } from 'react-icons/bs'
import { AiFillMail, AiOutlineLink } from 'react-icons/ai'

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EditProviderDetailsDialog from '../../../dialogs/edit-provider-details/editProviderDetailsDialog';
import SuspenseView from '../../../../components/suspense-view';
import Toast from '../../../common/snackbar/toast';

import { getProviderDetails } from '../../../../api/provider';

import { formatPhoneNumber } from '../../../../utils/numberUtils';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../utils/toastUtils';

import { useTranslation } from 'react-i18next';
import useStyles, { typgraphyStylesName, typographyStylesDesc, imgStyles, fontFamilyPoppins, colorBlue } from './styles';

import './detailsPanel.scss'


const useFetch = (providerId, lastUpdated) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderDetails(
        providerId
      );

      if (success) {
        await setData(data);
      } else {
        await setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId, lastUpdated]);

  return { data, error };
};

export default function DetailsPanel({ providerId }) {
  const classes = useStyles();
  const { t, ready } = useTranslation(['provider', 'error', 'common']);
  const [showEditDialog, setShowEditDialog] = useState(false);

  // Dialog Methods
  const openEditDialog = () => {
    setShowEditDialog(true);
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:lblMsgDetailsSuccess')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:lblMsgFailUpdate')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  const [state, setState] = useState({
    lastUpdated: moment(),
  });

  const setLastUpdated = () => {
    setState((state) => ({
      ...state,
      lastUpdated: moment(),
    }));
  };

  const response = useFetch(providerId, state.lastUpdated);

  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('error:objectNotFound')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const responseData = response.data;

  let name = responseData.name;
  let description = responseData.description;
  let website = responseData.website;
  let contact_email = responseData.contact_email;
  let contact_number = formatPhoneNumber(responseData.contact_number);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <div className='DetailsPanel'>
        <img
          src='https://www.wework.com/ideas/wp-content/uploads/sites/4/2017/06/Web_150DPI-20190927_10th_Floor_Conference_Room_2_v1-1120x630.jpg'
          alt='temp-company'
          style={imgStyles}
        />
        <div className='DetailsPanel__row'>
          <Typography
            style={typgraphyStylesName}
            variant='h6' color='primary' gutterBottom>
            {name}
          </Typography>
          <IconButton
            style={{ marginTop: '-.5rem', marginLeft: '.5rem' }}
            aria-label='edit'
            onClick={openEditDialog}
          >
            <EditRoundedIcon fontSize='small' />
          </IconButton>
        </div>
        <Typography style={typographyStylesDesc} variant='subtitle1'>{description}</Typography>
      </div>

      <Divider variant='middle' style={{ margin: '1rem' }} />
      <div className={classes.companyInfoText}>
        <BsFillTelephoneFill style={colorBlue} />
        <Typography style={fontFamilyPoppins}>{contact_number}</Typography>
      </div>
      <div className={classes.companyInfoText}>
        <AiFillMail style={colorBlue} />
        <Typography style={fontFamilyPoppins}>{contact_email}</Typography>
      </div>
      <div className={classes.companyInfoText}>
        <AiOutlineLink style={colorBlue} />
        <Typography style={fontFamilyPoppins}>{website}</Typography>
      </div>
      <EditProviderDetailsDialog
        show={showEditDialog}
        handleClose={closeEditDialog}
        details={responseData}
        setLastUpdated={setLastUpdated}
        successMsg={handleSuccess}
        errorMsg={handleError}
      />
      <Toast toastList={list} />
    </div>
  );
}

DetailsPanel.propTypes = {
  providerId: PropTypes.number,
};
