import { awsApiRequest } from '../utils/func';

export async function getUserServicesList(userId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/users/${userId}/services`,
    });
  } catch (error) {
    return error;
  }
}
