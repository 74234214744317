const poppinsFont = 'Poppins, sans-serif'

export const divStyles = {
  width: '83%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
}

export const divStylesMedia = {
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
}

export const typographyStyles = {
  fontSize: '1.5rem',
  color: 'rgb(26, 80, 161)',
  fontWeight: '600',
  fontFamily: poppinsFont
}

export const inputStyles = {
  marginTop: '1rem',
  marginBottom: '1rem',
  padding: '.6rem',
  width: '22rem',
  borderRadius: '10px',
  fontFamily: poppinsFont,
  border: '1px solid rgba(26, 80, 161, 0.216)',
}

