import React from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useContext } from 'react';
import { ServiceContext } from '../serviceContext';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import FileUpload from '../../../fileUpload/FileUpload';
import '../stepperStyles.scss';


export default function ServiceForm({ handleNext }) {
  const { t } = useTranslation(['common', 'service']);
  const classes = useStyles();

  const { state, setState } = useContext(ServiceContext)

  console.log('STATE ON WIZARD ONE', state)

  // setting all potential values off the start to avoid values not populating when switching between states
  const initialValues = {
    name: state.service ? state.service.name : '',
    summary: state.service ? state.service.summary : '',
    files: state.service ? state.service.files : '',
    serviceCategory: state.service ? state.service.serviceCategory : '',
    serviceCategorySecondary: state.service ? state.service.serviceCategorySecondary : '',
    educationalResources: state.service ? state.service.educationalResources : '',
    eligibilityCriteria: state.service ? state.service.eligibilityCriteria : '',
    // geographicalAreaServed: state.service ? state.service.geographicalAreaServed : '',
    insurableDetails: state.service ? state.service.insurableDetails : '',
    insurableService: state.service ? state.service.insurableDetails : ''
  };

  const requiredTranslated = t('form:required');
  const LocationServiceSchema = Yup.object().shape({
    name: Yup.string().required(requiredTranslated),
    summary: Yup.string().required(requiredTranslated),
    //TODO: validate files with Yup.
  });


  const onSubmit = async (values) => {
    // Set the service values from the form
    setState({
      ...state,
      service: {
        ...state.service,
        name: values.name,
        summary: values.summary,
      }
    })

    handleNext();

  };

  const fieldTitleStyles = {
    fontWeight: '600',
    color: 'rgb(26, 80, 161)',
    fontSize: '1.3rem',
    marginBottom: '-1rem'
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={LocationServiceSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Grid
              container
              spacing={1}
              direction='column'
              justifyContent='center'
              alignItems='stretch'
            >

              <Grid item>
                <div style={{ marginTop: '.5rem' }}>
                  <h2 style={fieldTitleStyles}>{t('service:lblName')}</h2>
                  <Field
                    id='additional-field-styles'
                    component={FormikTextField}
                    className={classes.textField}
                    type='text'
                    name='name'
                    margin='normal'
                    variant='outlined'
                    placeholder={t('service:phServiceName')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <h2 style={fieldTitleStyles}>{t('service:lblSummary')}</h2>
                <Field
                  id='additional-field-styles'
                  component={FormikTextField}
                  className={classes.textField}
                  type='text'
                  name='summary'
                  multiline
                  rows={4}
                  margin='normal'
                  variant='outlined'
                  placeholder={t('service:phServiceSummary')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <h2 style={fieldTitleStyles}>{t('service:lblApplicationForms')}</h2>
                <FileUpload state={state} setState={setState} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction='row'
              justifyContent='space-between'
              alignItems='flex-end'
            >
              <br />
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    id='btn-hover-fx'
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    className={classes.button}
                  >
                    {t('common:btnNext')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

ServiceForm.propTypes = {
  handleNext: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func
};
