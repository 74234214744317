import React, { useState } from 'react'
import '../mobileHeader.scss';
import PropTypes from 'prop-types';


import { AiOutlineHome, AiOutlineUser, } from 'react-icons/ai';
import { BiMessageSquareDetail, BiDonateHeart } from 'react-icons/bi';
import { RiCompassDiscoverLine } from 'react-icons/ri';

import useAuthDataContext from '../../../../auth/hooks/useAuthDataContext';
import SquareIcon from '../../../common/icons/Square-Icon-white.svg';
import { FaBars } from 'react-icons/fa'

import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import AccountOptions from '../../account-options';
import CustomIcons from '../../../customIcons/CustomIcons'
import { useMediaQuery } from '../../../pages/company-details/mediaQueries';


const iconStyles = {
  color: '#fff',
  fontSize: '2rem',
  marginLeft: '1rem'
}

const buttonStyles = {
  color: '#fff',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.1rem'
}

const avatarStyles = {
  height: '35px',
  width: '35px',
  fontFamily: 'Poppins, sans-serif',
  marginRight: '1rem'
}

const ClientOptions = () => {
  // hides or reveals mobile nav content when clicked on hamburger icon //
  const [showNav, setShowNav] = useState(false);

  // used to close menu if screen is expanded past mobile view while menu is open //
  // if screen is then switched back to mobile view, menu state is closed //
  const isMobile = useMediaQuery('(min-width: 621.5px)');
  if (isMobile && showNav === true) {
    setShowNav(false)
  }

  const { isSignedIn, user, userDetails, signOut } = useAuthDataContext();
  const { t } = useTranslation(['common', 'form', 'authentication']);
  const { goToPage } = useAuthDataContext();

  function handleClickDashboard() {
    goToPage('/dashboard');
    setShowNav(false);
  }

  function handleClickProfile() {
    goToPage('/profile');
    setShowNav(false);
  }

  function handleMessageClick() {
    goToPage('/messages');
    setShowNav(false);
  }

  function handleServiceDiscoveryClick() {
    goToPage('/service-discovery');
    setShowNav(false);
  }

  function handleOnboarding() {
    goToPage('/onboarding')
    setShowNav(false);
  }

  const SignedInControls = ({ user, signOut }) => {
    const showAccountIcons = user;

    return <React.Fragment>{showAccountIcons ? <AccountOptions signOut={signOut} /> : null}</React.Fragment>;
  };

  const handleShowNav = () => {
    //prevents user from accessing dropdown menu if not signed in //
    if (!isSignedIn) {
      setShowNav(false)
    }
    else if (isSignedIn) {
      setShowNav(!showNav)
    }
  }

  const setAvatarInitials = (firstName, lastName) => {
    if (firstName && lastName) {
      return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }

    return '';
  };

  let avatarInitials;

  if (userDetails) {
    let firstName = userDetails.first_name;
    let lastName = userDetails.last_name;
    avatarInitials = setAvatarInitials(firstName, lastName);
  }

  return (
    <React.Fragment>
      <div className='MobileHeader'>

        <FaBars
          className={showNav ? 'flipped' : 'not-flipped'}
          onClick={handleShowNav}
          style={iconStyles} id='hamburger-icon' />

        <img style={{ height: '4.5rem', userSelect: 'none' }} src={SquareIcon} alt={'common:svLogoIcon'} />

        {isSignedIn ? (
          <div className='MobileHeader__avatar'>
            <Avatar style={avatarStyles}>{avatarInitials}</Avatar>
            <SignedInControls user={user} signOut={signOut} />
          </div>
        ) : (
          <Button
            style={buttonStyles}
            onClick={() => goToPage('/signin')}>
            {t('authentication:signIn')}
          </Button>
        )}
      </div>
      <div className={showNav ? 'MobileHeader__content' : 'MobileHeader__content__none'}>
        <div hidden>
          <Button
            color='primary'
            startIcon={<AiOutlineHome />}
            onClick={handleClickDashboard}
          >
            {t('menuItems:lblHome')}
          </Button>
        </div>
        <div className='MobileHeader__content__row' onClick={handleClickProfile}>
          <span>
            <CustomIcons
              icon={<AiOutlineUser />}
              BgColor={'rgb(26, 80, 161)'}
              iconColor={'#fff'} />
          </span>
          <p>{t('menuItems:lblProfile')}</p>
        </div>
        <div className='MobileHeader__content__row' onClick={handleMessageClick}>
          <span>
            <CustomIcons
              icon={<BiMessageSquareDetail />}
              BgColor={'rgb(26, 80, 161)'}
              iconColor={'#fff'} />
          </span>
          <p>{t('menuItems:lblMessages')}</p>
        </div>
        <div className='MobileHeader__content__row' onClick={handleServiceDiscoveryClick}>
          <span>
            <CustomIcons
              icon={<RiCompassDiscoverLine />}
              BgColor={'rgb(26, 80, 161)'}
              iconColor={'#fff'} />
          </span>
          <p>{t('menuItems:lblDiscover')}</p>
        </div>
        <div className='MobileHeader__content__row' onClick={handleOnboarding}>
          <span>
            <CustomIcons
              icon={<BiDonateHeart />}
              BgColor={'rgb(26, 80, 161)'}
              iconColor={'#fff'} />
          </span>
          <p>{'Your Needs'}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

ClientOptions.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func,
  userDetails: PropTypes.object,
};


export default ClientOptions;