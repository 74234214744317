export const inputStyles = {

  control: (styles) => ({
    ...styles,
    color: "gray",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    marginTop: "1.5rem",
    padding: ".4rem",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "gray",
    fontFamily: "Poppins, sans-serif",
    opacity: '.5',
  }),
  option: (styles, { isSelected, isFocused, isDisabled }) => ({
    ...styles,
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.3rem",
    color: "rgb(26, 80, 161)",
    fontWeight: '600',
    // border: '1px solid gray',
    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? "rgb(234, 239, 247)"
        : isFocused
          ? "rgb(234, 239, 247)"
          : '#fff',
    cursor: isDisabled ? "not-allowed" : "pointer",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled
        ? isSelected
          ? "rgb(234, 239, 247)"
          : "rgb(234, 239, 247)"
        : undefined
    }
  })
};

export const inputStylesErr = {
  control: (styles) => ({
    ...styles,
    color: "gray",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    marginTop: "1.5rem",
    padding: ".4rem",
    border: '1px solid red'
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "gray",
    fontFamily: "Poppins, sans-serif",
    opacity: '.5',

  }),
  option: (styles, { isSelected, isFocused, isDisabled }) => ({
    ...styles,
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.3rem",
    color: "rgb(26, 80, 161)",
    fontWeight: '600',
    textAlign: 'center',
    border: '1px solid rgb(234, 239, 247)',

    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? "rgb(234, 239, 247)"
        : isFocused
          ? "rgb(234, 239, 247)"
          : 'white',
    cursor: isDisabled ? "not-allowed" : "pointer",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled
        ? isSelected
          ? "rgb(234, 239, 247)"
          : "rgb(234, 239, 247)"
        : undefined
    }
  })
};