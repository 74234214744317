import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocationContext } from '../locationContext';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import ServiceListItemView from './service-list-item-view';
import SuspenseView from '../../../suspense-view';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import { getProviderServiceList } from '../../../../api/provider-services';
import { moreMarginStyles } from '../../customStyles/customStyles';

const titleStyles = {
  fontSize: '1.7rem',
  fontWeight: '600',
  color: 'rgb(26, 80, 161)'
}

// Get the services list
const useFetch = (providerId) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderServiceList(
        providerId
      );
      if (success) {
        await setData(data);
      } else {
        await setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId]);

  return { data, error };
};

export default function ServicesForm({ handleNext, handleBack }) {
  const { t, ready } = useTranslation(['location', 'common']);
  const { state } = useContext(LocationContext)
  const classes = useStyles();

  const onSubmit = async () => {
    // Set the service values from the form
    handleNext();
  };

  const response = useFetch(state.providerId);

  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const responseData = response.data;

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <>
      <h3 style={titleStyles}>
        {'Select Services'} ({state.services.length})
      </h3>

      <List className={classes.root}>
        {responseData.map((entry) => (
          <ServiceListItemView
            key={entry.id}
            service={entry}
          />
        ))}
      </List>
      <br />
      <Grid style={moreMarginStyles} container justifyContent='flex-end'>
        <Grid item>
          <Button
            style={{ padding: '.5rem 0' }}
            id='btn-back' onClick={handleBack}>{t('common:btnBack')}</Button>
          <Button variant='contained' color='primary' onClick={onSubmit}>
            {t('common:btnNext')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

ServicesForm.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};
