import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    marginBottom: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
