import React, { useState } from 'react';
import './hoursOfServiceForm.scss';
import ServiceDay from './serviceDay';
import Checkbox from '@mui/material/Checkbox';
import { ServiceContext } from '../serviceContext';
import { useContext } from 'react';

const HoursOfServiceForm = () => {
  const { state, setState } = useContext(ServiceContext)

  const compareState = {
    Monday: ["00:00", "00:00"],
    Tuesday: ["00:00", "00:00"],
    Wednesday: ["00:00", "00:00"],
    Thursday: ["00:00", "00:00"],
    Friday: ["00:00", "00:00"],
    Saturday: ["00:00", "00:00"],
    Sunday: ["00:00", "00:00"],
  }

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const [open24, setOpen24] = useState(JSON.stringify(state.hoursOfService) === JSON.stringify(compareState) ? true : false)

  const handleSetOpen24 = () => {
    if (open24) {
      setOpen24(false)
      setState({
        ...state,
        hoursOfService: {
          Monday: ["09:00", "17:00"],
          Tuesday: ["09:00", "17:00"],
          Wednesday: ["09:00", "17:00"],
          Thursday: ["09:00", "17:00"],
          Friday: ["09:00", "17:00"],
          Saturday: null,
          Sunday: null,
        }
      })
    } else {
      setOpen24(true)
      setState({
        ...state,
        hoursOfService: {
          Monday: ["00:00", "00:00"],
          Tuesday: ["00:00", "00:00"],
          Wednesday: ["00:00", "00:00"],
          Thursday: ["00:00", "00:00"],
          Friday: ["00:00", "00:00"],
          Saturday: ["00:00", "00:00"],
          Sunday: ["00:00", "00:00"],
        }
      })
    }
  }

  return (
    <div className='HoursOfServiceContainer'>
      <div className='HoursOfServiceForm'>
        {days.map((day, idx) => (
          <ServiceDay key={idx} day={day} state={state} setState={setState} open24={open24} />
        ))}

      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '-1rem', marginBottom: '2rem' }}>
        <Checkbox
          defaultChecked={open24 ? true : false}
          onClick={handleSetOpen24}
          sx={{
            color: 'rgb(26, 80, 161)',
            opacity: 1,
            '&.Mui-checked': {
              color: 'rgb(26, 80, 161)',
              opacity: 1
            },
          }} />
        <p style={{ marginLeft: '.2rem', fontSize: '1.2rem', fontWeight: '600', color: 'rgb(26, 80, 161)' }}>My Service Is Available 24 hours</p>
      </div>
    </div>
  )
}

export default HoursOfServiceForm;
