import React, { useContext, useEffect } from 'react'
import Select from "react-select";
import PropTypes from 'prop-types';
import { inputStyles, inputStylesErr } from '../../../common/select/styles';
import { LocationContext } from '../locationContext';


const titleStyles = {
  fontSize: '1.5rem',
  marginBottom: '-1rem',
  color: 'rgb(26, 80, 161)'
}

const HealthZoneSelect = ({ err, setErr, options }) => {
  const { state, setState } = useContext(LocationContext);
  const { province } = state.address;

  const handleAddHealthZones = (value) => {
    setState({
      ...state,
      healthZonesServed: value
    })
  }

  useEffect(() => {
    if (err !== '' && state.healthZonesServed.length > 0) {
      setErr('')
    }
  }, [err, setErr, state.healthZonesServed])

  return (
    <div style={{ marginBottom: '2rem' }}>
      <h3 style={titleStyles}>Select Health Zone(s)</h3>
      <Select
        styles={err === '' ? inputStyles : inputStylesErr}
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        placeholder={`Select or search health zones in ${province}, ${state.address.country}`}
        options={options}
        defaultValue={options.some((option) => option.value === state.healthZonesServed[0]) ? (
          state.healthZonesServed.map((zone) => {
            return { ['label']: zone, ['value']: zone }
          })
        ) : ''}
        onChange={(values) => handleAddHealthZones(values.map((value) => value.value))}
      />
      <p style={{ color: 'red', marginTop: '.2rem' }}> {err}</p>
    </div>
  )
}

HealthZoneSelect.propTypes = {
  err: PropTypes.string,
  setErr: PropTypes.func,
  options: PropTypes.array
};

export default HealthZoneSelect
