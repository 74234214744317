import CloudSymbol from '../../assets/upload.svg'
import PropTypes from 'prop-types';
import React, { useRef, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import "./fileUpload.scss";

const baseStyle = {
  border: "2px dashed rgba(59, 59, 59, 0.192)"
};

const activeStyle = {
  border: "2px dashed rgba(0, 128, 0, 0.349)",
  background: "rgb(244, 249, 253)"
};

// const attachmentStyles = {
//   fontSize: "1.1rem",
//   marginTop: '.5rem',
//   marginBottom: '.5rem'
// }

const FileUpload = ({ state, setState }) => {
  const fileEl = useRef(null);

  const [files, setFiles] = useState(state.service.files ? state.service.files : {})


  useEffect(() => {
    setState({
      ...state,
      service: {
        ...state.service,
        files: files
      }
    })
  }, [setFiles, files])

  const handleChange = () => {
    setFiles(Object.values(fileEl.current.files));
  };


  const { t } = useTranslation(['service']);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".pdf, image/jpg, image/png",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {})
    }),
    [isDragActive]
  );

  const FilesNames = () => {

    return (
      <div>
        {files.map((file, idx) => (
          <p style={{ color: 'rgb(26, 80, 161)' }} key={idx}>{idx + 1}: {file.name}</p>
        ))}
      </div>
    )

  }

  return (
    <React.Fragment>
      <div className="FileUpload" {...getRootProps({ style })}>
        <label id="label" htmlFor="fileUpload">
          <img style={{ height: "3rem" }} src={CloudSymbol} alt="#" />
        </label>
        <p>
          {t('lblDragAndDrop')} <label htmlFor="fileUpload">{t('lblClickHere')}</label>
          {' '}
          {t('lblToBrowseFiles')}
        </p>

        {state.service.files && state.service.files.length >= 1 ? (
          <div style={{ margin: '.5rem', textAlign: 'center', }}>
            <FilesNames />
          </div>
        ) : null}

        <p id="subText">{t('lblUploadFileDetails')}</p>
      </div>
      <div style={{ display: "none" }}>
        <input
          {...getInputProps()}
          ref={fileEl}
          onChange={handleChange}
          type="file"
          id="fileUpload"
          name="fileUpload"
          multiple
        />
      </div>
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,

};

export default FileUpload;

