import React from 'react'
import PropTypes from 'prop-types';
import '../clientOnBoarding.scss'
import { useTranslation } from 'react-i18next';

const titleStyles = {
  fontSize: '2.5rem',
  marginBottom: '2rem',
  color: 'rgb(26, 80, 161)'

}

const ClientSummary = ({ values, setValues }) => {
  const { t } = useTranslation(['client']);

  const handleRemoveNeed = (option) => {
    setValues({
      ...values,
      needs: values.needs.filter((need) => need !== option)
    })

  }
  return (
    <div className='ClientOnBoarding'>
      <h2 style={titleStyles}>{t('lblProfileSummary')}</h2>
      <div className='ClientOnBoarding__summary'>
        <div className='ClientOnBoarding__summary__row end'>
          <h2>{t('lblName')}:</h2>
          <p>{values.firstName} {values.lastName}</p>
          <h2>{t('lblAddress')}:</h2>
          <p>{values.address}</p>
          <h2>{t('lblDateOfBirth')}:</h2>
          <p>{values.dateOfBirth.month}/{values.dateOfBirth.day}/{values.dateOfBirth.year}</p>
        </div>
        <div className='ClientOnBoarding__summary__row'>
          <h2>{t('lblPhoneNumber')}:</h2>
          <p>{values.phoneNumber}</p>
          <h2>{t('lblGender')}:</h2>
          <p>{values.gender}</p>
          <h2>{t('lblSelectedNeeds')}:</h2>
          {values.needs.map((need, idx) => (
            <p key={idx}>{idx + 1}: {need} <span style={{ fontWeight: '600' }} id='hover' onClick={() => handleRemoveNeed(need)}>X</span></p>
          ))}
        </div>
      </div>
    </div>
  )
}
ClientSummary.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired
};

export default ClientSummary
