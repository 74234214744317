import React from 'react';
import ServUsLogo from './ServUsIconWHITE.png';
import PropTypes from 'prop-types';
import './customSpinner.scss';

const CustomSpinner = ({ height, width }) => {
  return (
    <div className='CustomSpinner' style={{ height: `${height}vh`, width: `${width}vw` }}>
      <div className='CustomSpinner__container'>
        <img src={ServUsLogo} alt={ServUsLogo} />
      </div>
    </div>
  )
}

CustomSpinner.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}


export default CustomSpinner
