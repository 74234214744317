import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    // overflow: 'auto',
    height: 'auto',
    width: 'auto',
    minWidth: 0, //EXTRA WIDTH COMING FROM HERE
    float: 'right'
  },
}));

export default useStyles;
