// British Columbia
export const BC_OPTIONS = [
    { label: 'Fraser Health', value: 'Fraser Health' },
    { label: 'Interior Health', value: 'Interior Health' },
    { label: 'Island Health', value: 'Island Health' },
    { label: 'Northern Health', value: 'Northern Health' },
    { label: 'Vancouver Coastal Health', value: 'Vancouver Coastal Health' },
]

// Alberta
export const AB_OPTIONS = [
    { label: 'North', value: 'North' },
    { label: 'Edmonton', value: 'Edmonton' },
    { label: 'Central', value: 'Central' },
    { label: 'Calgary', value: 'Calgary' },
    { label: 'South', value: 'South' },
]

// Saskatchewan
export const SK_OPTIONS = [
    { label: 'Far North', value: 'Far North' },
    { label: 'North', value: 'North' },
    { label: 'Central', value: 'Central' },
    { label: 'South', value: 'South' },
    { label: 'Saskatoon', value: 'Saskatoon' },
    { label: 'Regina', value: 'Regina' },
]

// Ontario
export const ON_OPTIONS = [
    { label: 'Erie St. Clair LHIN', value: 'Erie St. Clair LHIN' },
    { label: 'South West LHIN', value: 'South West LHIN' },
    { label: 'Waterloo LHIN', value: 'Waterloo LHIN' },
    { label: 'Hamilton Niagara Haldimand Brant LHIN', value: 'Hamilton Niagara Haldimand Brant LHIN' },
    { label: 'Central West LHIN', value: 'Central West LHIN' },
    { label: 'Mississauga LHIN', value: 'Mississauga LHIN' },
    { label: 'Toronto Central LHIN', value: 'Toronto Central LHIN' },
    { label: 'Central LHIN', value: 'Central LHIN' },
    { label: 'Central East LHIN', value: 'Central East LHIN' },
    { label: 'South East LHIN', value: 'South East LHIN' },
    { label: 'Champlain LHIN', value: 'Champlain LHIN' },
    { label: 'North Simcoe Muskoka LHIN', value: 'North Simcoe Muskoka LHIN' },
    { label: 'North East LHIN', value: 'North East LHIN' },
    { label: 'North West LHIN', value: 'North West LHIN' },
]

// Quebec
export const QC_OPTIONS = [
    { label: 'Bas-Saint-Laurent', value: 'Bas-Saint-Laurent' },
    { label: 'Saguenay – Lac-Saint-Jean', value: 'Saguenay Lac-Saint-Jean' },
    { label: 'Capitale-Nationale', value: 'Capitale-Nationale' },
    { label: 'Mauricie-et-Center-du-Québec', value: 'Mauricie-et-Center-du-Quebec' },
    { label: 'Estrie', value: 'Estrie' },
    { label: 'Montreal', value: 'Montreal' },
    { label: 'Outaouais', value: 'Outaouais' },
    { label: 'Abitibi-Témiscamingue', value: 'Abitibi-Temiscamingue' },
    { label: 'North Shore', value: 'North Shore' },
    { label: 'Nord-du-Québec', value: 'Nord-du-Quebec' },
    { label: 'Gaspésie – Îles-de-la-Madeleine', value: 'Gaspesie Iles-de-la-Madeleine' },
    { label: 'Chaudière-Appalaches', value: 'Chaudiere-Appalaches' },
    { label: 'Laval', value: 'Laval' },
    { label: 'Lanaudière', value: 'Lanaudiere' },
    { label: 'Laurentians', value: 'Laurentians' },
    { label: 'Montérégie', value: 'Monteregie' },
    { label: 'Nunavik', value: 'Nunavik' },
    { label: 'Terres-Cries-de-la-Baie-James', value: 'Terres-Cries-de-la-Baie-James' },
]

// New Brunswick
export const NB_OPTIONS = [
    { label: 'Moncton/South-East Area', value: 'Moncton/South-East Area' },
    { label: 'Fundy Shore and Saint John Area', value: 'Fundy Shore and Saint John Area' },
    { label: 'Fredericton and River Valley Area', value: 'Fredericton and River Valley Area' },
    { label: 'Madawaska and North-West Area', value: 'Madawaska and North-West Area' },
    { label: 'Restigouche Area', value: 'Moncton/South-East Area' },
    { label: 'Bathurst and Acadian Peninsula Area', value: 'Bathurst and Acadian Peninsula Area' },
    { label: 'Miramichi Area', value: 'Miramichi Area' },
]

// New Foundland and Labradour
export const NL_OPTIONS = [
    { label: 'Eastern Regional Health Authority', value: 'Eastern Regional Health Authority' },
    { label: 'Central Regional Health Authority', value: 'Central Regional Health Authority' },
    { label: 'Western Regional Health Authority', value: 'Western Regional Health Authority' },
    { label: 'Labrador-Grenfell Regional Health Authority', value: 'Labrador-Grenfell Regional Health Authority' },
]

// Nova Scotia
export const NS_OPTIONS = [
    { label: 'Central Zone', value: 'Central Zone' },
    { label: 'Eastern Zone', value: 'Eastern Zone' },
    { label: 'Western Zone', value: 'Western Zone' },
    { label: 'Northern Zone', value: 'Northern Zone' },
]

// Manitoba
export const MB_OPTIONS = [
    { label: 'Interlake-Eastern Regional Health Authority', value: 'Interlake-Eastern Regional Health Authority' },
    { label: 'Northern Regional Health Authority', value: 'Northern Regional Health Authority' },
    { label: 'Prairie Mountain Health', value: 'Prairie Mountain Health' },
    { label: 'Southern Health-Santé Sud', value: 'Southern Health-Sante Sud' },
    { label: 'Winnipeg Regional Health Authority', value: 'Winnipeg Regional Health Authority' },
]

// Prince Edward Island
export const PE_OPTIONS = [
    { label: 'Health PEI', value: 'Health PEI' }
]

// Northern Territories
export const NT_OPTIONS = [
    { label: 'Beaufort-Delta HSS Authority', value: 'Beaufort-Delta HSS Authority' },
    { label: 'Sahtu HSS Authority', value: 'Sahtu HSS Authority' },
    { label: 'Deh Cho HSS Authority', value: 'Deh Cho HSS Authority' },
    { label: 'Yellowknife HSS Authority', value: 'Yellowknife HSS Authority' },
    { label: 'Stanton Territorial Health Authority', value: 'Stanton Territorial Health Authority' },
    { label: 'Fort Smith HSS Authority', value: 'Fort Smith HSS Authority' },
    { label: 'Hay River Health and Social Services Authority', value: 'Hay River Health and Social Services Authority' },
    { label: 'Tłįchǫ Community Services Agency', value: 'Tlicho Community Services Agency' },
]

// Yukon
export const YT_OPTIONS = [
    { label: 'Yukon Territory Health Region', value: 'Yukon Territory Health Region' }
]

// Nunavut
export const NU_OPTIONS = [
    { label: 'Nunavut Health Region', value: 'Nunavut Health Region' }
]