export const primaryOptions = [
    { label: "Healthcare", value: "Healthcare" },
    { label: "Mental Health", value: "Mental Health" },
    { label: "Recreational Activities", value: "Recreational Activities" },
    { label: "Transportation", value: "Transportation" },
    { label: "In Home Support", value: "In Home Support" },
    { label: "Housing", value: "Housing" },
    { label: "Financial", value: "Financial" },
    { label: "Legal", value: "Legal" },
    { label: "End of Life Care", value: "End of Life Care" },
    { label: "Resources for Caregivers", value: "Resources for Caregivers" }
];

//======SECONDARY OPTIONS=======//

export const healthCareOptions = [
    { label: "Acupuncture", value: "Acupuncture" },
    { label: "Dental", value: "Dental" },
    { label: "Dietician", value: "Dietician" },
    { label: "Family Doctor", value: "Family Doctor" },
    { label: "Foot Care (incl orthotics)", value: "Foot Care (incl orthotics)" },
    { label: "Health Coaching ", value: "Health Coaching " },
    { label: "Health Education", value: "Health Education" },
    { label: "Hearing Care", value: "Hearing Care" },
    { label: "Hospital Care", value: "Hospital Care" },
    { label: "In Home Health Assessment", value: "In Home Health Assessment" },
    { label: "Massage Therapy", value: "Massage Therapy" },
    { label: "Medical Equipment", value: "Medical Equipment" },
    { label: "Nurse or LPN Services", value: "Nurse or LPN Services" },
    { label: "Occupational Therapy", value: "Occupational Therapy" },
    { label: "Patient Support Services", value: "Patient Support Services" },
    { label: "Physical Therapy", value: "Physical Therapy" },
    { label: "Prosthetics", value: "Prosthetics" },
    { label: "Physical Therapy", value: "Physical Therapy" },
    { label: "Urgent Care Center", value: "Urgent Care Center" },
    { label: "Vision Care", value: "Vision Care" }
];

export const mentalHealthOptions = [
    { label: "Therapy Animals", value: "Therapy Animals" },
    { label: "Grief and Loss Counselling", value: "Grief and Loss Counselling" },
    { label: "Addiction Services", value: "Addiction Services" },
    { label: "Emotional Support", value: "Emotional Support" },
    { label: "Counselling", value: "Counselling" },
    { label: "Holistic Therapy", value: "Holistic Therapy" },
    { label: "Mental Health", value: "Mental Health" },
    { label: "Education", value: "Education" }
];

export const recActivities = [
    { label: "Adult Day Program", value: "Adult Day Program" },
    { label: "Community Event", value: "Community Event" },
    { label: "Exercise Program", value: "Exercise Program" },
    { label: "Fitness Education", value: "Fitness Education" },
    { label: "Recreation", value: "Recreation" },
    { label: "Social Program", value: "Social Program" }
];

export const transportationOptions = [
    { label: "Disability Support", value: "Disability Support" },
    { label: "Medical Transportation", value: "Medical Transportation" },
    { label: "Public Transportation", value: "Public Transportation" },
    { label: "Parking Permits", value: "Parking Permits" },
    { label: "Vehicle Registration", value: "Vehicle Registration" }
];

export const inHomeSupportOptions = [
    { label: "Bath Service", value: "Bath Service" },
    { label: "Child Care", value: "Child Care" },
    { label: "Cleaning Services", value: "Cleaning Services" },
    { label: "Food", value: "Food" },
    { label: "Non-Medical Home Care", value: "Non-Medical Home Care" },
    { label: "Medical Home Healthcare", value: "Medical Home Healthcare" },
    {
        label: "Outdoor Maintenance (lawn/snow)",
        value: "Outdoor Maintenance (lawn/snow)"
    },
    { label: "Pet Care", value: "Pet Care" },
    { label: "Phones", value: "Phones" },
    { label: "Smart Home Technology", value: "Smart Home Technology" }
];

export const housingOptions = [
    { label: "Assisted Living Facility", value: "Assisted Living Facility" },
    {
        label: "Independent Living Facility",
        value: "Independent Living Facility"
    },
    { label: "Long Term Care Homes", value: "Long Term Care Homes" },
    { label: "Relocation Service", value: "Relocation Service" }
];

export const financialOptions = [
    { label: "Financial Education", value: "Financial Education" },
    { label: "Financial Planner", value: "Financial Planner" },
    { label: "Tax Clinics", value: "Tax Clinics" },
];

export const legalOptions = [
    { label: "Elder Abuse", value: "Elder Abuse" },
    { label: "Will & Estate Planning", value: "Will & Estate Planning" },
    { label: "Legal Education", value: "Legal Education" }
];

export const endOfLifeOptions = [
    { label: "Advanced Care Planning", value: "Advanced Care Planning" },
    { label: "Death Doula", value: "Death Doula" },
    { label: "Hospice Care", value: "Hospice Care" },
    { label: "End of Life Education", value: "End of Life Education" }
];

export const resourcesCaregiversOptions = [
    { label: "Caregiver Support Groups", value: "Caregiver Support Groups" },
    { label: "Caregiver Training", value: "Caregiver Training" },
    { label: "Caregiver Education", value: "Caregiver Education" },
    { label: "Senior Advocate Program", value: "Senior Advocate Program" },
    { label: "Volunteer", value: "Volunteer" }
];
