import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { useContext } from 'react';
import { LocationContext } from '../../locationContext';

import Tooltip from '@material-ui/core/Tooltip'

import { tooltipClasses } from '@mui/material';


const divStylesChecked = {
  margin: '1rem',
  padding: '.8rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'auto',
  borderRadius: '35px',
  alignItems: 'center',
  opacity: '1',
  color: 'white',
  backgroundColor: 'rgb(26, 80, 161)',
  fontSize: '1.3rem'
}

const divStyles = {
  margin: '1rem',
  padding: '1rem 3rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 'auto',
  borderRadius: '35px',
  opacity: '.7',
  color: 'rgb(26, 80, 161)',
  backgroundColor: 'rgb(234, 239, 247)',
  fontSize: '1.3rem'
}

const spanStyles = {
  marginRight: '1rem',
  fontSize: '1.8rem'
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    width: '20 rem',
    fontSize: '1rem',
    border: '1px solid rgb(234, 239, 247)',
    borderRadius: '15px',
    padding: '.5rem 2rem',
    fontFamily: 'Poppins, sans-serif'
  },
}));

export default function ServicesListItemView({
  service,
}) {

  const { state, setState } = useContext(LocationContext)

  const handleToggle = (value) => () => {
    if (state.services.some(service => service.id === value.id)) {
      setState({
        ...state,
        services: state.services.filter((service) => service.id !== value.id)
      })
    } else {
      setState({
        ...state,
        services: [...state.services, value]
      })
    }
  };

  return (
    <React.Fragment>
      <HtmlTooltip
        enterNextDelay={500}
        title={
          <div className='LocationFormPopup'>
            <h3>{service.name}</h3>
            <p>{service.summary}</p>
          </div>
        }
      >

        <div
          id='hover-notchecked'
          style={state.services.some(s => s.id === service.id) ? divStylesChecked : divStyles}
          onClick={handleToggle(service)}>
          <h3>{state.services.some(s => s.id === service.id) ? <span style={spanStyles}>&#10003;</span> : null}{service.name}</h3>
        </div>


      </HtmlTooltip>
    </React.Fragment>
  );
}

ServicesListItemView.propTypes = {
  service: PropTypes.object,
};
