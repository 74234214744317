import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './serviceTeamTab.scss'
import Typography from '@material-ui/core/Typography';
import { BsPlusLg } from 'react-icons/bs'
import AddServiceTeamDialog from '../../../dialogs/add-service-team';

import ServiceTeamListItemView from './service-team-list-item-view';
import SuspenseView from '../../../suspense-view';
import Toast from '../../../common/snackbar/toast';

import { useMediaQuery } from '../mediaQueries';

import { getProviderServiceTeamList } from '../../../../api/provider-service-team';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../utils/toastUtils';

import { useTranslation } from 'react-i18next';
import { divStyles, inputStyles, typographyStyles, divStylesMedia } from './styles';


const useFetch = (providerId, lastUpdated) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderServiceTeamList(
        providerId
      );
      if (success) {
        setData(data);
      } else {
        setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId, lastUpdated]);

  return { data, error };
};

export default function ServiceTeamTab({ providerId }) {

  const isMedia = useMediaQuery("(max-width: 900px)");

  // const classes = useStyles();
  const { t, ready } = useTranslation(['common', 'serviceTeam', 'error']);

  const [searchText, setSearchText] = useState('')


  // State value for tracking the list refresh last updated time
  const [serviceTeamState, setServiceTeamState] = useState({
    lastUpdated: moment(),
  });

  const setLastUpdated = () => {
    setServiceTeamState((serviceTeamState) => ({
      ...serviceTeamState,
      lastUpdated: moment(),
    }));
  };

  // Dialog Methods
  const [showServiceTeamDialog, setShowServiceTeamDialog] = useState(false);

  const openAddServiceTeamDialog = () => {
    setShowServiceTeamDialog(true);
  };

  const closeAddServiceTeamDialog = () => {
    setShowServiceTeamDialog(false);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('serviceTeam:lblSuccessNewMemberAdd')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('error:msgTeamMemberNotAdded')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  // Retrieve the service team list data
  const response = useFetch(providerId, serviceTeamState.lastUpdated);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const serviceTeams = response.data;

  let numberOfServiceTeams = _.get(serviceTeams, 'length', 0);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <React.Fragment>

      <div style={isMedia ? divStylesMedia : divStyles}>
        <Typography
          style={typographyStyles}
          variant='h6'>
          {t('serviceTeam:lblNumServiceTeams', {
            num: numberOfServiceTeams,
          })}
        </Typography>

        <input
          className='ServiceTeamTab__input'
          style={inputStyles}
          type='text'
          placeholder='Search Team Members'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>

      <div className='ServiceTeamTab__container'>
        {serviceTeams
          .filter((entry) => {

            const fullName = entry.first_name.toLowerCase() + ' ' + entry.last_name.toLowerCase() + ' '.repeat(50);

            if (fullName.includes(searchText.toLowerCase())) {
              return (
                <ServiceTeamListItemView
                  key={entry.id}
                  details={entry}
                  setLastUpdated={setLastUpdated}
                />
              );

            } else if (searchText === '') {
              return (
                <ServiceTeamListItemView
                  key={entry.id}
                  details={entry}
                  setLastUpdated={setLastUpdated}
                />
              )
            }

          }).map((entry) => (
            <ServiceTeamListItemView
              key={entry.id}
              details={entry}
              setLastUpdated={setLastUpdated}
            />
          ))}

        <div className='ServiceTeamTab__Add-New'>
          <BsPlusLg
            className='ServiceTeamTab__Add-New__Plus'
            onClick={openAddServiceTeamDialog} />
          <p>{t('serviceTeam:lblAddService')}</p>
          <p>{t('serviceTeam:lblServiceTeamMember')}</p>
        </div>

        <div hidden>
          <AddServiceTeamDialog
            show={showServiceTeamDialog}
            handleClose={closeAddServiceTeamDialog}
            providerId={providerId}
            setLastUpdated={setLastUpdated}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </div>
      </div>
      <Toast toastList={list} />
    </React.Fragment>
  );
}

ServiceTeamTab.propTypes = {
  providerId: PropTypes.number,
};
