import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { ServiceContext } from '../../serviceContext';

import '../../stepperStyles.scss';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';


import { BsInfoCircleFill } from 'react-icons/bs';
import RadioButton from '../../../../common/radio-button';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import HoursOfServiceForm from '../../hoursOfServiceForm/hoursOfServiceForm';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { moreMarginStyles } from '../../../customStyles/customStyles';

const RADIO_SELECT_YES = 'YES';
const RADIO_SELECT_NO = 'NO';

const fieldTitleStyles = {
  fontWeight: '600',
  color: 'rgb(26, 80, 161)',
  fontSize: '1.3rem',
  marginBottom: '-1rem'
}

const textStyles = {
  fontSize: '.8rem'
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function DetailsForm({ handleNext, handleBack }) {
  const { t } = useTranslation(['service', 'common']);
  const classes = useStyles();


  const { state } = useContext(ServiceContext)

  // Radio Button functions
  const handleChange = (event) => {
    const option = event.target.value;
    const showInsurableDetails = setShowInsurableDetails(option);

    setDetailsState((detailsState) => ({
      ...detailsState,
      insurableService: option,
      showInsurableDetails: showInsurableDetails,
    }));
  };

  const setShowInsurableDetails = (option) => {
    return option === RADIO_SELECT_YES ? true : false;
  };

  const clearState = {
    // Selected insurable service option
    insurableService: RADIO_SELECT_NO,
    showInsurableDetails: false,
  };

  const setInitialState = () => {
    if (state.service) {
      const insurableService = state.service.insurableService;
      const insurableServiceOption = insurableService
        ? RADIO_SELECT_YES
        : RADIO_SELECT_NO;

      return {
        // Selected insurable service option
        insurableService: insurableServiceOption,
        showInsurableDetails: insurableService,
      };
    } else {
      return clearState;
    }
  };

  const [detailsState, setDetailsState] = useState(setInitialState());

  // Set the initial values
  let serviceState = state.service;

  const initialValues = {
    hoursOfService: serviceState.hoursOfService
      ? serviceState.hoursOfService : 'TBD',
    insurableDetails: serviceState.insurableDetails
      ? serviceState.insurableDetails
      : '',
    eligibilityCriteria: serviceState.eligibilityCriteria
      ? serviceState.eligibilityCriteria
      : '',
    // geographicalAreaServed: serviceState.geographicalAreaServed
    //   ? serviceState.geographicalAreaServed
    //   : '',
    educationalResources: serviceState.educationalResources
      ? serviceState.educationalResources
      : ''
  };

  const requiredTranslated = t('form:required');
  let validationSchema = Yup.object().shape({
    insurableDetails: detailsState.showInsurableDetails
      ? Yup.string().required(requiredTranslated)
      : Yup.string(),
    hoursOfService: Yup.string(),
    eligibilityCriteria: Yup.string().required(requiredTranslated),
    // geographicalAreaServed: Yup.string().required(requiredTranslated),
    educationalResources: Yup.string().required(requiredTranslated)
  });

  const onSubmit = async (values) => {
    // Set the result of the radio button
    let insurableServiceValue =
      detailsState.insurableService === RADIO_SELECT_YES ? true : false;
    state.service.insurableService = insurableServiceValue;

    // Set the service values from the form
    state.service = Object.assign({}, state.service, values);

    handleNext();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <h2 style={fieldTitleStyles}>{t('service:lblInsurableService')}</h2>
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label='position'
                name='insurableService'
                defaultValue='top'
                value={detailsState.insurableService}
                onChange={handleChange}
                id='radio-additional-styles'
              >
                <FormControlLabel
                  value={RADIO_SELECT_YES}
                  control={
                    <RadioButton
                      inputProps={{
                        'aria-label': t('common:rbAriaLabelYes'),
                      }}
                    />
                  }
                  label={t('common:yes')}
                />
                <FormControlLabel
                  value={RADIO_SELECT_NO}
                  control={
                    <RadioButton
                      inputProps={{
                        'aria-label': t('common:rbAriaLabelNo'),
                      }}
                    />
                  }
                  label={t('common:no')}
                />
              </RadioGroup>
            </FormControl>
            <div hidden={!detailsState.showInsurableDetails}>
              <h2 style={fieldTitleStyles}>{t('service:lblInsurableDetails')}</h2>
              <Field
                id='additional-field-styles'
                component={FormikTextField}
                className={classes.textField}
                type='text'
                name='insurableDetails'
                margin='normal'
                variant='outlined'
                placeholder={t('service:phInsurableDetails')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className='ServiceHoursTitle'>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <p style={textStyles}>Open menu by clicking the arrow and adjust your hours accordingly.</p>
                    <p style={textStyles}>Clicking a day will toggle your service between being availble or not.</p>
                  </React.Fragment>
                }
              >
                <p><BsInfoCircleFill /></p>
              </HtmlTooltip>

              <h2>{t('service:lblHoursOfService')}</h2>
            </div>

            <HoursOfServiceForm />

            <h2 className={'margin-extra'} style={fieldTitleStyles}>{t('service:lblEligibilityCriteria')}</h2>
            <Field
              id='additional-field-styles'
              component={FormikTextField}
              className={classes.textField}
              type='text'
              name='eligibilityCriteria'
              multiline
              rows={4}
              margin='normal'
              variant='outlined'
              placeholder={t('service:phEligibilityCriteria')}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/*  <h2 style={fieldTitleStyles}>{t('service:lblGeographicalAreaServed')}</h2>
            <Field
              id='additional-field-styles'
              component={FormikTextField}
              className={classes.textField}
              type='text'
              name='geographicalAreaServed'
              margin='normal'
              variant='outlined'
              placeholder={t('service:phEligibilityCriteria')}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <h2 style={fieldTitleStyles}>{t('service:lblEducationalResources')}</h2>
            <Field
              id='additional-field-styles'
              component={FormikTextField}
              className={classes.textField}
              type='text'
              name='educationalResources'
              margin='normal'
              variant='outlined'
              placeholder={t('service:phEducationalResources')}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid
              container
              spacing={1}
              direction='row'
              justifyContent='space-between'
              alignItems='flex-end'
            >
              <br />
              <Grid style={moreMarginStyles} container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    id='btn-back'
                    style={{ padding: '.6rem 0' }} // TODO: fix this
                    onClick={handleBack}>{t('common:btnBack')}
                  </Button>
                  <Button
                    id='btn-hover-fx'
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    className={classes.button}
                  >
                    {t('common:btnNext')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

DetailsForm.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func
};
