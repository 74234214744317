import React from 'react';

import useStyles from './styles';

export default function MessagesPage() {
  const classes = useStyles();

  return (
    <form>
      <div className={classes.root}>
        <h2>Messages</h2>
      </div>
    </form>
  );
}
