const DATE_FORMAT_EN = 'dddd, MMMM Do, YYYY';
const DATE_FORMAT_FR = 'dddd Do MMMM YYYY';

// TODO: Replace this function when moment has locale 'L' support for this style
export const getDateFormatStringForLocale = (locale) => {
  switch (locale) {
    case 'en':
      return DATE_FORMAT_EN;
    case 'fr':
      return DATE_FORMAT_FR;
    default:
      return DATE_FORMAT_EN;
  }
};
