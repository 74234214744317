import CloudSymbol from '../../assets/upload.svg'
import PropTypes from 'prop-types';
import React, { useRef, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import "./fileUploadTeamMember.scss";
import { useFormikContext } from 'formik';

const baseStyle = {
  border: "2px dashed rgba(59, 59, 59, 0.192)"
};

const activeStyle = {
  border: "2px dashed rgba(0, 128, 0, 0.349)",
  background: "rgb(244, 249, 253)"
};

const attachmentStyles = {
  fontSize: "1rem",
  marginTop: '.5rem',
  marginBottom: '.5rem',
  fontWeight: '500'
}

const FileUploadTeamMember = ({ files, setFiles, name }) => {
  const fileEl = useRef(null);


  const { t } = useTranslation(['service']);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".pdf, image/jpg, image/png",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {})
    }),
    [isDragActive]
  );



  const { setFieldValue } = useFormikContext();

  const handleChange = (option) => {
    setFieldValue(name, option);
  };

  // const handleOnChange = () => {
  //   setFiles(Object.values(fileEl.current.files));
  // };

  return (
    <div className='FileUploadTeam__Container'>
      <div className="FileUploadTeam" {...getRootProps({ style })}>
        <label id="label" htmlFor="fileUpload">
          <img style={{ height: "4rem" }} src={CloudSymbol} alt="#" />
        </label>

      </div>
      <div style={{ display: "none" }}>
        <input
          {...getInputProps()}
          ref={fileEl}
          onChange={(files) => handleChange(files.map((file) => file.name))}
          type="file"
          id="fileUpload"
          name="fileUpload"
          multiple
        />
      </div>

      <p className='FileUploadTeam__text'>
        {t('lblDragAndDrop')} <label htmlFor="fileUpload">{t('lblClickHere')}</label><br />
        {' '}
        {t('lblToBrowseFiles')}
      </p>

      <p id="subTextTeam">{t('lblUploadFileDetails')}</p>

      {files.length === 1 ? (
        <p className='FileUploadTeam__upload-details' style={attachmentStyles}>{t('lblUploadOneFile', { number: files.length })}</p>
      ) : null}
      {files.length > 1 ? (
        <p className='FileUploadTeam__upload-details' style={attachmentStyles}>{t('lblUploadManyFiles', { number: files.length })}</p>
      ) : null}


    </div>
  );
};

FileUploadTeamMember.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  name: PropTypes.string
};

export default FileUploadTeamMember;