import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PasswordMustContain = ({ valid, err }) => {
    const { t } = useTranslation([
        'authentication',
    ]);
    return (
        <div className='SetPasswordContainer'>
            <p style={{ color: 'red' }}>{err}</p>
            <h2>{t('passwordMustContain')}</h2>
            <div className='SetPasswordContainer__row'>
                {valid.length ? <span style={{ color: 'green' }}>&#10003;</span> : <span style={{ opacity: '.3' }}>&#10005;</span>}
                <p className={valid.length ? 'SetPasswordContainer__row__valid' : 'SetPasswordContainer__row__not-valid'}>
                    {t('minimum8chars')}
                </p>
            </div>
            <div className='SetPasswordContainer__row'>
                {valid.uppercase ? <span style={{ color: 'green' }}>&#10003;</span> : <span style={{ opacity: '.3' }}>&#10005;</span>}
                <p className={valid.uppercase ? 'SetPasswordContainer__row__valid' : 'SetPasswordContainer__row__not-valid'}>
                    {t('atLeastOneUpperChar')}
                </p>
            </div>
            <div className='SetPasswordContainer__row'>
                {valid.specialChars ? <span style={{ color: 'green' }}>&#10003;</span> : <span style={{ opacity: '.3' }}>&#10005;</span>}
                <p className={valid.specialChars ? 'SetPasswordContainer__row__valid' : 'SetPasswordContainer__row__not-valid'}>
                    {t('atLeastOneSpecialChar')}
                </p>
            </div>
            <div className='SetPasswordContainer__row'>
                {valid.number ? <span style={{ color: 'green' }}>&#10003;</span> : <span style={{ opacity: '.3' }}>&#10005;</span>}
                <p className={valid.number ? 'SetPasswordContainer__row__valid' : 'SetPasswordContainer__row__not-valid'}>
                    {t('atLeastOneNum')}
                </p>
            </div>
            <div className='SetPasswordContainer__row'>
                {valid.match ? <span style={{ color: 'green' }}>&#10003;</span> : <span style={{ opacity: '.3' }}>&#10005;</span>}
                <p className={valid.match ? 'SetPasswordContainer__row__valid' : 'SetPasswordContainer__row__not-valid'}>
                    {t('Passwords must match')}
                </p>
            </div>
        </div>
    )
}

PasswordMustContain.propTypes = {
    valid: PropTypes.object,
    err: PropTypes.string
}

export default PasswordMustContain
