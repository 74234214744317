import React from 'react';
import { Link } from 'react-router-dom';

//import { useTranslation } from 'react-i18next';

//copied Consent Agreement Form from php version
//can be used as a placeholder as opposed to Latin text
function Privacy() {
  return (
    <form>
      <div>
        <h1>Privacy Statement</h1>

        <p>
          Here at ServUs Health Inc. we’re developing a new software
          application, called ServUs, to assist family caregivers of seniors in
          New Brunswick and across Canada with information and resources. Right
          now, we’re recruiting new users of ServUs to help us test our product
          and give us feedback on how to make it even better. We want to enroll
          YOU as a ServUs user and tester. This page will give you the
          information you need to decide whether you want to participate and
          join us in developing our exciting new software. What is ServUs?
          ServUs App is a web service and mobile software platform which the end
          users (seniors and their family caregivers) directly download and
          install on their Android and Apple devices and use it on computers.
          The software platform allows information-access to senior-friendly
          community resources, as well as access to care professionals and
          service providers. ServUs is able to improve care-coordination with
          family, friends, pharmacists, and relevant health care professionals
          with a direct chat application and is capable of delivering desired
          information and resources to the end users’ fingertips. The key
          features of the app are a) Information access to elder friendly social
          services, government programs and relevant resources in the proximity
          of seniors and their family caregivers across Canada, and b) a
          communication and coordination feature which will improve care
          coordination between senior or family caregiver with their informal
          and formal care team. The solution benefits family caregivers and
          seniors in reducing stress in finding resources when they need and
          reduces the time and improve care coordination, especially for a
          family caregiver who is juggling between full-time job and care giving
          responsibilities. Participating as a User and Tester of ServUs: What’s
          involved? You have the option to participate in both usability
          interviewing and beta-testing or beta-testing alone. If you agree to
          help and participate, the first step would be to sign this form.
          Usability Interview When participating in a usability interview, the
          next step would be to answer some questions about how you currently
          search for- and coordinate care. You will then be asked to do a few
          short tasks using the app, and then asked questions about your
          experience and opinion of the app. Then you will be asked to complete
          a questionnaire, which asks you for more details on the time
          requirements you spend delivering care to the senior you care for, and
          how you are currently accessing information about support.
          Beta-Testing You will be invited to complete a pre-test questionnaire
          and register as a user at the link provided below and start using the
          webservice. When the mobile app is ready we will send a notification
          to your email that you provided about steps to download the app your
          phone and to arrange a mutually agreeable time to meet. Later, after
          you have had experience with ServUs, we will ask you to complete a
          Postest Questionnaire and tell us whether using the app has helped
          you. After the pre-test questionnaire you will use the app for a time
          period between four to eight weeks before you take post-test
          questionnaire. The third step will be to participate in the followup
          feedback process through one or more of the following ways. We may ask
          you, during or after the testing period, questions about the product.
          You are under no obligation during the testing period to participate
          in these surveys, group meetings, or interviews; at this time you are
          only giving us your permission to contact you with invitations. You
          can leave the program at any time. Is My Confidentiality Going to be
          Protected? Yes. All the information that we receive from you via the
          surveys and other means will be shared only within our firm and among
          our organizational stakeholders (Canadian Institutions) and will be
          used exclusively for the purpose of improving the ServUs software app
          and webservice. Your personal identity will not be revealed and will
          always be kept confidential. We will follow applicable privacy laws
          and regulations. You agree that we may store your contact information
          in connection with the program. What Personal Information are you
          Going to Collect from Me? In order to enroll you in our ServUs testing
          program, we will ask you to supply ServUs Health Inc with your name,
          your email address, age, gender, phone number, and your location, as
          well as User Device Information, User identification (senior/family
          caregiver), and care recipient relationship Can I Talk to Someone If I
          Want Further Information? The testing of the ServUs app is being
          carried out by ServUs Health Inc. in collaboration with the J. Herbert
          Smith Centre for Technology Management and Entrepreneurship at the
          University of New Brunswick in Fredericton. If you have questions or
          comments, send them to Mr. Vikram Devaguptapu (Director of ServUs
          Health Inc.) atvikram@servusapp.com or Dr. Dhirendra Shukla (Chair of
          the J. Herbert Smith Centre for Technology Management and
          Entrepreneurship, University of New Brunswick) at dshukla@unb.ca. This
          project has been reviewed by the Research Ethics Board of the
          University of New Brunswick and is on the file as REB 2018-005. If you
          would like to talk to someone not directly connected with the testing
          project, contact Steven Turner, Chair of the Research Ethics Board, at
          turner@unb.ca. BY SIGNING BELOW, I UNDERSTAND THAT I AM AGREEING TO
          PARTICIPATE AS A USER / TESTER OF THE ServUs APP UNDER THESE TERMS AND
          SUBJECT TO THE CONDITIONS OF CONFIDENTIALITY DESCRIBED ABOVE.
        </p>
        <Link to='/'>Home</Link>
      </div>
    </form>
  );
}

export default Privacy;
