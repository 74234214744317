import React from 'react'
import PropTypes from 'prop-types';
import { divStyles, col1, col2, textStyles } from './styles';

const ClientDetailsCard = ({ icon, number, text, primColor, iconBg }) => {

    const iconStyle = {
        fontSize: '2rem',
        borderRadius: '50%',
        color: primColor
    }

    const numberStyles = {
        fontSize: '3rem',
        fontWeight: '300',
        color: primColor
    }

    const iconContainer = {
        borderRadius: '50%',
        width: '4rem',
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: iconBg,
        marginLeft: '2rem'
    }

    return (
        <div style={divStyles}>
            <div style={col1}>
                <div style={iconContainer}>
                    <span style={iconStyle}>{icon}</span>
                </div>

            </div>
            <div style={col2}>
                <h2 style={numberStyles}>{number}</h2>
                <p style={textStyles}>{text}</p>
            </div>
        </div>
    )
}

ClientDetailsCard.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.string,
    number: PropTypes.number,
    primColor: PropTypes.string,
    iconBg: PropTypes.string
}


export default ClientDetailsCard
