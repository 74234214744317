import React from 'react'
import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import ClientOnboardingForm from './client-onboarding/clientOnboardingForm';


export default function OnBoardingPage() {
    const { userDetails } = useAuthDataContext();

    return (
        <div>
            <ClientOnboardingForm
                client={userDetails} />
        </div>
    )
}
