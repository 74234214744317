import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const DEFAULT_LNG = 'en';

const allowedLanguages = ['en', 'fr'];
let lng = DEFAULT_LNG;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LNG,
    lng: lng,
    supportedLngs: allowedLanguages,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
    },
    react: {
      useSuspense: false,
    },
    cache: {
      enabled: true,
      expirationTime: 24 * 60 * 60 * 1000,
    },
  });

export const getCurrentLanguage = () => {
  return i18n.language;
};

export const changeLanguage = (lang) => {
  return i18n.changeLanguage(lang);
};

export default i18n;
