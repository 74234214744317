import React from 'react'
import Select from "react-select";
import { inputStyles } from './styles';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';


const SelectInput = ({ options, placeholder, isMulti, formName }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleChange = (option) => {
    setFieldValue(formName, option)
  }

  // console.log('VALUES ==>', values)

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <Select
        styles={inputStyles}
        className="basic-single"
        classNamePrefix="select"
        isClearable={isMulti ? true : false}
        isSearchable={true}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        onChange={isMulti ? (
          (values) => handleChange(values.map((value) => value.value))
        ) : (values) => handleChange(values.value)}

        defaultValue={isMulti && values[formName] ? (
          values[formName].map((value) => {
            return { ['label']: value, ['value']: value }
          })
        ) : values[formName] !== '' ? { ['label']: values[formName], ['value']: values[formName] } : placeholder
        }
      />
    </div>
  )
}

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  formName: PropTypes.string,
};

export default SelectInput;