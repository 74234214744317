import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PhoneNumberMask from '../../common/form/phone-number-mask';
import SuspenseView from '../../suspense-view';

import { editProviderDetails } from '../../../api/provider';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import { fieldTitleStyles, dialogContentStyles, buttonStyles, moreMarginStyles } from '../customStyles/customStyles';

export default function EditProviderDetailsDialog({
  show,
  handleClose,
  details,
  setLastUpdated,
  successMsg,
  errorMsg,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const { t, ready } = useTranslation(['common', 'error', 'form', 'provider']);
  const classes = useStyles();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmCancelModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmCancelModel = () => {
    setOpenCancel(false);
  };

  const requiredTranslated = t('form:required');
  const EditDetailsSchema = Yup.object().shape({
    name: Yup.string().required(requiredTranslated),
    contact_number: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[ ]([0-9]{3})[-]([0-9]{4})$/,
        t('form:invalidPhoneNumber')
      )
      .required(requiredTranslated),
    contact_email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
    website: Yup.string().notRequired(),
    description: Yup.string().notRequired(),
  });

  const onSubmit = async (values, actions) => {
    if (values && !values.isCancelButton) {
      const result = await editProviderDetails(details.id, values);
      if (result.success) {
        handleClose();
        setLastUpdated();
        successMsg();
      } else {
        let errorMessage;
        if (result.statusCode === 409) {
          errorMessage = t('provider:errorProviderAlreadyInUse', {
            name: values.name,
          });
        } else {
          errorMessage = t(result.errorMessage);
          errorMsg();
        }

        actions.setStatus({ msg: errorMessage });
      }
    } else {
      if (values.isDirty) {
        openConfirmCancelModel();
      } else {
        handleClose();
      }
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='update-provider-details-dialog-title'
      >
        <DialogTitle className='Stepper__title' id='update-provider-details-dialog-title'>
          {t('provider:lblEditDetails')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={details}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            validationSchema={EditDetailsSchema}
          >
            {({
              errors,
              touched,
              values,
              status,
              handleChange,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <h3 style={fieldTitleStyles}>{t('provider:lblName')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='name'
                  id='name'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.name}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.name && touched.name ? (
                  <div className={classes.error}>{errors.name}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('common:lblPhoneNumber')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  id='contact_number'
                  name='contact_number'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.contact_number}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: PhoneNumberMask }}
                />
                {errors.contact_number && touched.contact_number ? (
                  <div className={classes.error}>{errors.contact_number}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('common:lblEmail')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  id='contact_email'
                  name='contact_email'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.contact_email}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.contact_email && touched.contact_email ? (
                  <div className={classes.error}>{errors.contact_email}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('provider:lblWebsite')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  id='website'
                  name='website'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.website}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.website && touched.website ? (
                  <div className={classes.error}>{errors.website}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('provider:lblDescription')}</h3>
                <Field
                  multiline
                  rows={3}
                  className={classes.textField}
                  type='text'
                  id='description'
                  name='description'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.description}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.description && touched.description ? (
                  <div className={classes.error}>{errors.description}</div>
                ) : null}
                {status && status.msg && (
                  <Typography className={classes.error}>
                    {status.msg}
                  </Typography>
                )}

                <Grid
                  style={moreMarginStyles}
                  container justify='flex-end'>
                  <Button
                    id='btn-back'
                    variant='text'
                    color='inherit'
                    onClick={async () => {
                      values = {
                        isCancelButton: true,
                        isDirty: dirty,
                      };
                      onSubmit(values, {});
                    }}
                    aria-label='Cancel'
                  >
                    {t('common:btnCancel')}
                  </Button>
                  <Dialog
                    open={openCancel}
                    onClose={closeConfirmCancelModel}
                    aria-labelledby='form-dialog-title'
                  >
                    <DialogContent style={dialogContentStyles}>{t('form:discardChanges')}</DialogContent>
                    <DialogActions>
                      <Button
                        style={buttonStyles}
                        type='submit'
                        onClick={async () => {
                          closeConfirmCancelModel();
                          handleClose();
                        }}
                      >
                        {t('common:yes')}
                      </Button>
                      <Button style={buttonStyles} onClick={closeConfirmCancelModel} autoFocus>
                        {t('common:no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    id='btn-hover-fx'
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={!dirty || isSubmitting}
                  >
                    {t('common:btnSubmit')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

EditProviderDetailsDialog.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  details: PropTypes.object,
  setLastUpdated: PropTypes.func,
  successMsg: PropTypes.func,
  errorMsg: PropTypes.func,
};
