import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
import PropTypes from 'prop-types';
import { HiInformationCircle } from 'react-icons/hi';


const ServiceCarousel = ({ services }) => {

  return (
    <div className='Modal-Service__services'>
      <h2>Services:</h2>
      <Carousel
        className='Modal-Service__Carousel'
        autoPlay={false}
        animation={'fade'}
        duration={500}
        swipe={true}
        navButtonsAlwaysVisible={true}
        indicators={false}
        NextIcon={<MdOutlineArrowForwardIos />}
        PrevIcon={<MdOutlineArrowBackIos />}
        navButtonsProps={{
          style: {
            color: 'rgb(26, 80, 161)',
            fontSize: '2rem'
          }
        }}
        navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.

        }}
      >
        {services && services.map((service) => (
          <div className='Modal-Service__Carousel__container' key={service.id}>
            <div className='Modal-Service__Carousel__container__row'>
              <h4>{service.name}</h4>
            </div>
            <div className='Modal-Service__Carousel__container__row'>
              <p>{service.summary}</p>
            </div>
            <HiInformationCircle
              id='hover'
              style={{ fontSize: '1.8rem', color: 'rgb(26, 80, 161)' }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

ServiceCarousel.propTypes = {
  services: PropTypes.array,
}

export default ServiceCarousel;
