import { makeStyles } from '@material-ui/core/styles';

const poppinsFont = 'Poppins, sans-serif'

const useStyles = makeStyles(() => ({
  paginator: {
    justifyContent: 'center',
    width: '100%',
    marginBottom: '2rem',
  },
  paginatorMedia: {
    justifyContent: 'center',
    width: '100vw',
    marginBottom: '2rem',
  }
}));

export const divStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
}

export const divStylesMedia = {
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
}

export const typographyStyles = {
  fontSize: '1.5rem',
  color: 'rgb(26, 80, 161)',
  fontWeight: '600',
  fontFamily: poppinsFont
}

export default useStyles;
