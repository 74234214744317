import React from 'react';
import PropTypes from 'prop-types';

import { BsFillTelephoneFill } from 'react-icons/bs'
import { AiFillMail } from 'react-icons/ai'
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';
import { formatPhoneNumber } from '../../../../utils/numberUtils';
import SuspenseView from '../../../../components/suspense-view';

import { useTranslation } from 'react-i18next';
import useStyles, { typgraphyStylesName, imgStyles, fontFamilyPoppins, colorBlue } from './styles';
import './profileDetailsPanel.scss';

export default function ProfileDetailsPanel({ userDetails }) {
    const classes = useStyles();
    const { ready } = useTranslation(['provider', 'error', 'common']);

    if (!ready) {
        return <SuspenseView />;
    }

    const firstName = userDetails.first_name[0].toUpperCase() + userDetails.first_name.substring(1);
    const lastName = userDetails.last_name[0].toUpperCase() + userDetails.last_name.substring(1);
    const fullName = firstName + ' ' + lastName;

    const email = userDetails.email
    const contactNumber = formatPhoneNumber(userDetails.contact_number);

    return (
        <div className={classes.root}>
            <div className='ProfileDetailsPanel'>
                <img
                    src='https://media.istockphoto.com/photos/portrait-young-confident-smart-asian-businessman-look-at-camera-and-picture-id1288538088?b=1&k=20&m=1288538088&s=170667a&w=0&h=3efMku7kSXUhpVrErAVVgxp6G91tRZ_5seygOn68RnE='
                    alt='temp-profile'
                    style={imgStyles}
                />
                <div className='DetailsPanel__row'>
                    <Typography
                        style={typgraphyStylesName}
                        variant='h6' color='primary' gutterBottom>
                        {fullName}
                    </Typography>
                </div>
            </div>

            <Divider variant='middle' style={{ margin: '1rem' }} />
            <div className={classes.profileInfoText}>
                <BsFillTelephoneFill style={colorBlue} />
                <Typography style={fontFamilyPoppins}>{contactNumber}</Typography>
            </div>
            <div className={classes.profileInfoText}>
                <AiFillMail style={colorBlue} />
                <Typography style={fontFamilyPoppins}>{email}</Typography>
            </div>
        </div>
    );
}

ProfileDetailsPanel.propTypes = {
    userDetails: PropTypes.object,
};
