import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 25,
  },
  textField: {
    width: '100%',
  },
  button: {
    textTransform: 'none',
  },
}));

export default useStyles;
