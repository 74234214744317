export const freeConsult = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
]

export const ageSpeciality = [
    { label: '0 to 17 years', value: '0 to 17 years' },
    { label: '18 to 24 years', value: '18 to 24 years' },
    { label: '25 to 44 years', value: '25 to 44 years' },
    { label: '45 to 64 years', value: '45 to 64 years' },
    { label: '65 years and over', value: '45 to 64 years' },
]

export const areasOfPractice = [
    { label: 'Abuse', value: 'Abuse' },
    { label: 'Adaptation', value: 'Adaptation' },
    { label: 'Addictions', value: 'Addictions' },
    { label: 'Ageing', value: 'Ageing' },
    {
        label: 'Anger Management Issues',
        value: 'Anger Management Issues'
    },
    { label: 'Anxiety and/or Panic', value: 'Anxiety and/or Panic' },
    { label: 'Behaviour Management', value: 'Behaviour Management' },
    { label: 'Bereavement', value: 'Bereavement' },
    { label: 'Bipolar Disorder', value: 'Bipolar Disorder' },
    {
        label: 'Borderline Personality Disorder',
        value: 'Borderline Personality Disorder'
    },
    { label: 'Brain Health', value: 'Brain Health' },
    { label: 'Bullying', value: 'Bullying' },
    {
        label: 'Cancer Care and Support',
        value: 'Cancer Care and Support'
    },
    { label: 'Chronic Illness', value: 'Chronic Illness' },
    { label: 'Chronic Pain', value: 'Chronic Pain' },
    { label: 'COVID-19 Loneliness', value: 'COVID-19 Loneliness' },
    { label: 'Compassion Fatigue', value: 'Compassion Fatigue' },
    {
        label: 'Cross Cultural Issues',
        value: 'Cross Cultural Issues'
    },
    { label: 'Death and Dying', value: 'Death and Dying' },
    {
        label: 'Dementia and Alzheimer\'s',
        value: 'Dementia and Alzheimer\'s'
    },
    { label: 'Depression', value: 'Depression' },
    {
        label: 'Dissociative Disorders',
        value: 'Dissociative Disorders'
    },
    {
        label: 'Divorce and/or Seperation',
        value: 'Divorce and/or Seperation'
    },
    { label: 'Elder Care Issues', value: 'Elder Care Issues' },
    {
        label: 'Family Caregiver Stress',
        value: 'Family Caregiver Stress'
    },
    { label: 'Family Issues', value: 'Family Issues' },
    { label: 'First Nations Issues', value: 'First Nations Issues' },
    {
        label: 'Geriatric Issues and Counselling',
        value: 'Geriatric Issues and Counselling'
    },
    {
        label: 'Gender Identity Issues',
        value: 'Gender Identity Issues'
    },
    {
        label: 'Grandparenting Issues',
        value: 'Grandparenting Issues'
    },
    { label: 'Grief and Loss', value: 'Grief and Loss' },
    { label: 'Hoarding', value: 'Hoarding' },
    {
        label: 'Infidelity Learning Disabilities',
        value: 'Infidelity Learning Disabilities'
    },
    { label: 'LGBTQ+ Issues', value: 'LGBTQ+ Issues' },
    { label: 'Life Balance', value: 'Life Balance' },
    { label: 'Life Transitions', value: 'Life Transitions' },
    {
        label: 'Marriage and/or Relationship Issues',
        value: 'Marriage and/or Relationship Issues'
    },
    { label: 'Men\'s Issues', value: 'Men\'s Issues' },
    { label: 'Mood Disorders', value: 'Mood Disorders' },
    { label: 'Obesity', value: 'Obesity' },
    {
        label: 'Obsessive Compulsive Disorder',
        value: 'Obsessive Compulsive Disorder'
    },
    { label: 'Parent/Teen Conflict', value: 'Parent/Teen Conflict' },
    { label: 'Parenting Issues', value: 'Parenting Issues' },
    { label: 'Personal Growth', value: 'Personal Growth' },
    {
        label: 'Pre-Marital Counselling',
        value: 'Pre-Marital Counselling'
    },
    { label: 'Procrastination', value: 'Procrastination' },
    { label: 'Professional Burnout', value: 'Professional Burnout' },
    { label: 'Racial Identity', value: 'Racial Identity' },
    { label: 'Racism Issues', value: 'Racism Issues' },
    { label: 'Retirement', value: 'Retirement' },
    { label: 'Self-Esteem Issues', value: 'Self-Esteem Issues' },
    {
        label: 'Sleep Difficulties-Adults',
        value: 'Sleep Difficulties-Adults'
    },
    { label: 'Smoking Cessation', value: 'Smoking Cessation' },
    { label: 'Spirituality', value: 'Spirituality' },
    {
        label: 'Stepfamily Adjustment',
        value: 'Stepfamily Adjustment'
    },
    {
        label: 'Stress Management Transgender Identity and Adaptation',
        value: 'Stress Management Transgender Identity and Adaptation'
    },
    { label: 'Trauma Counselling', value: 'Trauma Counselling' },
    { label: 'Weight Loss', value: 'Weight Loss' },
    { label: 'Womens Issues', value: 'Womens Issues' }
]