import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';

import Grid from '@material-ui/core/Grid';
// import { useTranslation } from 'react-i18next';

import './dialogStyles.scss';


export default function CareTeamDialog({ show, handleClose, careTeamArr, fullName }) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  // const { t } = useTranslation(['common', 'form', 'provider']);

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='invite-client-dialog-title'
      >
        <div>
          <DialogTitle className='Stepper__title' id='invite-client-dialog-title'>
            <span style={{ fontSize: '2rem' }}>{`Viewing Care Team For: ${fullName}`}</span>
          </DialogTitle>
        </div>

        <DialogContent>

          {careTeamArr.map((member) => (
            <div className='CareTeamDialog' key={member}>

              <Avatar aria-label='user-avatar' className='CareTeamDialog__avatar'>
                {'CM'}
              </Avatar>
              <h2>{member}</h2>

              <p>Personal Support Worker</p>
              <span>TEAM MEMBER</span>
            </div>
          ))}

          <Grid container justifyContent='flex-end'>
            <Button
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                padding: '.6rem 1.5rem',
                color: '#fff',
                backgroundColor: 'rgb(26, 80, 161)'
              }}
              id='btn-back'
              onClick={handleClose}
              variant='text'
              color='inherit'
              aria-label='Close'
            >
              {'Close'}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

CareTeamDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  careTeamArr: PropTypes.array.isRequired,
  fullName: PropTypes.string.isRequired
};
