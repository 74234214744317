import { awsApiRequest } from '../utils/func';
import { unmaskPhoneNumber } from '../../utils/numberUtils';

export async function getProviderServiceTeamList(providerId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/providers/${providerId}/users`,
    });
  } catch (error) {
    return error;
  }
}

export async function addProviderServiceTeamUser(providerId, values) {
  try {
    const body = {
      email: values.email,
      contact_number: unmaskPhoneNumber(values.phoneNumber),
      first_name: values.firstName,
      last_name: values.lastName,
      user_type: values.jobPosition,
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/providers/${providerId}/users`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function removeProviderServiceTeamMember(providerId, userId) {
  try {
    return await awsApiRequest({
      method: 'DELETE',
      path: `/providers/${providerId}/users/${userId}`,
    });
  } catch (error) {
    return error;
  }
}
