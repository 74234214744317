import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

export default function TabPanel({ children, value, index }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>


      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        <Grid container>
          <React.Fragment>
            {value === index && children}
          </React.Fragment>
        </Grid>
      </Typography>

    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};
