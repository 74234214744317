import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../common/tab-panel/tabPanel';
import ServicesTab from '../services-tab';
import CareTeamTab from './careteam-tab/CareTeamTab';

// import NeedsTab from '../needs-tab';
// import { BiDonateHeart } from 'react-icons/bi';

import { RiHeartPulseLine } from 'react-icons/ri';
import { AiOutlineTeam } from 'react-icons/ai';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { useMediaQuery } from '../../company-details/mediaQueries';

const iconStyles = {
  fontSize: '1.5rem',
  marginRight: '.5rem',
  marginTop: '.2rem'
}

export default function ProfileNavigationTabs({ userId }) {
  const isMobile = useMediaQuery('(max-width:900px)')

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation(['common']);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className={classes.root}>
      <div className={!isMobile ? classes.nav : classes.navMobile}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          {/*  <Tab
            icon={<BiDonateHeart style={iconStyles} />}
            className={classes.tabItem}
        label={t('common:lblMyNeeds')} /> */}

          <Tab
            icon={<RiHeartPulseLine style={iconStyles} />}
            className={classes.tabItem}
            label={t('common:lblMyServices')} />
          <Tab
            icon={<AiOutlineTeam style={iconStyles} />}
            className={classes.tabItem}
            label={t('common:lblMyCareTeam')} />
        </Tabs>
      </div>

      {/*  <TabPanel value={value} index={0}>
        <NeedsTab userId={userId} />
  </TabPanel> */}
      <TabPanel value={value} index={0}>
        <ServicesTab userId={userId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CareTeamTab userId={userId} />
      </TabPanel>
    </div>
  );
}

ProfileNavigationTabs.propTypes = {
  userId: PropTypes.number,
};
