export const divStyles = {
    height: '8rem',
    borderRadius: '5px',
    minWidth: '20rem',
    display: 'flex',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    margin: '1rem',
}

export const col1 = {
    width: '35%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const col2 = {
    width: '65%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
}

export const textStyles = {
    fontWeight: '500',
    marginTop: '-.8rem',
    fontSize: '1.1rem'
}
