import React from 'react'
import PropTypes from 'prop-types';
import DetailsForm from './generic/detailsForm';
import MentalHealthForm from './mentalHealth/mentalHealthForm';

/* || ============== NOTE ============= ||
|| ====================================== ||
As ServUs scales in size, different types of services will require additional, 
or different fields for their respective form. 

Services with the categories: Mental Health/ Counselling may need a 
'Years in Practice' field, or an 'Areas of Practice' field, whereas other services may not.

This component will check the current SECONDARY SERVICE CATEGORY as previously selected by 
the user and return the appropriate details form.

In addition: detail-form has been renamed to "details-forms", and will hold multiple folders
and files for different detail forms as they are created.
|| ====================================== ||
|| ========================================== */

const DetailsFormChecker = ({ handleNext, handleBack, serviceType }) => {

  if (serviceType === 'Counselling') {
    return (
      <MentalHealthForm
        handleNext={handleNext}
        handleBack={handleBack}
      />
    )
  } else if (serviceType === 'Tax Clinics') {
    return (
      <div>
        <h3>DETAILS FOR TAX CLINICS.</h3>
      </div>
    )
  } else {
    return (
      <DetailsForm
        handleNext={handleNext}
        handleBack={handleBack}
      />
    )
  }
}

DetailsFormChecker.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired
};


export default DetailsFormChecker;
