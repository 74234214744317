import React, { useEffect, useState, useMemo } from 'react';

import { getProviderLocationList } from '../../../api/provider-location';
import { getProviderDetails } from '../../../api/provider';
import { getEnvVariable } from '../../../utils/environmentVariables';

// import SuspenseView from '../../../components/suspense-view';
import haversine from 'haversine';
import { useLoadScript } from '@react-google-maps/api';

import SearchFilters from './searchFilters/searchFilters';
import ServiceCard from './serviceCard/serviceCard';
import GoogleMap from './map/map';
import CustomSpinner from '../../suspense-view/customSpinner';

import './serviceDiscoveryPage.scss';

export default function ServiceDiscoveryPage() {

  const [libraries] = useState(['places']);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: getEnvVariable('REACT_APP_API_MAP_KEY'),
    libraries,
  })

  //=== CONTROLS DISTANCE IN KM THE USERS WANTS TO SEARCH BY ====//
  // Will always default to 20 on page load even if changed. Maybe user model could hold a user_distance_search value? 
  const [userDistance, setUserDistance] = useState(20)


  //====== GETTING AND SETTING USERS CURRENT LOCATION====================//
  // Users might want the option to add a specific location as well, and not use their current location
  const [userLocation, setUserLocation] = useState({
    lat: null,
    lng: null,
  })

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  const showPosition = async (position) => {
    setUserLocation({
      ...userLocation,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    })
  }


  //==== FETCHING PROVIDER ON PAGE AND LOCATION ON PAGE LOAD ==========//
  // (POSSIBLILITY TO LOOK INTO FETCHING MORE PROVIDERS, WITH OBJECT CONTAINING LOCATIONS AND ITS BOUND SERVICE?) //
  const [provider, setProvider] = useState(null)

  const fetchProvider = async () => {
    const res = await getProviderDetails(1)
    return res.data
  }

  const fetchProviderLocations = async () => {
    const res = await getProviderLocationList(1)
    return res.data
  }

  useEffect(async () => {
    getUserLocation()
    setProvider({
      provider: await fetchProvider(),
      locations: await fetchProviderLocations()
    })
  }, [])


  //====== CALCULATES DISTANCE BETWEEN TWO OBJS AND RETURNS AN INT IN KM VALUE =====//
  const getDistance = (location) => {
    const distance = haversine(
      { latitude: userLocation.lat, longitude: userLocation.lng },
      { latitude: location.latitude, longitude: location.longitude },
      { unit: 'km' }
    )
    return Math.round(distance)
  }

  //===== FILTERING WHICH LOCATIONS ARE SHOWN BASED OFF WHAT IS WITHIN USER DISTANCE KM RADIUS SETTING ====//
  // Need to look into how to return services to their bound locations so that the services obj can be plugged in here //
  const filteredProvidersByDistance = useMemo(() => {
    if (provider !== null) {
      return provider.locations.filter((location) => getDistance(location) <= userDistance)
    }
  })

  //======= ON USER CLICK REVEALS INFO WINDOW AND CUSTOM STYLING FOR CARD CLICKED =======//
  const [active, setActive] = useState(null)


  const handleActive = (location) => {

    setActive(location)

  }

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return <CustomSpinner height={90} width={100} />;



  return (
    <div className='ServiceDiscoveryPage'>
      <SearchFilters
        userDistance={userDistance}
        setUserDistance={setUserDistance}
        setActive={setActive}
        filteredProvidersByDistance={filteredProvidersByDistance}
        active={active}
      />

      {userLocation.lat === null && userLocation.lng === null ? (
        <CustomSpinner height={50} width={100} />
      ) : (
        <div className='ServiceDiscoveryPage__container'>
          <div className='ServiceDiscoveryPage__container__cards'>
            {provider !== null ? (
              filteredProvidersByDistance.map((location, idx) => (
                <ServiceCard
                  key={location.id}
                  location={location}
                  provider={provider}
                  active={active}
                  handleActive={handleActive}
                  idx={idx}
                />
              ))
            ) : null}
          </div>

          <GoogleMap
            active={active}
            provider={provider}
            filteredProvidersByDistance={filteredProvidersByDistance}
            userLocation={userLocation}
            handleActive={handleActive}
            userDistance={userDistance}
            setActive={setActive}
          />
        </div>
      )}
    </div>
  );
}
