import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsGlobe } from 'react-icons/bs';

import { LANGUAGE_NAMES } from './constants';

import { useTranslation } from 'react-i18next';
import { changeLanguage, DEFAULT_LNG } from '../../../i18n/i18n';
import { useStyles } from './styles';

const menuItemStyles = {
  fontSize: '1.1rem',
  color: 'black',
  opacity: '.7',
  fontFamily: 'Poppins, sans-serif',
  marginLeft: '.5rem',
  marginRight: '.5rem',
};

export default function LanguageSelector() {
  const [languageMenu, setLanguageMenu] = React.useState(null);
  const [userLanguage, setUserLanguage] = React.useState(DEFAULT_LNG);

  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleMenu = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleClose = () => {
    setLanguageMenu(null);
  };

  return (
    <>
      <Tooltip title={t('common:lblChangeLanguage')} enterDelay={300}>
        <Button
          color='inherit'
          aria-owns={languageMenu ? 'language-menu' : undefined}
          aria-haspopup='true'
          aria-label={t('common:lblChangeLanguage')}
          onClick={handleMenu}
          data-ga-event-category='header'
          data-ga-event-action='language'
        >
          <BsGlobe style={{ fontSize: '1.5rem' }} />
          <span className={classes.language}>
            {
              LANGUAGE_NAMES.filter(
                (language) => language.code === userLanguage
              )[0].label
            }
          </span>
          <ExpandMoreIcon id='expand-icon' fontSize='small' />
        </Button>
      </Tooltip>
      <Menu
        id='language-menu'
        elevation={0}
        anchorEl={languageMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(languageMenu)}
        onClose={handleClose}
      >
        {LANGUAGE_NAMES.map((language) => (
          <MenuItem
            style={menuItemStyles}
            component='a'
            data-no-link='true'
            key={language.code}
            selected={userLanguage === language.code}
            onClick={() => {
              setUserLanguage(language.code);
              changeLanguage(language.code);
              handleClose();
            }}
          >
            {language.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
