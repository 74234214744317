import { makeStyles } from '@material-ui/core/styles';

const PoppinsFont = 'Poppins, sans-serif'
const mainBlueColor = 'rgb(26, 80, 161)'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '22rem',
    marginTop: '-5rem'
  },
  companyInfoText: {
    margin: theme.spacing(0, 0.5, 0, 2),
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const typgraphyStylesName = {
  fontFamily: PoppinsFont,
  fontSize: '2rem',
  fontWeight: '600',
  color: mainBlueColor
}

export const typographyStylesDesc = {
  fontSize: '1.3rem',
  fontFamily: PoppinsFont
}

export const imgStyles = {
  height: '10rem',
  width: '10rem',
  objectFit: 'cover',
  borderRadius: '50%',
  border: '3px solid white',
  backgroundColor: 'white'
}

export const fontFamilyPoppins = {
  fontFamily: PoppinsFont
}

export const colorBlue = {
  color: mainBlueColor,
  fontSize: '1.5rem'
}

export default useStyles;
