import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import SuspenseView from '../../suspense-view';
import ClientListView from './client-list-view';

import AddNewClientDialog from '../../dialogs/add-new-client';
// import InviteClientDialog from '../../dialogs/invite-client';

import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import { getProviderClientList } from '../../../api/provider-client';
import { getProviderId } from '../../../utils/userUtils';

import { getProviderServiceList } from '../../../api/provider-services';

import { useTranslation } from 'react-i18next';
import ClientDetailsCard from './clientDetailsCard/ClientDetailsCard';
import './providerClientsPage.scss';

//icons for card props
import { AiOutlineUser, AiOutlineArrowUp, AiOutlineSearch } from 'react-icons/ai'

const useFetch = (providerId, lastUpdated) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderClientList(
        providerId
      );

      if (success) {
        setData(data);
      } else {
        setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId, lastUpdated]);

  return { data, error };
};



export default function ProviderClientsPage() {
  const [providerServices, setProviderServices] = useState(null);


  const { userDetails } = useAuthDataContext();
  const providerId = getProviderId(userDetails);

  useEffect(async () => {
    const serviceList = await getProviderServiceList(providerId)
    setProviderServices(serviceList.data)

  }, [])


  // const classes = useStyles();
  const { t, ready } = useTranslation(['common', 'provider']);
  const [searchText, setSearchText] = useState('')



  // State value for tracking the list refresh last updated time
  const [clientListState, setClientListState] = useState({
    lastUpdated: moment(),
  });

  const setLastUpdated = () => {
    setClientListState((clientListState) => ({
      ...clientListState,
      lastUpdated: moment(),
    }));
  };

  // ADD Dialog Methods
  const [showInviteClientDialog, setShowInviteClientDialog] = useState(false);
  const openInviteClientDialog = () => {
    setShowInviteClientDialog(true);
  };

  const closeInviteClientDialog = () => {
    setShowInviteClientDialog(false);
  };

  // Retrieve the user list data
  const response = useFetch(providerId, clientListState.lastUpdated);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const responseData = response.data;

  console.log('CLIENTS DATA ==>', responseData)

  let numberOfClients = _.get(responseData, 'length', 0);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className='ProviderClientsPage'>

      <div className='ProviderClientsPage__client-details-container'>
        <ClientDetailsCard
          icon={<AiOutlineUser />}
          text={t('provider:lblNumClients')}
          number={numberOfClients}
          primColor={'rgb(59, 161, 65)'}
          iconBg={'rgb(204, 255, 204, .4)'}
        />
        <ClientDetailsCard
          icon={<AiOutlineUser />}
          text={t('provider:lblClientsInactive')}
          number={numberOfClients}
          primColor={'rgb(255, 187, 60)'}
          iconBg={'rgb(255, 235, 197, .4)'}
        />
        <ClientDetailsCard
          icon={<AiOutlineArrowUp />}
          text={t('provider:lblAddedThisMonth')}
          number={10}
          primColor={'rgb(91, 205, 250)'}
          iconBg={'rgb(177, 233, 255, .3)'}
        />
        <ClientDetailsCard
          icon={<AiOutlineArrowUp />}
          text={t('provider:lblUsedOurServices')} x
          number={210}
          primColor={'rgb(255, 102, 128)'}
          iconBg={'rgb(255, 188, 199, .3)'}
        />
      </div>

      <div className='ProviderClientsPage__row'>
        <input
          type='text'
          placeholder={t('provider:phSearchClient')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <AiOutlineSearch className='ProviderClientsPage__row__icon' />
        <Tooltip title={t('provider:lblAddNewClient')} enterDelay={500}>
          <button
            className='ProviderClientsPage__row__add-new-btn'
            onClick={openInviteClientDialog}
          >
            &#65291;
          </button>
        </Tooltip>
      </div>

      <div className='ProviderClientsPage__labels'>
        <p>{t('provider:lblClientName')}</p>
        <p>{t('provider:lblServicesAssigned')}</p>
        <p>{t('provider:lblCareTeam')}</p>
        <p>{t('provider:lblDateAdded')}</p>
        <p>{t('provider:lblContactInfo')}</p>
        <p>Dots</p> {/* using as invisible filler for margins */}

      </div>

      {/*  <div hidden>
        <InviteClientDialog
          show={showInviteClientDialog}
          handleClose={closeInviteClientDialog}
          setLastUpdated={setLastUpdated}
          providerId={providerId}
        />
    </div> */}
      <div hidden>
        <AddNewClientDialog
          show={showInviteClientDialog}
          handleClose={closeInviteClientDialog}
          setLastUpdated={setLastUpdated}
          providerId={providerId}
        />
      </div>
      <br />
      <ClientListView
        providerServices={providerServices}
        searchText={searchText}
        clients={responseData.sort((a, b) => a.first_name.localeCompare(b.first_name))}
        setLastUpdated={setLastUpdated} />
    </div>
  );
}
