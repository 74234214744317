import { awsApiRequest } from '../utils/func';

export async function getUserNeedsList(userId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/users/${userId}/needs`,
    });
  } catch (error) {
    return error;
  }
}

export async function addNewUserNeed(userId, values) {
  try {
    const body = {
      name: values.name,
      details: values.summary,
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/users/${userId}/needs`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function removeUserNeed(userId, userNeedId) {
  try {
    return await awsApiRequest({
      method: 'DELETE',
      path: `/users/${userId}/needs/${userNeedId}`,
    });
  } catch (error) {
    return error;
  }
}
