import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  VALUE_ROLE_NAME_ADMIN,
  //VALUE_ROLE_NAME_STAFF,
  VALUE_ROLE_NAME_SUPPORT,
  //VALUE_ROLE_NAME_VOLUNTEER,
  VALUE_SELECT,
} from '../../constants';

export default function JobPositionList() {
  const { t } = useTranslation(['common', 'serviceTeam']);

  const jobPositions = [
    {
      value: VALUE_SELECT,
      label: t('common:lblSelect'),
    },
    {
      value: VALUE_ROLE_NAME_ADMIN,
      label: t('serviceTeam:lblRoleNameAdmin'),
    },
    // {
    //   value: VALUE_ROLE_NAME_STAFF,
    //   label: t('serviceTeam:lblRoleNameStaff'),
    // },
    {
      value: VALUE_ROLE_NAME_SUPPORT,
      label: t('serviceTeam:lblRoleNameSupportWorker'),
    },
    // {
    //   value: VALUE_ROLE_NAME_VOLUNTEER,
    //   label: t('serviceTeam:lblRoleNameVolunteer'),
    // },
  ];

  return (
    <>
      {jobPositions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </>
  );
}
