import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';

import { RiHandHeartLine } from "react-icons/ri";
import { AiOutlineTeam } from "react-icons/ai";
import { TiContacts } from "react-icons/ti";

import ViewServiceNeedsDialog from '../dialogs/viewServiceNeedsDialog';
import CareTeamDialog from '../dialogs/careTeamDialog';
import ContactInfoDialog from '../dialogs/contactInfoDialog';
import MoreOptionsDialog from '../dialogs/moreOptionsDialog';

import '../../provider-clients/providerClientsPage.scss';

import Tooltip from '@material-ui/core/Tooltip';
import SuspenseView from '../../../suspense-view';
import { useTranslation } from 'react-i18next';
import { getUserNeedsList } from '../../../../api/user-need';
// import { getProviderServiceList } from '../../../../api/provider-services';

const iconStyles = {
  fontSize: '1.6rem',
  color: 'rgb(56, 172, 201)'
}

export default function ClientListItemView({ details, setLastUpdated, providerServices }) {
  const { t, ready } = useTranslation(['common', 'user', 'provider']);
  const [openNeeds, setOpenNeeds] = useState(false);

  const openNeedsModal = () => {
    setOpenNeeds(true)
  }

  const closeNeedsModal = () => {
    setOpenNeeds(false)
  }

  const [openCareTeam, setOpenCareTeam] = useState(false);

  const openCareTeamModal = () => {
    setOpenCareTeam(true)
  }

  const closeCareTeamModal = () => {
    setOpenCareTeam(false)
  }

  const [openContactInfo, setOpenContactInfo] = useState(false);

  const openContactInfoModal = () => {
    setOpenContactInfo(true)
  }

  const closeContactInfoModal = () => {
    setOpenContactInfo(false)
  }

  const [userServices, setUserServices] = useState(null);

  useEffect(async () => {
    const userServicesList = await getUserNeedsList(details.user_id)
    setUserServices(userServicesList.data)
  }, [])

  // Set the local attributes
  let userId = details.user_id;
  let providerId = details.provider_id;
  let firstName = details.first_name[0].toUpperCase() + details.first_name.substring(1);
  let lastName = details.last_name[0].toUpperCase() + details.last_name.substring(1);
  let avatarInitials = firstName.charAt(0) + lastName.charAt(0);
  let fullName = firstName + ' ' + lastName;

  if (!ready) {
    return <SuspenseView />;
  }

  //dummy data for UI //

  const careTeamArr = [
    'Care Team Member 1',
    'Care Team Member 2',
    'Care Team Member 3',
  ]

  // const [providerServices, setProviderServices] = useState(null)
  // const [userServices, setUserServices] = useState(null)

  // useEffect(async () => {
  //   const serviceList = await getProviderServiceList(providerId)
  //   setProviderServices(serviceList.data)

  //   const userServicesList = await getUserNeedsList(userId)
  //   setUserServices(userServicesList.data)
  // }, [])


  let tempDate = moment(new Date).format('D MMM YYYY')

  return (
    <div>
      <div className='ClientListView'>

        <div className='ClientListView__row'>
          <Avatar aria-label='user-avatar' className='ClientListView__avatar'>
            {avatarInitials}
          </Avatar>
          <h5>{fullName}</h5>
        </div>

        <div className='ClientListView__row2'>
          <div className='ClientListView__row2__needs'>
            <p className='ClientListView__row2__needs__text'>{t('provider:lblServicesAssigned')}:</p>
            <p>{userServices?.length}</p>
            <Tooltip title={'View Services'} enterDelay={300}>
              <span onClick={openNeedsModal}><RiHandHeartLine style={iconStyles} /></span>
            </Tooltip>
          </div>

          <div className='ClientListView__row2__needs'>
            <p className='ClientListView__row2__needs__text'>{t('provider:lblCareTeam')}:</p>
            <p>{careTeamArr.length}</p>
            <Tooltip title={'View Care Team'} enterDelay={300}>
              <span onClick={openCareTeamModal}><AiOutlineTeam style={iconStyles} /></span>
            </Tooltip>
          </div>

          <div>
            <span className='ClientListView__row2__needs__text'>{t('provider:lblDateAdded')}:</span>
            <p>{tempDate}</p>
          </div>

          <div className='ClientListView__row2__needs'>
            <Tooltip title={'View Contact Info.'} enterDelay={300}>
              <span onClick={openContactInfoModal}><TiContacts style={iconStyles} /></span>
            </Tooltip>
          </div>
        </div>

        <div style={{ marginRight: '1rem' }}>
          <MoreOptionsDialog providerId={providerId} userId={userId} setLastUpdated={setLastUpdated} />
        </div>

      </div>

      <div hidden>
        <ViewServiceNeedsDialog
          show={openNeeds}
          handleClose={closeNeedsModal}
          fullName={fullName}
          providerId={providerId}
          userId={userId}
          providerServices={providerServices}
          userServices={userServices}
          setUserServices={setUserServices}
        />
      </div>

      <div hidden>
        <CareTeamDialog
          show={openCareTeam}
          handleClose={closeCareTeamModal}
          careTeamArr={careTeamArr}
          fullName={fullName}
        />
      </div>

      <div hidden>
        <ContactInfoDialog
          show={openContactInfo}
          handleClose={closeContactInfoModal}
          fullName={fullName}
          userDetails={details}
        />
      </div>
    </div>
  );
}

ClientListItemView.propTypes = {
  details: PropTypes.object.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  providerServices: PropTypes.array,
  userServices: PropTypes.object,
  setUserServices: PropTypes.func
};
