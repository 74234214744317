import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiOutlineMail, AiFillPhone, AiOutlineVideoCamera } from 'react-icons/ai';


import Grid from '@material-ui/core/Grid';
// import { useTranslation } from 'react-i18next';

import './dialogStyles.scss';


export default function ContactInfoDialog({ show, handleClose, fullName, userDetails }) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  // const { t } = useTranslation(['common', 'form', 'provider']);

  const formattedPhoneNumber =
    '(' + userDetails.contact_number.slice(0, 3) + ") " +
    userDetails.contact_number.slice(3, 6) + '-' +
    userDetails.contact_number.slice(6);

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='invite-client-dialog-title'
      >
        <div>
          <DialogTitle className='Stepper__title' id='invite-client-dialog-title'>
            <span style={{ fontSize: '2rem' }}>{`Viewing Contact Info For: ${fullName}`}</span>
          </DialogTitle>
        </div>

        <DialogContent>


          <div className='ContactInfoDialog'>
            <div className='ContactInfoDialog__row'>
              <span><AiOutlineMail /></span>
              <h2>{userDetails.email}</h2>
            </div>
            <div className='ContactInfoDialog__row'>
              <span><AiFillPhone /></span>
              <h2>{formattedPhoneNumber}</h2>
            </div>
            <div className='ContactInfoDialog__row'>
              <span><AiOutlineVideoCamera /></span>
              <h2>Some video info</h2>
            </div>


          </div>


          <Grid container justifyContent='flex-end'>
            <Button
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                padding: '.6rem 1.5rem',
                color: '#fff',
                backgroundColor: 'rgb(26, 80, 161)'
              }}
              id='btn-back'
              onClick={handleClose}
              variant='text'
              color='inherit'
              aria-label='Close'
            >
              {'Close'}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ContactInfoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired
};
