import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ClientServiceNeedsOption = ({ option, values, setValues }) => {
  const [selected, setSelected] = useState(false)

  const handleOption = (option) => {
    setSelected(!selected)
    if (values.needs.includes(option)) {
      setValues({
        ...values,
        needs: values.needs.filter((need) => need !== option)
      })
    } else {
      setValues({
        ...values,
        needs: [...values.needs, option]
      })
    }
  }

  return (
    <div
      onClick={() => handleOption(option)}
      className={values.needs.includes(option) ?
        'ClientOnBoarding__options__option selected' :
        'ClientOnBoarding__options__option'} key={option}>
      <p>{values.needs.includes(option) ? <span>&#10003;</span> : null}{option}</p>
    </div>
  )
}

ClientServiceNeedsOption.propTypes = {
  option: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired
};

export default ClientServiceNeedsOption;