import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import './Toast.css';

//modified from https://github.com/uzochukwueddie/react-toast
export default function Toast({
  toastList,
  position,
  autoDelete,
  dismissTime,
}) {
  const [list, setList] = useState(toastList);
  const [toastDismiss, setToastDismiss] = useState([]);

  // the useCallback is used so as to
  // prevent unncessary renders
  const deleteToast = useCallback(
    (id) => {
      const index = list.findIndex((e) => e.id === id);
      list.splice(index, 1);
      setList([...list]);

      toastDismiss.length = 0; // after deleting a toast, clear the contents of the array
      setToastDismiss([...toastDismiss]); // update the toastDismiss array with the empty array
    },
    [list, toastDismiss]
  );

  useEffect(() => {
    setList(toastList);

    setTimeout(() => {
      setToastDismiss(toastList);
    }, dismissTime);

    const toast = toastDismiss.reverse();
    if (autoDelete && toast.length) {
      setTimeout(() => {
        deleteToast(toast);
      }, dismissTime);
    }
  }, [toastList, list, toastDismiss]);

  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={`notification toast ${position}`}
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <button onClick={() => deleteToast(toast.id)}>X</button>
            <div className='notification-image'>
              <img src={toast.icon} alt='' />
            </div>
            <div>
              <p className='notification-title'>{toast.title}</p>
              <p className='notification-message'>{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

Toast.defaultProps = {
  position: 'bottom-right',
  autoDelete: true,
  dismissTime: 3000,
};
