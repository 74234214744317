import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  servicesLabel: {
    margin: theme.spacing(0, 0.5, 0, 2),
    float: 'left',
  },
}));

export default useStyles;
