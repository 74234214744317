import { awsApiRequest } from '../utils/func';

export async function getProviderLocationList(providerId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/providers/${providerId}/locations`,
    });
  } catch (error) {
    return error;
  }
}

export async function addLocationForProvider(providerId, values) {
  try {
    const body = {
      address_details: {
        name: values.branch,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        province: values.province,
        postal_code: values.postalCode,
        country: values.country,
        latitude: values.latitude,
        longitude: values.longitude,
      },
      service_ids: values.serviceIds,
      provider_user_ids: values.teamMemberIds,
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/providers/${providerId}/locations`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function removeLocation(providerId, locationId) {
  try {
    return await awsApiRequest({
      method: 'DELETE',
      path: `/providers/${providerId}/locations/${locationId}`,
    });
  } catch (error) {
    return error;
  }
}

export async function editProviderLocation(providerId, locationId, values) {
  try {
    const body = {
      address1: values.address1,
      address2: values.address2,
      postal_code: values.postal_code,
      city: values.city,
      province: values.province,
      name: values.name,
    };

    return await awsApiRequest({
      method: 'PUT',
      path: `/providers/${providerId}/locations/${locationId}`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}
