import { awsApiRequest } from '../utils/func';
import { unmaskPhoneNumber } from '../../utils/numberUtils';

export async function getUserDetailsBySub(userSub) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/users/sub/${userSub}`,
    });
  } catch (error) {
    return error;
  }
}

export async function updateUserProfileDetails(userId, values) {
  try {
    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      contact_email: values.contact_email,
      contact_number: unmaskPhoneNumber(values.contact_number),
    };

    return await awsApiRequest({
      method: 'PUT',
      path: `/users/${userId}`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}
