import { awsApiRequest } from '../utils/func';
import { unmaskPhoneNumber } from '../../utils/numberUtils';

export async function getProviderClientList(providerId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/providers/${providerId}/clients`,
    });
  } catch (error) {
    return error;
  }
}

export async function inviteClient(providerId, values) {
  try {
    const body = {
      email: values.email,
      location_id: values.locationId,
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/providers/${providerId}/invite-client`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function addNewClient(providerId, values) {
  try {
    const body = {
      email: values.email,
      contact_number: unmaskPhoneNumber(values.phoneNumber),
      first_name: values.firstName,
      last_name: values.lastName,
      location_id: values.locationId,
    };

    return await awsApiRequest({
      method: 'POST',
      path: `/providers/${providerId}/add-client`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function removeClientFromProvider(providerId, userId) {
  try {
    return await awsApiRequest({
      method: 'DELETE',
      path: `/providers/${providerId}/clients/${userId}`,
    });
  } catch (error) {
    return error;
  }
}
