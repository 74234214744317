import checkIcon from '../assets/check.svg';
import errorIcon from '../assets/error.svg';

// Functions for working with the snackbars
export const setHandleSuccessProperties = (title, description) => {
  return {
    title: title,
    description: description,
    backgroundColor: '#5cb85c',
    icon: checkIcon,
  };
};

export const setHandleErrorProperties = (title, description) => {
  return {
    title: title,
    description: description,
    backgroundColor: '#d9534f',
    icon: errorIcon,
  };
};
