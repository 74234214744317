import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import DialogTitle from '../common/dialog-title';
import AddressForm from './address-form/addressForm';
import MapForm from './map-form/mapForm';
import ServicesForm from './services-form/servicesForm';
import TeamMembersForm from './team-members-form/teamMembersForm';
import SummaryForm from './summary-form/summaryForm';

import SuspenseView from '../../suspense-view';
import { LocationContext } from './locationContext';


import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { DialogContent } from '@material-ui/core';

function getSteps(t) {
  return [
    t('location:lblAddress'),
    t('location:lblMap'),
    t('location:lblServices'),
    t('Team'),
    t('location:lblSummary'),
  ];
}

export default function AddLocationForm({
  show,
  handleClose,
  providerId,
  setLastUpdated,
  success,
  error,
  isLoaded,
  loadError
}) {
  const { t, ready } = useTranslation(['location', 'common']);
  const classes = useStyles();




  const [state, setState] = useState({
    // Initialize the current active step
    providerId: providerId,

    // Initialize the data from each step
    address: {},
    services: [],
    teamMembers: [],
    healthZonesServed: []
  });

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps(t);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  };

  const handleReset = () => {
    setActiveStep(0)
    setState({
      // Initialize the current active step
      providerId: providerId,

      // Initialize the data from each step
      address: null,
      services: [],
      teamMembers: [],
      healthZonesServed: []
    });
  };

  const onClose = () => {
    handleReset();
    handleClose();
  };

  const handleSubmit = () => {
    setLastUpdated();
    handleReset();
    handleClose();
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <>
      <Dialog
        open={show}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby='location-wizard-title'
        className={classes.root}
      >
        <DialogTitle className='Stepper__title' id='location-wizard-title' onClose={onClose}>
          {t('location:lblAddNewLocation')}
        </DialogTitle>
        <DialogContent>
          <Stepper
            style={{ borderRadius: '25px' }}
            alternativeLabel activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <LocationContext.Provider value={{ state, setState }}>
            {activeStep === 0 && (
              <AddressForm
                handleNext={handleNext}
                isLoaded={isLoaded}
                loadError={loadError} />
            )}
            {activeStep === 1 && (
              <MapForm
                isLoaded={isLoaded}
                loadError={loadError}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            )}
            {activeStep === 2 && (
              <ServicesForm
                handleNext={handleNext}
                handleBack={handleBack}
              />
            )}
            {activeStep === 3 && (
              <TeamMembersForm
                handleNext={handleNext}
                handleBack={handleBack}
              />
            )}
            {activeStep === 4 && (
              <SummaryForm
                handleBack={handleBack}
                handleSubmit={handleSubmit}
                success={success}
                error={error}
              />
            )}
          </LocationContext.Provider>
        </DialogContent>
      </Dialog>
    </>
  );
}

AddLocationForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  providerId: PropTypes.number.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
  loadError: PropTypes.object
};
