import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 25,
  },
  textField: {
    width: '100%',
  },
  error: {
    color: theme.palette.secondary.dark,
  },
}));

export default useStyles;
