import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddServiceDialog from './addServiceDialog';
import ServUsLogo from './assets/ServUsIconWHITE.png';
import { RiHandHeartFill } from 'react-icons/ri';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { buttonStyles, dialogContentStyles } from '../../../dialogs/customStyles/customStyles';
import { removeUserNeed } from '../../../../api/user-need';

import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import './dialogStyles.scss';

const textStyles = {
  marginLeft: '2.5rem',
  marginTop: '-1rem',
  fontSize: '1.4rem',
  color: 'gray',
  fontWeight: '600'
}


export default function ViewServiceNeedsDialog({ show, handleClose, userServices, setUserServices, fullName, providerServices, userId }) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const { t } = useTranslation('location', 'common', 'error');

  const [selectedNeed, setSelectedNeed] = useState(null)
  const [openAddService, setOpenAddService] = useState(false);

  const openAddServiceModal = () => {
    setOpenAddService(true)

  }

  const closeAddServiceModal = () => {
    setOpenAddService(false)
  }

  const [openDelete, setOpenDelete] = useState(false);

  const openConfirmDeleteModel = (need) => {
    setSelectedNeed(need)
    setOpenDelete(true);
  };

  const closeConfirmDeleteModel = () => {
    setOpenDelete(false);
    setSelectedNeed(null)
  };

  const handleRemoveServiceFromUser = async () => {
    await removeUserNeed(selectedNeed.user_id, selectedNeed.id)
    setUserServices(userServices.filter((service) => service !== selectedNeed))
    setSelectedNeed(null)
    setOpenDelete(false);
  }


  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='invite-client-dialog-title'
      >
        <div>
          <DialogTitle className='Stepper__title' id='invite-client-dialog-title'>
            <span style={{ fontSize: '2rem' }}> {`Viewing Services For: ${fullName}`}</span>
          </DialogTitle>
          <p style={textStyles}>* {fullName} currently has {userServices?.length} service(s) assigned</p>
        </div>

        <DialogContent>

          {userServices && userServices.length > 0 ? (
            userServices.map((need) => (
              <div className='ServiceNeedDialogText' key={need.id}>
                <p onClick={() => openConfirmDeleteModel(need)}>X</p>
                <div className='ServiceNeedDialogText__col-1'>
                  <img src={ServUsLogo} alt={ServUsLogo} />
                </div>
                <div className='ServiceNeedDialogText__col-2'>
                  <div className='ServiceNeedDialogText__col-2__row'>
                    <span><RiHandHeartFill /></span>
                    <h3>{need.name}</h3>
                  </div>
                </div>

              </div>
            ))
          ) : <p style={{ margin: '4rem' }}></p>}

          <div style={{ display: 'flex', marginLeft: '1rem', alignItems: 'center' }}>
            <BsFillPlusSquareFill
              id='hover'
              onClick={openAddServiceModal}
              style={{ color: 'rgb(26, 80, 161)', fontSize: '2.2rem', marginLeft: '.5rem' }}
            />
            <p style={{ fontSize: '1.5rem', fontWeight: '600', marginLeft: '1rem', color: 'rgb(26, 80, 161)' }}>Add New Service</p>
          </div>

          <Grid container justifyContent='flex-end'>
            <Button
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                padding: '.6rem 1.5rem',
                color: '#fff',
                backgroundColor: 'rgb(26, 80, 161)'
              }}
              id='btn-back'
              onClick={handleClose}
              variant='text'
              color='inherit'
              aria-label='Close'
            >
              {'Close'}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <div hidden>
        <AddServiceDialog
          fullName={fullName}
          show={openAddService}
          handleClose={closeAddServiceModal}
          providerServices={providerServices}
          userServices={userServices}
          setUserServices={setUserServices}
          userId={userId}
        />
      </div>
      <Dialog
        open={openDelete}
        onClose={closeConfirmDeleteModel}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent style={dialogContentStyles}>
          Are you sure you want to remove {selectedNeed?.name} ?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRemoveServiceFromUser}
            style={buttonStyles}
          >
            {t('common:yes')}
          </Button>
          <Button style={buttonStyles} onClick={closeConfirmDeleteModel} autoFocus>
            {t('common:no')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ViewServiceNeedsDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userServices: PropTypes.array,
  setUserServices: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  providerServices: PropTypes.array,
  userId: PropTypes.number.isRequired,
};
