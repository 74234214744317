import React, { useState } from 'react'
import PropTypes from 'prop-types';
import CareTeamTabListView from '../careteam-tab-listview/careTeamTabListView'

import { divStyles, inputStyles, typographyStyles, divStylesMedia } from './styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../company-details/mediaQueries';
import Typography from '@material-ui/core/Typography';
import { AiOutlineSearch } from 'react-icons/ai';


const CareTeamTab = () => {
  const isMedia = useMediaQuery("(max-width: 900px)");
  const { t } = useTranslation(['common', 'serviceTeam', 'error']);

  const [searchText, setSearchText] = useState('')

  const dummyCareTeam = [
    {
      id: 1,
      name: 'Care Team Member1',
      userType: 'Personal Support Worker',
      teamRole: 'Team Admin',
      contactNumber: '123-333-4444',
      email: 'provider@email.com',
      company: 'Some Company'
    },
    {
      id: 2,
      name: 'Care Team Member2',
      userType: 'Personal Support Worker',
      teamRole: 'Team Admin',
      contactNumber: '123-333-4444',
      email: 'provider@email.com',
      company: 'Some Company'
    },
    {
      id: 3,
      name: 'Care Team Member3',
      userType: 'Personal Support Worker',
      teamRole: 'Team Member',
      contactNumber: '123-333-4444',
      email: 'provider@email.com',
      company: 'Some Company'
    },
    {
      id: 4,
      name: 'Care Team Member4',
      userType: 'Personal Support Worker',
      teamRole: 'Team Member',
      contactNumber: '123-333-4444',
      email: 'provider@email.com',
      company: 'Some Company'
    },
    {
      id: 5,
      name: 'Care Team Member5',
      userType: 'Personal Support Worker',
      teamRole: 'Team Member',
      contactNumber: '123-333-4444',
      email: 'provider@email.com',
      company: 'Some Company'
    },
  ]

  return (
    <React.Fragment>

      <div style={isMedia ? divStylesMedia : divStyles}>
        <Typography
          style={typographyStyles}
          variant='h6'>
          {t('serviceTeam:lblMyCareTeam', {
            num: dummyCareTeam.length
          })}
        </Typography>

        <div>
          <input
            className='ServiceTeamTab__input'
            style={inputStyles}
            type='text'
            placeholder='Search Team Members'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <AiOutlineSearch
            style={{
              fontSize: '2rem',
              transform: 'translateY(6px) translateX(-28px)',
              color: 'rgb(26, 80, 161)',
              opacity: '.3'
            }} />
        </div>
      </div>

      <div className='ServiceTeamTab__container'>
        {dummyCareTeam
          .filter((entry) => {
            if (entry.name.toLowerCase().includes(searchText.toLowerCase())) {
              return (
                <CareTeamTabListView
                  key={entry.id}
                  details={entry}
                />
              );
            } else if (searchText === '') {
              return (
                <CareTeamTabListView
                  key={entry.id}
                  details={entry}
                />
              )
            }
          }).map((entry) => (
            <CareTeamTabListView
              key={entry.id}
              details={entry}
            />
          ))}
      </div>
    </React.Fragment>
  )
}

CareTeamTab.propTypes = {
  userId: PropTypes.number,
};


export default CareTeamTab
