import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PhoneNumberMask from '../../common/form/phone-number-mask';
import SuspenseView from '../../suspense-view';

import { addNewClient } from '../../../api/provider-client';
import { getProviderLocationList } from '../../../api/provider-location';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { fieldTitleStyles, moreMarginStyles, buttonStyles, dialogContentStyles } from '../customStyles/customStyles';

import { VALUE_SELECT } from '../../common/constants';

const useFetch = (providerId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderLocationList(
        providerId
      );
      if (success) {
        setData(data);
      } else {
        setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId]);

  return { data, error };
};

export default function AddNewClientDialog({
  providerId,
  show,
  handleClose,
  setLastUpdated,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const { t, ready } = useTranslation(['common', 'form', 'provider']);
  const classes = useStyles();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmCancelModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmCancelModel = () => {
    setOpenCancel(false);
  };

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    location: '',
  };

  const requiredTranslated = t('form:required');
  const InviteClientSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
    firstName: Yup.string().required(requiredTranslated),
    lastName: Yup.string().required(requiredTranslated),
    phoneNumber: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[ ]([0-9]{3})[-]([0-9]{4})$/,
        t('form:invalidPhoneNumber')
      )
      .required(t('form:invalidPhoneNumber')),
    location: Yup.string()
      .notOneOf([VALUE_SELECT], requiredTranslated)
      .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    if (values && !values.isCancelButton) {
      values.locationId = parseInt(values.location, 10);

      const result = await addNewClient(providerId, values);
      if (result.success) {
        setLastUpdated();
        handleClose();
      } else {
        let errorMessage;
        if (result.statusCode === 409) {
          errorMessage = t('provider:errorClientAlreadyAdded', {
            name: values.email,
          });
        } else {
          errorMessage = t(result.errorMessage);
        }

        actions.setStatus({ msg: errorMessage });
      }
    } else {
      if (values.isDirty) {
        openConfirmCancelModel();
      } else {
        handleClose();
      }
    }
  };

  // Retrieve the locations list data
  const response = useFetch(providerId);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const locations = response.data;

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='invite-client-dialog-title'
      >
        <DialogTitle className='Stepper__title' id='invite-client-dialog-title'>
          {t('provider:lblAddNewClient')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            validationSchema={InviteClientSchema}
          >
            {({
              errors,
              touched,
              values,
              actions,
              status,
              handleChange,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <h3 style={fieldTitleStyles}>{t('common:lblEmail')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  id='email'
                  name='email'
                  placeholder={t('common:lblEmail')}
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.email}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.email && touched.email ? (
                  <div className={classes.error}>{errors.email}</div>
                ) : null}

                <h3 style={fieldTitleStyles}>{t('common:lblFirstName')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='firstName'
                  id='firstName'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  placeholder={t('common:lblFirstName')}
                  value={'' || values.firstName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.firstName && touched.firstName ? (
                  <div className={classes.error}>{errors.firstName}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('common:lblLastName')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='lastName'
                  id='lastName'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  placeholder={t('common:lblLastName')}
                  value={'' || values.lastName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.lastName && touched.lastName ? (
                  <div className={classes.error}>{errors.lastName}</div>
                ) : null}

                <h3 style={fieldTitleStyles}>{t('common:lblPhoneNumber')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='phoneNumber'
                  id='phoneNumber'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  placeholder={t('common:lblPhoneNumber')}
                  value={'' || values.phoneNumber}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: PhoneNumberMask }}
                />

                {errors.phoneNumber && touched.phoneNumber ? (
                  <div className={classes.error}>{errors.phoneNumber}</div>
                ) : null}

                <h3 style={fieldTitleStyles}>{t('provider:lblLocation')}</h3>
                <Field
                  select
                  component={TextField}
                  name='location'
                  id='location'
                  variant='outlined'
                  className={classes.textField}
                  value={'' || values.location}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin='normal'
                >
                  <option key={VALUE_SELECT} value={VALUE_SELECT}>
                    {t('common:lblSelect')}
                  </option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </Field>
                {errors.location && touched.location ? (
                  <div className={classes.error}>{errors.location}</div>
                ) : null}

                {status && status.msg && (
                  <Typography className={classes.error}>
                    {status.msg}
                  </Typography>
                )}

                <Grid style={moreMarginStyles} container justifyContent='flex-end'>
                  <Button
                    id='btn-back'
                    variant='text'
                    color='inherit'
                    onClick={async () => {
                      values = {
                        isCancelButton: true,
                        isDirty: dirty,
                      };
                      onSubmit(values, {});
                    }}
                    aria-label='Cancel'
                  >
                    {t('common:btnCancel')}
                  </Button>
                  <Dialog
                    style={dialogContentStyles}
                    open={openCancel}
                    onClose={closeConfirmCancelModel}
                    aria-labelledby='form-dialog-title'
                  >
                    <DialogContent>{t('form:confirmCancel')}</DialogContent>
                    <DialogActions>
                      <Button
                        style={buttonStyles}
                        type='submit'
                        onClick={async () => {
                          closeConfirmCancelModel();
                          handleClose();
                        }}
                      >
                        {t('common:yes')}
                      </Button>
                      <Button
                        style={buttonStyles}
                        onClick={closeConfirmCancelModel} autoFocus>
                        {t('common:no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    id='btn-hover-fx'
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={!dirty || isSubmitting}
                    onClick={async () => {
                      onSubmit(values, actions);
                    }}
                  >
                    {t('common:btnSubmit')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

AddNewClientDialog.propTypes = {
  providerId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
};
