import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';

import { FaPhoneAlt } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { RiBuildingFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';

import './careTeamTabListView.scss';

const spanStylesMember = {
    border: '1px solid rgb(255, 153, 0)',
    backgroundColor: 'rgb(255, 153, 0)'
}

const spanStylesAdmin = {
    border: '1px solid rgb(0, 194, 0)',
    backgroundColor: 'rgb(0, 194, 0)'
}

const careTeamTabListView = ({ details }) => {
    const { t } = useTranslation(['common', 'serviceTeam', 'error']);

    let avatarInitials =
        details.name.charAt(0).toUpperCase() + details.name.charAt(1).toUpperCase()

    return (
        <Container>

            <div className='CareTeamCard'>
                <div className='CareTeamCard__row1'>
                    <div className='CareTeamCard__row1__col1'>
                        <Avatar aria-label='recipe'>
                            {avatarInitials}
                        </Avatar>
                    </div>
                    <div className='CareTeamCard__row1__col2'>
                        <h3>{details.name}</h3>
                        <p>{details.userType}</p>
                        <span style={
                            details.teamRole === 'Team Admin' ?
                                spanStylesAdmin : spanStylesMember
                        }>{details.teamRole.toUpperCase()}</span>
                    </div>
                </div>
                <div className='CareTeamCard__row2'>
                    <p style={{ alignSelf: 'flex-start' }}>{t('serviceTeam:lblContactInformation')}</p>
                    <div className='CareTeamCard__row2__content'>
                        <span><FaPhoneAlt /></span><p>{details.contactNumber}</p>
                    </div>
                    <div className='CareTeamCard__row2__content'>
                        <span><MdEmail /></span><p>{details.email}</p>
                    </div>
                    <p
                        style={{
                            color: 'rgb(116, 116, 116)',
                            fontSize: '1.1rem',
                            marginTop: '1rem',
                            alignSelf: 'flex-start'
                        }}>Company</p>
                    <div className='CareTeamCard__row2__content-company'>
                        <span><RiBuildingFill /></span><p>{details.company}</p><span style={{
                            fontSize: '1.3rem',
                            marginLeft: '.5rem',
                            marginTop: '.6rem',
                            color: 'rgb(56, 172, 201)'
                        }}><IoIosArrowForward /></span>
                    </div>
                </div>
                <div className='CareTeamCard__row3'>

                </div>
            </div>


        </Container>
    )
}

export default careTeamTabListView
