import React from 'react';
import PropTypes from 'prop-types';
import './userServiceListViewItem.scss';
import Logo from './ServUsIconWHITE.png';

export default function UserServiceListItemView({ details }) {

  // Set the local attributes
  // let detailsId = details.id;
  let serviceName = details.name;
  let serviceSummary = details.details;

  return (
    <div className='UserServiceListCard'>
      <img src='https://shortbreakcompany.com/wp-content/uploads/team-building-activities-tw.png' alt='activity' />
      <img
        src={Logo}
        alt='service'
        className='UserServiceListCard__avatar' />
      <h2>{serviceName}</h2>
      <p>{serviceSummary}</p>
      <div className='UserServiceListCard__row2'></div>
    </div>
  );
}

UserServiceListItemView.propTypes = {
  details: PropTypes.object.isRequired,
};
