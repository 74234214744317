import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';

import ServiceListItemView from '../services/services-list-item-view';
import SuspenseView from '../../../suspense-view';

import { getProviderServiceList } from '../../../../api/provider-services';

import Toast from '../../../common/snackbar/toast';
import { divStyles, typographyStyles, divStylesMedia } from './styles';
import './services.scss'
import { useMediaQuery } from '../mediaQueries';

import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../utils/toastUtils';

import { BsPlusLg } from 'react-icons/bs'

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import ServiceTypeSelect from '../../../dialogs/service-wizard/service-type-select/serviceTypeSelect';

//CONST Items Per Page
const ITEMSPERPAGE = 6; //needs to be hard coded

const useFetch = (providerId, lastUpdated) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderServiceList(
        providerId
      );
      if (success) {
        setData(data);
      } else {
        setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId, lastUpdated]);

  return { data, error };
};

export default function ServicesTab({ providerId }) {
  const { t, ready } = useTranslation([
    'common',
    'error',
    'provider',
    'service',
  ]);

  const isMedia = useMediaQuery("(max-width: 900px)");

  const classes = useStyles();

  const [locationState, setLocationState] = useState({
    lastUpdated: moment(),
  });

  const setLastUpdated = () => {
    setLocationState((locationState) => ({
      ...locationState,
      lastUpdated: moment(),
    }));
  };

  // Controls display for serviceTypeChecker
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);

  //pagination back and forth functionality
  const handleChange = (event, value) => {
    setPage(value);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:msgSuccessServiceAdded')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:msgServiceUpdateError')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  // Set the date range
  const response = useFetch(providerId, locationState.lastUpdated);
  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const services = response.data;
  let numberOfServices = _.get(services, 'length', 0);
  const numberOfPages = Math.ceil(numberOfServices / ITEMSPERPAGE);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <React.Fragment>

      <div style={isMedia ? divStylesMedia : divStyles}>
        <Typography variant='h6' style={typographyStyles}>
          {t('service:lblNumServices', {
            num: numberOfServices,
          })}
        </Typography>
      </div>

      <div className='ServiceTeamTab__container'>
        {services
          .slice((page - 1) * ITEMSPERPAGE, page * ITEMSPERPAGE)
          .map((entry) => (
            <Grid item key={services.indexOf(entry)}>
              <br />
              <ServiceListItemView
                key={entry.id + '-' + entry.name}
                serviceDetails={entry}
                setLastUpdated={setLastUpdated}
              />
            </Grid>
          ))}

        <div className='ServiceCard__Add-New'>
          <BsPlusLg onClick={handleOpen} className='ServiceCard__Add-New__Plus' />
          <p>{t('provider:lblAddService')}</p>
        </div>

        <ServiceTypeSelect
          setLastUpdated={setLastUpdated}
          success={handleSuccess}
          error={handleError}
          providerId={providerId}
          open={open}
          handleClose={handleClose} />

      </div>

      <div style={{ width: '100%', marginTop: '-5rem' }}>
        <Pagination
          count={numberOfPages}
          page={page}
          onChange={handleChange}
          defaultPage={1}
          color='primary'
          size='large'
          justify='center'
          classes={isMedia ? { ul: classes.paginatorMedia } : { ul: classes.paginator }}
        />
      </div>
      <Toast toastList={list} />

    </React.Fragment>
  );
}

ServicesTab.propTypes = {
  providerId: PropTypes.number,
};
