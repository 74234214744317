import React, { useState } from 'react'
import '../mobileHeader.scss';
import PropTypes from 'prop-types';

import { AiOutlineHome, AiOutlineUser, } from 'react-icons/ai'
import { BsBuilding } from 'react-icons/bs'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { HiDocumentReport } from 'react-icons/hi'

import useAuthDataContext from '../../../../auth/hooks/useAuthDataContext';
import SquareIcon from '../../../common/icons/Square-Icon-white.svg';
import { FaBars } from 'react-icons/fa'

import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import AccountOptions from '../../account-options';
import CustomIcons from '../../../customIcons/CustomIcons'
import { useMediaQuery } from '../../../pages/company-details/mediaQueries';

const iconStyles = {
    color: '#fff',
    fontSize: '2rem',
    marginLeft: '1rem'
}

const buttonStyles = {
    color: '#fff',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1.1rem'
}

const avatarStyles = {
    height: '35px',
    width: '35px',
    fontFamily: 'Poppins, sans-serif',
    marginRight: '1rem'
}

const providerAdminOptions = () => {
    // hides or reveals mobile nav content when clicked on hamburger icon //
    const [showNav, setShowNav] = useState(false);

    // used to close menu if screen is expanded past mobile view while menu is open //
    // if screen is then switched back to mobile view, menu state is closed //
    const isMobile = useMediaQuery('(min-width: 621.5px)');
    if (isMobile && showNav === true) {
        setShowNav(false)
    }

    const { isSignedIn, user, userDetails, signOut } = useAuthDataContext();
    const { t } = useTranslation(['common', 'form', 'authentication']);
    const { goToPage } = useAuthDataContext();

    function handleClickDashboard() {
        goToPage('/dashboard');
        setShowNav(false);
    }

    function handleClickCompanyDetails() {
        goToPage('/company-details');
        setShowNav(false);
    }

    function handleClickClients() {
        goToPage('/company-clients');
        setShowNav(false);
    }

    function handleMessageClick() {
        goToPage('/messages');
        setShowNav(false);
    }

    const SignedInControls = ({ user, signOut }) => {
        const showAccountIcons = user;

        return <React.Fragment>{showAccountIcons ? <AccountOptions signOut={signOut} /> : null}</React.Fragment>;
    };

    SignedInControls.propTypes = {
        user: PropTypes.object,
        signOut: PropTypes.func,
    };


    const handleShowNav = () => {
        //prevents user from accessing dropdown menu if not signed in //
        if (!isSignedIn) {
            setShowNav(false)
        }
        else if (isSignedIn) {
            setShowNav(!showNav)
        }
    }

    const setAvatarInitials = (firstName, lastName) => {
        if (firstName && lastName) {
            return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
        }

        return '';
    };

    let avatarInitials;

    if (userDetails) {
        let firstName = userDetails.first_name;
        let lastName = userDetails.last_name;
        avatarInitials = setAvatarInitials(firstName, lastName);
    }

    return (
        <React.Fragment>
            <div className='MobileHeader'>

                <FaBars
                    className={showNav ? 'flipped' : 'not-flipped'}
                    onClick={handleShowNav}
                    style={iconStyles} id='hamburger-icon' />

                <img style={{ height: '4.5rem', userSelect: 'none' }} src={SquareIcon} alt={'common:svLogoIcon'} />

                {isSignedIn ? (
                    <div className='MobileHeader__avatar'>
                        <Avatar style={avatarStyles}>{avatarInitials}</Avatar>
                        <SignedInControls user={user} signOut={signOut} />
                    </div>
                ) : (
                    <Button
                        style={buttonStyles}
                        onClick={() => goToPage('/signin')}>
                        {t('authentication:signIn')}
                    </Button>
                )}
            </div>
            <div className={showNav ? 'MobileHeader__content' : 'MobileHeader__content__none'}>
                <div hidden>
                    <Button
                        color='primary'
                        startIcon={<AiOutlineHome />}
                        onClick={handleClickDashboard}
                    >
                        {t('menuItems:lblHome')}
                    </Button>
                </div>
                <div className='MobileHeader__content__row' onClick={handleClickCompanyDetails}>
                    <span>
                        <CustomIcons
                            icon={<BsBuilding />}
                            BgColor={'rgb(26, 80, 161)'}
                            iconColor={'#fff'} />
                    </span>
                    <p>{t('menuItems:lblCompanyDetails')}</p>
                </div>
                <div className='MobileHeader__content__row' onClick={handleClickClients}>
                    <span>
                        <CustomIcons
                            icon={<AiOutlineUser />}
                            BgColor={'rgb(26, 80, 161)'}
                            iconColor={'#fff'} />
                    </span>
                    <p>{t('menuItems:lblClients')}</p>
                </div>
                <div className='MobileHeader__content__row' onClick={handleMessageClick}>
                    <span>
                        <CustomIcons
                            icon={<BiMessageSquareDetail />}
                            BgColor={'rgb(26, 80, 161)'}
                            iconColor={'#fff'} />
                    </span>
                    <p>{t('menuItems:lblMessages')}</p>
                </div>

                <div className='MobileHeader__content__row disabled'>
                    <span>
                        <CustomIcons
                            icon={<HiDocumentReport />}
                            BgColor={'rgb(26, 80, 161)'}
                            iconColor={'#fff'} />
                    </span>
                    <p>{t('menuItems:lblReports')}</p>
                </div>
            </div>
        </React.Fragment>
    )
}

providerAdminOptions.propTypes = {
    user: PropTypes.object,
    signOut: PropTypes.func,
    userDetails: PropTypes.object,
};


export default providerAdminOptions;