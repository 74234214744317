import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../common/tab-panel/tabPanel';
import ServicesTab from '../services';
import LocationsTab from '../location';
import ServiceTeamTab from '../service-teams';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { useMediaQuery } from '../mediaQueries';

const divStyles = {
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid rgba(26, 80, 161, 0.116)',
}

const divStylesMedia = {
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid rgba(26, 80, 161, 0.116)',
  justifyContent: 'center',
}

export default function NavigationTabs({ providerId }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation(['provider']);

  const isMedia = useMediaQuery("(max-width: 900px)");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <div style={isMedia ? divStylesMedia : divStyles}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab className={classes.tabItem} label={t('provider:lblServices')} />
          <Tab
            className={classes.tabItem}
            label={t('provider:lblServiceTeams')}
          />
          <Tab className={classes.tabItem} label={t('provider:lblLocations')} />
        </Tabs>
      </div>

      <TabPanel value={value} index={0} >
        <ServicesTab providerId={providerId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ServiceTeamTab providerId={providerId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LocationsTab providerId={providerId} />
      </TabPanel>
    </div>
  );
}

NavigationTabs.propTypes = {
  providerId: PropTypes.number,
};
