import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './stepperStyles.scss'
import Dialog from '@material-ui/core/Dialog';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import DialogTitle from '../common/dialog-title';
import ServiceForm from './service-form/serviceForm';
import LocationsForm from './locations-form/locationsForm';
import SummaryForm from './summary-form/summaryForm';

import SuspenseView from '../../suspense-view';

import { useTranslation } from 'react-i18next';
import { DialogContent } from '@material-ui/core';
import useStyles from './styles';
import { ServiceContext } from './serviceContext';
import DetailsFormChecker from './details-forms/detailsFormChecker';

function getSteps(t) {
  return [
    t('service:lblService'),
    t('service:lblDetails'),
    t('service:lblLocations'),
    t('common:lblReview'),
  ];
}

export default function AddServiceForm({
  show,
  handleClose,
  providerId,
  setLastUpdated,
  success,
  error,
  handleCloseModal
}) {
  const { t, ready } = useTranslation(['service', 'common']);
  const classes = useStyles();

  const initialState = {
    // Initialize the current active step
    providerId: providerId,

    // Initialize the data from each step
    service: {},
    locations: [],
    hoursOfService: {
      Monday: ["09:00", "17:00"],
      Tuesday: ["09:00", "17:00"],
      Wednesday: ["09:00", "17:00"],
      Thursday: ["09:00", "17:00"],
      Friday: ["09:00", "17:00"],
      Saturday: null,
      Sunday: null,
    }
  };



  // const [state, setState] = useState(initialState);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps(t);

  const { state, setState } = useContext(ServiceContext)


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  };


  const handleReset = () => {
    setState(initialState);
    setActiveStep(0)
  };

  const onClose = () => {
    handleReset();
    handleClose();
    handleCloseModal()
  };

  const handleSubmit = () => {
    setLastUpdated();
    handleReset();
    handleClose();
  };

  if (!ready) {
    return <SuspenseView />;
  }


  return (
    <React.Fragment>
      <Dialog
        open={show}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby='location-wizard-title'
      >
        <DialogTitle
          className='Stepper__title'
          id='location-wizard-title' onClose={onClose}>
          {t('service:lblAddNewService')}
        </DialogTitle>
        <DialogContent className='Stepper'>
          <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <ServiceForm handleNext={handleNext} />
          )}
          {activeStep === 1 && (
            <DetailsFormChecker
              serviceType={state.service.serviceCategorySecondary}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          )}
          {activeStep === 2 && (
            <LocationsForm
              handleNext={handleNext}
              handleBack={handleBack}
            />
          )}
          {activeStep === 3 && (
            <SummaryForm
              handleBack={handleBack}
              handleSubmit={handleSubmit}
              success={success}
              error={error}
            />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

AddServiceForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  providerId: PropTypes.number.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};
