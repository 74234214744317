import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SuspenseView from '../../../../suspense-view';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
  GoogleMap,
  Marker,
} from '@react-google-maps/api';


import Toast from '../../../../common/snackbar/toast';
import { buttonStyles, dialogContentStyles } from '../../../../dialogs/customStyles/customStyles';

// import { IoLocationSharp } from 'react-icons/io5'
import { FaTrash } from 'react-icons/fa'
import { MdModeEditOutline } from 'react-icons/md'

import EditLocationDetailsDialog from '../../../../dialogs/edit-location-details/editLocationDetailsDialog';

import { removeLocation } from '../../../../../api/provider-location';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../../utils/toastUtils';

import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import CustomIcon from '../../../../customIcons/CustomIcons';


const options = {
  disableDefaultUI: true,
  zoomControl: false,
  gestureHandling: 'none'
};

const mapContainerStyle = {
  height: '17.8rem',
  width: '25rem',
};
//This is the card component for the added locations
export default function LocationListItemView({ details, setLastUpdated, isLoaded, loadError }) {
  const { t } = useTranslation('location', 'common', 'error');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const open = Boolean(anchorEl);

  /**
   * Methods for handling the delete confirmation dialog
   */
  const [openDelete, setOpenDelete] = useState(false);

  const openConfirmDeleteModel = () => {
    setOpenDelete(true);
  };

  const closeConfirmDeleteModel = () => {
    setOpenDelete(false);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:lblMsgSuccessDelete')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:lblMsgFailDelete')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  const handleConfirmYes = async (providerId, locationId) => {
    const { success, errorMessage } = await removeLocation(
      providerId,
      locationId
    );
    if (success) {
      setLastUpdated();
      handleSuccess();
    } else {
      // TODO: Show error message on the screen
      console.log(errorMessage);
      handleError();
    }
  };

  //
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // EDIT Dialog Methods
  const openEditDialog = () => {
    setShowEditDialog(true);
    handleClose();
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  // Show the information
  let locationId = details.id;
  let providerId = details.provider_id;

  // For address display
  let address2Length = 0;
  if (details.address2)
    address2Length = details.address2.length;

  // TODO: Adjust the way that long addresses are shown in the card
  /*{detailsLine.split('\n').map((i, key) => {
    return <div key={key}>{i}</div>;
  })}*/

  if (loadError) {
    return 'Error';
  }

  const name = details.name.toLowerCase()

  return (
    <Container>
      <div className='LocationCard'>
        <div className='LocationCard__row1'>
          {!isLoaded ? (
            <SuspenseView />
          ) : (
            <GoogleMap
              id='map'
              mapContainerStyle={mapContainerStyle}
              zoom={16}
              center={{ lat: parseFloat(details.latitude), lng: parseFloat(details.longitude) }}
              options={options}
              gestrueHandling={'none'}
            >
              <Marker position={{ lat: parseFloat(details.latitude), lng: parseFloat(details.longitude) }} clickable={false} />
            </GoogleMap>
          )}
        </div>
        <div className='LocationCard__row2'>
          <h2 style={{ textTransform: 'capitalize' }}>{name}</h2>
          <div className='LocationCard__row2__address'>
            {address2Length !== 0 ?
              <div className='LocationCard__row2__address__row'>
                <p>{details.address1}, {details.address2} {details.city}</p>
              </div>
              :
              <div className='LocationCard__row2__address__row'>
                <p>{details.address1}, {details.postal_code} {details.city} {details.province}, {details.country}</p>
              </div>
            }
          </div>
        </div>
        <div className='LocationCard__row3'>
          <div className='LocationCard__row3__col2'>
            <IconButton
              aria-label={t('location:lblEditDetails')}
              onClick={handleMenu}
            >
              <CustomIcon
                icon={<MdModeEditOutline />}
                iconColor='#fff'
                BgColor='rgb(56, 172, 201)' />
            </IconButton>
          </div>
          <div className='LocationCard__row3__col3'>
            <Tooltip title={t('location:lblRemoveLocation')} enterDelay={300}>
              <IconButton
                aria-label={t('location:lblRemoveLocation')}
                onClick={openConfirmDeleteModel}
              >
                <CustomIcon
                  icon={<FaTrash />}
                  iconColor='rgb(56, 172, 201)'
                  BgColor='rgb(212, 246, 255)' />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>


      <Menu
        id='menu-appbar'
        elevation={0}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={openEditDialog}>
          {t('location:lblEditDetails')}
        </MenuItem>
      </Menu>

      <EditLocationDetailsDialog
        show={showEditDialog}
        handleClose={closeEditDialog}
        details={details}
        setLastUpdated={setLastUpdated}
      />
      <Dialog
        open={openDelete}
        onClose={closeConfirmDeleteModel}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent style={dialogContentStyles}>
          {t('location:lblDeleteLocationMessage')}
        </DialogContent>
        <DialogActions>
          <Button
            style={buttonStyles}
            onClick={handleConfirmYes.bind(this, providerId, locationId)}
          >
            {t('common:yes')}
          </Button>
          <Button style={buttonStyles} onClick={closeConfirmDeleteModel} autoFocus>
            {t('common:no')}
          </Button>
        </DialogActions>
      </Dialog>
      <Toast toastList={list} />

    </Container>
  );
}

LocationListItemView.propTypes = {
  details: PropTypes.object,
  setLastUpdated: PropTypes.func,
  isLoaded: PropTypes.bool,
  loadError: PropTypes.object
};
