import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip'
import { tooltipClasses } from '@mui/material';
import { styled } from '@material-ui/core/styles';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { getEnvVariable } from '../../../../../utils/environmentVariables';

import './locationListViewItem.scss'

const divStylesChecked = {
  margin: '1rem',
  padding: '.8rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  width: '30rem',
  borderRadius: '35px',
  alignItems: 'center',
  opacity: '1',
  color: 'white',
  backgroundColor: 'rgb(26, 80, 161)',
  fontSize: '1.5rem',
  fontWeight: '600',
  textTransform: 'capitalize'
}

const divStyles = {
  margin: '1rem',
  padding: '.8rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30rem',
  borderRadius: '35px',
  fontWeight: '600',
  opacity: '.7',
  color: 'rgb(26, 80, 161)',
  backgroundColor: 'rgb(234, 239, 247)',
  fontSize: '1.5rem',
  textTransform: 'capitalize'
}

const textStyles = {
  fontSize: '1.1rem',
  fontWeight: '300',
  color: 'gray'
}

const textStylesPrimary = {
  marginTop: '.5rem',
  fontSize: '1.3rem',
  fontWeight: '500'
}



const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 'auto',
    fontSize: '1rem',
    border: '1px solid rgb(234, 239, 247)',
    borderRadius: '15px',
    padding: '.5rem 2rem',
    fontFamily: 'Poppins, sans-serif'
  },
}));


export default function LocationListItemView({
  location,
  handleToggle,
  state
}) {

  const mapContainerStyle = {
    width: '26rem',
    height: '15rem',
    borderRadius: '20px',
    marginTop: '1rem'
  }


  const center = {
    lat: parseFloat(location.latitude),
    lng: parseFloat(location.longitude)
  }

  const options = {
    disableDefaultUI: true
  }

  const locationLower = location.name.toLowerCase()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: getEnvVariable('REACT_APP_API_MAP_KEY')
  })

  if (loadError) return 'Error loading maps'
  if (!isLoaded) return 'Loading...'

  return (
    <HtmlTooltip
      enterNextDelay={500}
      title={
        <div className='LocationFormPopup'>
          <h3 style={{ margin: '.5rem 0', fontSize: '1.8rem', textTransform: 'capitalize' }}>
            {locationLower}
          </h3>
          <p style={textStylesPrimary}>{location.address1}</p>
          <p style={textStyles}>{location.postal_code} {location.city}, {location.province} {location.country}</p>
          <GoogleMap
            zoom={13}
            mapContainerStyle={mapContainerStyle}
            center={center}
            options={options}>
            <Marker
              position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
            />
          </GoogleMap>
        </div>

      }>

      <div
        onClick={handleToggle(location)}
        id='hover'
        style={state.locations.some((loc) => loc.id === location.id) ? divStylesChecked : divStyles} >
        {locationLower}
      </div>
    </HtmlTooltip>
  );
}

LocationListItemView.propTypes = {
  location: PropTypes.object,
  handleToggle: PropTypes.func,
  state: PropTypes.object
};
