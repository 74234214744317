import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { IoIosArrowDown, IoMdLogOut } from 'react-icons/io';
import { FaRegUserCircle } from 'react-icons/fa';


import EditProfileDialog from '../../dialogs/edit-profile';
import Toast from '../../common/snackbar/toast';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../utils/toastUtils';
import { useTranslation } from 'react-i18next';




const menuItemStyles = {
  fontSize: '1.1rem',
  fontFamily: 'Poppins, sans-serif',

  width: '12rem',
  color: 'black',
  opacity: '.7',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const iconStyles = {
  fontSize: '1.5rem',
  color: 'gray',
  opacity: '.8'
}

export default function AccountOptions({ signOut }) {


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const { t } = useTranslation('common', 'error');

  // Edit Profile Dialog Methods
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const openProfileDialog = () => {
    setShowProfileDialog(true);
  };

  const closeProfileDialog = () => {
    setShowProfileDialog(false);
  };

  // Menu Handling Events
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //toast success fail methods
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:msgSuccessAccountDetailsUpdate')
  );
  let errorProperties = setHandleErrorProperties(
    t('error:lblError'),
    t('error:msgAccountUpdateFail')
  );

  const handleToastSuccess = () => {
    setList([...list, successProperties]);
  }

  const handleToastFail = () => {
    setList([...list, errorProperties]);
  }

  return (
    <>
      <div>
        <IconButton
          aria-label={t('common:lblAccount')}
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <IoIosArrowDown style={{ color: '#fff' }} />
        </IconButton>
        <Menu
          id='menu-appbar'
          elevation={0}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            style={menuItemStyles}
            onClick={() => {
              openProfileDialog();
              handleClose();
            }}
          >
            {t('common:lblAccount')}
            <FaRegUserCircle style={iconStyles} />
          </MenuItem>
          <MenuItem
            style={menuItemStyles}
            onClick={() => {
              signOut();
              handleClose();
            }}
          >
            {t('authentication:signOut')}
            <IoMdLogOut style={iconStyles} />
          </MenuItem>
        </Menu>
        <div hidden>
          <EditProfileDialog
            show={showProfileDialog}
            handleClose={closeProfileDialog}
            handleToastFail={handleToastFail}
            handleToastSuccess={handleToastSuccess}
          />
        </div>
      </div>
      <Toast toastList={list} />
    </>
  );
}

AccountOptions.propTypes = {
  signOut: PropTypes.func.isRequired,
};
