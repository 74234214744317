export const getProviderId = (userDetails) => {
  let providerAccess = userDetails.provider_access;
  if (providerAccess) {
    return providerAccess.provider_id;
  }

  return null;
};

export const getUserRole = (userDetails) => {
  let providerAccess = userDetails.provider_access;
  if (providerAccess) {
    return providerAccess.user_type;
  }

  return null;
};
