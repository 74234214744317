import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0.5, 0, 2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  linkButton: {
    textTransform: 'none',
    color: 'rgb(16, 31, 119)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem'
  },
}));
