import React from 'react'
import PropTypes from 'prop-types';
import Logo from '../assets/ServUsIconWHITE.png';
import CustomMarker from '../assets/Ellipse 66.svg';
import { GoogleMap, Marker, InfoWindow, Circle } from '@react-google-maps/api';
// import CustomSpinner from '../../../suspense-view/customSpinner';

import '../serviceDiscoveryPage.scss';

const circleOptions = {
  fillColor: "rgb(134, 201, 255)",
  fillOpacity: '.2',
  strokeWeight: '1',
  strokeColor: 'rgb(13, 83, 173)',
  strokeOpacity: '.5'
}

const options = {
  disableDefaultUI: true,
  zoomControl: true,
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
}



const Map = ({
  userLocation,
  handleActive,
  active,
  provider,
  filteredProvidersByDistance,
  userDistance,
  setActive
}) => {

  return (
    <div className='ServiceDiscoveryPage__container__map'>

      <GoogleMap
        zoom={8}
        mapContainerStyle={mapContainerStyle}
        center={{ lat: parseFloat(userLocation.lat), lng: parseFloat(userLocation.lng) }}
        options={options}
      >
        <Circle
          radius={userDistance * 1000}
          center={{ lat: parseFloat(userLocation.lat), lng: parseFloat(userLocation.lng) }}
          options={circleOptions}
        />

        <Marker
          position={{ lat: parseFloat(userLocation.lat), lng: parseFloat(userLocation.lng) }}
          icon={{
            url: CustomMarker,
            scaledSize: new window.google.maps.Size(15, 15)
          }}
        />
        {provider && filteredProvidersByDistance.map((location) => (
          <Marker
            key={location.id}
            position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
            onClick={() => handleActive(location)}
          />
        ))}
        {active !== null ? (
          <InfoWindow
            onCloseClick={() => setActive(null)}
            position={{ lat: parseFloat(active.latitude), lng: parseFloat(active.longitude) }}
            options={{
              pixelOffset: new window.google.maps.Size(0, -25)
            }}

          >
            <div className='ServiceDiscoveryPage__info-marker'>
              <img src={Logo} alt={Logo} />
              <h4>Test Service</h4>
              <p>{active.address1}</p>
              <p>{provider.provider.contact_email}</p>
              <p>{provider.provider.website}</p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  )
}

Map.propTypes = {
  active: PropTypes.object,
  handleActive: PropTypes.func,
  userLocation: PropTypes.object,
  provider: PropTypes.object,
  filteredProvidersByDistance: PropTypes.array,
  userDistance: PropTypes.number,
  setActive: PropTypes.func
}

export default Map;
