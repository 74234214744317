import React from 'react'
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import SquareIcon from '../../../common/icons/Square-Icon-white.svg';
import { FaBars } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";

const iconStyles = {
    color: '#fff',
    fontSize: '2rem',
    marginLeft: '1rem'
}

const buttonStyles = {
    color: '#fff',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1.1rem'
}

const signIn = () => {
    let navigate = useNavigate();
    const { t } = useTranslation(['common', 'form', 'authentication']);

    return (
        <div>
            <React.Fragment>
                <div className='MobileHeader'>

                    <FaBars
                        style={iconStyles} id='hamburger-icon' />

                    <img style={{ height: '4.5rem', userSelect: 'none' }} src={SquareIcon} alt={'common:svLogoIcon'} />
                    <Button
                        style={buttonStyles}
                        onClick={() => navigate('/signin', { replace: true })}>
                        {t('authentication:signIn')}
                    </Button>
                </div>

            </React.Fragment>
        </div>
    )
}

export default signIn
