import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import JobPositionList from '../../common/lists/job-position-list';
import PhoneNumberMask from '../../common/form/phone-number-mask';
import SuspenseView from '../../suspense-view';

import { VALUE_SELECT } from '../../common/constants';
import { addProviderServiceTeamUser } from '../../../api/provider-service-team';
import FileUploadTeamMember from '../../fileUpload/FileUploadTeamMember';

import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../common/select/selectInput';
import { languages } from './languageOptions';

import { moreMarginStyles, dialogContentStyles, buttonStyles, fieldTitleStyles } from '../customStyles/customStyles';

// TODO: Add Toast messages
export default function AddServiceTeamDialog({
  show,
  handleClose,
  providerId,
  setLastUpdated,
  onSuccess,
  onError,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const [files, setFiles] = useState([]);

  const { t, ready } = useTranslation([
    'authentication',
    'common',
    'form',
    'serviceTeam',
  ]);
  const classes = useStyles();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmCancelModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmCancelModel = () => {
    setOpenCancel(false);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    jobPosition: '',
    email: '',
    phoneNumber: '',
    languages: [],
    files: []
  };

  const requiredTranslated = t('form:required');
  const AddTeamMemberSchema = Yup.object().shape({
    firstName: Yup.string().required(requiredTranslated),
    lastName: Yup.string().required(requiredTranslated),
    jobPosition: Yup.string()
      .notOneOf([VALUE_SELECT], requiredTranslated)
      .required(requiredTranslated),
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
    phoneNumber: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[ ]([0-9]{3})[-]([0-9]{4})$/,
        t('form:invalidPhoneNumber')
      )
      .required(t('form:invalidPhoneNumber')),
  });

  const onSubmit = async (values, actions) => {
    if (values && !values.isCancelButton) {
      const result = await addProviderServiceTeamUser(providerId, values);
      if (result.success) {
        handleClose();
        setLastUpdated();
        onSuccess();
      } else {
        let errorMessage;
        if (result.statusCode === 409) {
          errorMessage = t('authentication:errorEmailAlreadyInUse', {
            name: values.email,
          });
          onError();
        } else {
          errorMessage = t(result.errorMessage);
          onError();
        }

        actions.setStatus({ msg: errorMessage });
      }
    } else {
      if (values.isDirty) {
        openConfirmCancelModel();
      } else {
        handleClose();
      }
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }



  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='add-team-member-dialog-title'
      >
        <DialogTitle className='Stepper__title' id='add-team-member-dialog-title'>
          {t('serviceTeam:lblNewTeamMember')}

        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            validationSchema={AddTeamMemberSchema}
          >
            {({
              errors,
              touched,
              values,
              status,
              handleChange,
              isSubmitting,
              dirty,
            }) => (

              <Form>
                <div style={{ display: 'flex', height: 'auto' }}>

                  <div style={{ width: '40%' }}>
                    <FileUploadTeamMember name='files' type='select' files={files} setFiles={setFiles} />
                  </div>

                  <div style={{ width: '60%', height: 'auto' }}>
                    <h3 style={fieldTitleStyles}>{t('common:lblFirstName')}</h3>
                    <Field
                      className={classes.textField}
                      type='text'
                      name='firstName'
                      id='firstName'
                      margin='normal'
                      variant='outlined'
                      component={TextField}
                      placeholder={t('common:lblFirstName')}
                      value={'' || values.firstName}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div>{errors.firstName}</div>
                    ) : null}
                    <h3 style={fieldTitleStyles}>{t('common:lblLastName')}</h3>
                    <Field
                      className={classes.textField}
                      type='text'
                      name='lastName'
                      id='lastName'
                      margin='normal'
                      variant='outlined'
                      component={TextField}
                      placeholder={t('common:lblLastName')}
                      value={'' || values.lastName}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div>{errors.lastName}</div>
                    ) : null}
                    <h3 style={fieldTitleStyles}>{t('serviceTeam:lblJobPosition')}</h3>
                    <Field
                      select
                      component={TextField}
                      name='jobPosition'
                      id='jobPosition'
                      variant='outlined'
                      className={classes.textField}
                      value={'' || values.jobPosition}
                      onChange={handleChange}
                      SelectProps={{
                        native: true,
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin='normal'
                    >

                      <JobPositionList />
                    </Field>

                    <h3 style={fieldTitleStyles}>{'Language(s)'}</h3>
                    <SelectInput
                      type='select'
                      name='languages'
                      options={languages}
                      isMulti={true}
                      placeholder='Select a language(s)'
                      formName={'languages'}
                    />
                    {errors.jobPosition && touched.jobPosition ? (
                      <div>{errors.jobPosition}</div>
                    ) : null}
                    <h3 style={fieldTitleStyles}>{t('common:lblEmail')}</h3>
                    <Field
                      className={classes.textField}
                      type='email'
                      name='email'
                      id='email'
                      margin='normal'
                      variant='outlined'
                      component={TextField}
                      placeholder={t('common:lblEmail')}
                      value={'' || values.email}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.email && touched.email ? (
                      <div>{errors.email}</div>
                    ) : null}
                    <h3 style={fieldTitleStyles}>{t('common:lblPhoneNumber')}</h3>
                    <Field
                      className={classes.textField}
                      type='text'
                      name='phoneNumber'
                      id='phoneNumber'
                      margin='normal'
                      variant='outlined'
                      component={TextField}
                      placeholder={t('common:lblPhoneNumber')}
                      value={'' || values.phoneNumber}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ inputComponent: PhoneNumberMask }}
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <div>{errors.phoneNumber}</div>
                    ) : null}

                    {status && status.msg && (
                      <Typography className={classes.error}>
                        {status.msg}
                      </Typography>
                    )}

                  </div>
                </div>

                <Grid style={moreMarginStyles} container justifyContent='flex-end'>
                  <Button
                    id='btn-back'
                    variant='text'
                    color='inherit'
                    onClick={async () => {
                      values = {
                        isCancelButton: true,
                        isDirty: dirty,
                      };
                      onSubmit(values, {});
                    }}
                    aria-label='Cancel'
                  >
                    {t('common:btnCancel')}
                  </Button>
                  <Dialog
                    open={openCancel}
                    onClose={closeConfirmCancelModel}
                    aria-labelledby='form-dialog-title'
                  >
                    <DialogContent style={dialogContentStyles}>{t('form:discardChanges')}</DialogContent>
                    <DialogActions>
                      <Button
                        style={buttonStyles}
                        type='submit'
                        onClick={async () => {
                          closeConfirmCancelModel();
                          handleClose();
                        }}
                      >
                        {t('common:yes')}
                      </Button>
                      <Button style={buttonStyles} onClick={closeConfirmCancelModel} autoFocus>
                        {t('common:no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    id='btn-hover-fx'
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={!dirty || isSubmitting}
                  >
                    {t('serviceTeam:lblAddServiceTeamMember')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

AddServiceTeamDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  providerId: PropTypes.number.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
