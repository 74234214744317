import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    stepper: {
        borderRadius: '25px',
        marginTop: '3rem',
        width: '100%',
        maxWidth: '50rem',
    },
}));

export const btnStyles = {
    color: 'white',
    backgroundColor: 'rgb(26, 80, 161)',
    fontFamily: 'Poppins, sans-serif',
    borderRadius: '20px',
    textTransform: 'capitalize'
}

export const notActiveStyles = {
    fontSize: '1.4rem',
    fontFamily: 'Poppins, sans-sefif',
    fontWeight: '600',
}

export const errorStyles = {
    color: 'red',
    marginTop: '-1.8rem',
    marginBottom: '1.5rem'
}

export const errorStylesDate = {
    color: 'red',
    marginTop: '-1.5rem',

}

export const errorStylesDiv = {
    marginBottom: '1rem',
    color: 'red'
}

export const imageStyles = {
    marginTop: '-2rem',
    width: '12rem',
}

export const divStyles = {
    display: 'flex',
    alignItems: 'center'
}

export const textStyles = {
    fontSize: '1.2rem',
    fontWeight: '600',
    color: 'gray',
    transform: 'translateX(-15px)'
}

export default useStyles;