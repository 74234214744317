import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik, Form } from 'formik';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useContext } from 'react';
import { ServiceContext } from '../serviceContext';

import { addServiceForProvider } from '../../../../api/provider-services';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import './summaryForm.scss'

export default function SummaryForm({
  handleBack,
  handleSubmit,
  success,
  error,
}) {
  const { t } = useTranslation(['common', 'form', 'service']);
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);

  const { state } = useContext(ServiceContext)

  let insurableService = state.service.insurableService;
  let insurableServiceValue = insurableService
    ? t('common:yes')
    : t('common:no');

  const onSubmit = async () => {
    let values = state.service;



    const result = await addServiceForProvider(state.providerId, values);
    if (result.success) {
      success();
      handleSubmit();
    } else {
      let errorMessage;
      if (result.statusCode === 422) {
        errorMessage = t('error:defaultMessage');
        error();

      } else {
        errorMessage = t(result.errorMessage);
        error();

      }
      setErrorMessage(errorMessage);
    }
  };

  // const handleRemoveFiles = (value) => {
  //   setState({
  //     ...state,
  //     service: {
  //       ...state.service,
  //       files: state.service.files.filter((file) => file !== value)
  //     }
  //   })
  // }

  function RenderHours() {
    const days = {};
    let hoursOfService = state.hoursOfService

    for (const day in hoursOfService) {
      days[day] =
        hoursOfService[day] !== null
          ? `${moment(hoursOfService[day][0], "HH:mm").format('h:mm a')} - ${moment(hoursOfService[day][1], "HH:mm").format('h:mm a')}`
          : "Closed";
    }

    return (
      <div>
        {Object.keys(days).map((day, idx) => (
          <p
            style={{ color: 'black', fontSize: '1.1rem', fontWeight: '300', margin: '.2rem 0' }}
            key={idx}
          >
            <span>{day}:</span> {days[day]}
          </p>
        ))}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Formik
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className='SummaryForm'>

              <div className='SummaryForm__content'>
                <h2>{t('service:lblService')}</h2>

                <h3>{t('service:lblServiceName')}: </h3>
                <p>{state.service.name}</p>

                <h3>{'Service Categories'}: </h3>
                <p>Primary: {state.service.serviceCategory}</p>
                <p style={{ marginTop: '-1rem' }}>Secondary: {state.service.serviceCategorySecondary}</p>

                <h3>{t('service:lblSummary')}: </h3>
                <p>{state.service.summary}</p>

                <h3>{t('service:lblHoursOfService')}:</h3>
                <RenderHours />

                {/* {state.service.files.length && state.service.files.length > 0 ? (
                  <div className='attached-files'>
                    <h3>{t('service:lblApplicationForms')}</h3>
                    {state.service.files.map((file, idx) => (

                      <p key={idx}><span onClick={() => handleRemoveFiles(file)}>X</span>{file.name}</p>

                    ))}
                  </div>
                ) : null} */}
              </div>
              <div className='SummaryForm__content'>

                <h2>{t('service:lblDetails')}</h2>

                <h3>{t('service:lblInsurableService')}:</h3>
                <p>{insurableServiceValue}</p>

                <div className='insurableDetails' hidden={!insurableService}>

                  {state.service.insurableDetails !== '' ? (
                    <React.Fragment>
                      <h3>{t('service:lblInsurableDetails')}:</h3>
                      <p>{state.service.insurableDetails}</p>
                    </React.Fragment>
                  ) : null}

                  <h3>{t('service:lblLocations')}:</h3>
                  {state.locations.map((location, idx) => (
                    <div
                      style={{ margin: '.2rem 0' }}
                      key={location.id}>

                      <p style={{ color: 'black', fontWeight: '300', fontSize: '1.1rem' }}>
                        {idx + 1}: <span style={{ textTransform: 'capitalize' }}>{location.name},</span>
                        {' '}{location.address1} {location.city}, {location.province} {location.country}
                      </p>
                    </div>
                  ))}

                </div>

                <h3 style={{ marginTop: '1rem' }}>{t('service:lblEligibilityCriteria')}:</h3>
                <p>{state.service.eligibilityCriteria}</p>

                {/*    <h3>{t('service:lblGeographicalAreaServed')}:</h3>
            <p>{state.service.geographicalAreaServed}</p> */}

                <h3>{t('service:lblEducationalResources')}:</h3>
                <p>{state.service.educationalResources}</p>
              </div>
            </div>
            {errorMessage && (
              <Typography className={classes.error}>{errorMessage}</Typography>
            )}

            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Button id='btn-back' type='submit' style={{ padding: '.6rem 0' }} onClick={handleBack}>{t('common:btnBack')}</Button>
                <Button id='btn-hover-fx' variant='contained' color='primary' onClick={onSubmit} disabled={!isValid || isSubmitting}>
                  {t('form:submit')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

SummaryForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  state: PropTypes.object,
  success: PropTypes.func,
  error: PropTypes.func,
};
