import React from 'react'
import '../clientOnBoarding.scss'
import logo from '../assets/Icons-05 2.svg'
import PropTypes from 'prop-types';
import ClientServiceNeedsOption from './clientServiceNeedsOption';
import { useTranslation } from 'react-i18next';

const imageStyles = {
  marginTop: '-2rem',
  width: '12rem',
}

const titleStyles = {
  fontSize: '2rem',
  marginBottom: '2rem',
  color: 'rgb(26, 80, 161)'
}

const spanStyles = {
  fontSize: '1.3rem'
}

const ClientServiceNeeds = ({ values, setValues }) => {
  const { t } = useTranslation(['client']);

  const serviceOptions = [
    t('client:wizardInHomeSupport'),
    t('client:wizardCommunityWellness'),
    t('client:wizardTherapyCareServices'),
    t('client:wizardCareGiver'),
    t('client:wizardSeniorHousing'),
    t('client:wizardCounselling'),
    t('client:wizardFinancial'),
    t('client:wizardVehicles'),
    t('client:wizardOutDoorRec')
  ]

  return (
    <div className='ClientOnBoarding'>
      <img style={imageStyles} src={logo} alt='logo' />
      <h3 style={titleStyles}>{t('client:lblSelectYourNeeds')}{values.needs.length > 0 ? <span>({values.needs.length})</span> : null} <span style={spanStyles}>(optional)</span></h3>
      <div className='ClientOnBoarding__options'>
        {serviceOptions.map((option, idx) => (
          <ClientServiceNeedsOption key={idx} option={option} values={values} setValues={setValues} />
        ))}
      </div>
    </div>
  )
}

ClientServiceNeeds.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired
};
export default ClientServiceNeeds
