import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Rating from '@mui/material/Rating';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';

import Menu from '@material-ui/core/Menu';
import Toast from '../../../../common/snackbar/toast';

import CustomIcon from '../../../../customIcons/CustomIcons';
import { FaTrash } from 'react-icons/fa'
import { MdModeEditOutline } from 'react-icons/md'

import { buttonStyles, dialogContentStyles } from '../../../../dialogs/customStyles/customStyles';

// TODO: Remove when the location can be shown accurately
import Logo from './ServUsIconWHITE.png';

import EditServicesDialog from '../../../../dialogs/edit-service/editServiceDialog';

import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../../utils/toastUtils';

import { removeService } from '../../../../../api/provider-services';
import { useTranslation } from 'react-i18next';

export default function ServicesListItemView({
  serviceDetails,
  setLastUpdated,
}) {
  const { t } = useTranslation('common', 'error', 'service');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const open = Boolean(anchorEl);

  /**
   * Methods for handling the delete confirmation dialog
   */
  const [openDelete, setOpenDelete] = useState(false);

  const openConfirmDeleteModel = () => {
    setOpenDelete(true);
  };

  const closeConfirmDeleteModel = () => {
    setOpenDelete(false);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:msgServiceDeletedSuccess')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:msgServiceDeleteError')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  const handleConfirmYes = async (providerId, serviceId) => {
    const { success, errorMessage } = await removeService(
      providerId,
      serviceId
    );
    if (success) {
      setLastUpdated();
      handleSuccess();
    } else {
      // TODO: Show error message
      console.log(errorMessage);
      handleError();
    }
  };

  //
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // EDIT Dialog Methods
  const openEditDialog = () => {
    setShowEditDialog(true);
    handleClose();
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  // Show the information
  let serviceId = serviceDetails.id;
  let providerId = serviceDetails.provider_id;

  const [rating] = useState(4)

  return (
    <React.Fragment>

      <div className='ServiceCard'>
        <div className='ServiceCard__row1'>
          <img
            style={{
              height: '14rem',
              objectFit: 'cover',
              backgroundColor: 'rgb(16, 31, 119)',

            }}
            src={Logo} alt='map' />
        </div>
        <div className='ServiceCard__row2'>
          <h2>{serviceDetails.name}</h2>
          <div className='ServiceCard__row2__address'>

            <div className='ServiceCard__row2__address__row'>
              <p>{serviceDetails.summary}</p>
            </div>
          </div>
          <div className='ServiceCard__row2__address__row'>
            <span id='rating'>{rating}</span>
            <Rating name="read-only" value={rating} readOnly />
          </div>
          <div className='ServiceCard__row2__address__rating'>
            <p>0 reviews</p>
          </div>

        </div>
        <div className='ServiceCard__row3'>
          <div className='ServiceCard__row3__col2'>
            <IconButton
              aria-label={t('service:lblEditServiceDetails')}
              onClick={handleMenu}
            >
              <CustomIcon
                icon={<MdModeEditOutline />}
                iconColor='#fff'
                BgColor='rgb(56, 172, 201)'
              />
            </IconButton>
          </div>
          <div className='ServiceCard__row3__col3'>
            <Tooltip title={t('service:lblRemoveService')} enterDelay={300}>
              <IconButton
                aria-label={t('service:lblRemoveService')}
                onClick={openConfirmDeleteModel}
              >
                <CustomIcon
                  icon={<FaTrash />}
                  iconColor='rgb(56, 172, 201)'
                  BgColor='rgb(212, 246, 255)' />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      <Menu
        id='menu-appbar'
        elevation={0}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={openEditDialog}>
          {t('service:lblEditService')}
        </MenuItem>
      </Menu>
      <EditServicesDialog
        show={showEditDialog}
        handleClose={closeEditDialog}
        details={serviceDetails}
        setLastUpdated={setLastUpdated}
      />

      <Dialog
        open={openDelete}
        onClose={closeConfirmDeleteModel}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent style={dialogContentStyles}>{t('service:lblDeleteServiceMessage')}</DialogContent>
        <DialogActions>
          <Button
            style={buttonStyles}
            onClick={handleConfirmYes.bind(this, providerId, serviceId)}
          >
            {t('common:yes')}
          </Button>
          <Button style={buttonStyles} onClick={closeConfirmDeleteModel} autoFocus>
            {t('common:no')}
          </Button>
        </DialogActions>
      </Dialog>

      <Toast toastList={list} />

    </React.Fragment>
  );
}

ServicesListItemView.propTypes = {
  serviceDetails: PropTypes.object,
  setLastUpdated: PropTypes.func,
};
