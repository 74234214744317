import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { addLocationForProvider } from '../../../../api/provider-location/index';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { moreMarginStyles } from '../../customStyles/customStyles';

import { useContext } from 'react';
import { LocationContext } from '../locationContext';

const titleStyles = {
  fontSize: '2.2rem',
  fontWeight: '600',
  color: 'rgb(26, 80, 161)',
  textDecoration: 'underline',
  marginBottom: '1rem'
}

const textStyles = {
  color: 'rgb(151, 174, 211)',
  fontSize: '1.6rem',
  fontWeight: '600',
  marginTop: '.2rem'
}

const subTextStyles = {
  fontWeight: '300',
  color: 'black',
  fontSize: '1.2rem',
  marginBottom: '.5rem'
}

const spanStyles = {
  fontWeight: '600',
  fontSize: '1rem',
  marginRight: '.5rem'
}

export default function SummaryForm({
  handleBack,
  handleSubmit,
  success,
  error,
}) {
  const { t } = useTranslation(['location', 'common', 'form']);
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);

  const { state, setState } = useContext(LocationContext)

  let serviceList = state.services;
  let showServices = serviceList.length !== 0;
  let teamMembersList = state.teamMembers;
  let showMembers = teamMembersList.length !== 0;

  const onSubmit = async () => {
    let values = state.address;

    let serviceIds = [];
    serviceList.forEach((item) => serviceIds.push(item.id));
    values.serviceIds = serviceIds;

    let teamMemberIds = [];
    teamMembersList.forEach((item) => teamMemberIds.push(item.id));
    values.teamMemberIds = teamMemberIds;

    const result = await addLocationForProvider(state.providerId, values);
    if (result.success) {
      success();
      handleSubmit();
    } else {
      let errorMessage;
      if (result.statusCode === 422) {
        errorMessage = t('error:defaultMessage');
      } else {
        errorMessage = t(result.errorMessage);
        error();
      }

      setErrorMessage(errorMessage);
    }
  };

  const handleRemoveTeamMember = (value) => {
    setState({
      ...state,
      teamMembers: state.teamMembers.filter((member) => member !== value)
    })
  }

  const handleRemoveService = (value) => {
    setState({
      ...state,
      services: state.services.filter((service) => service !== value)
    })
  }

  const handleRemoveHealthZone = (value) => {
    setState({
      ...state,
      healthZonesServed: state.healthZonesServed.filter((zone) => zone !== value)
    })
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', marginBottom: '4rem', marginTop: '3rem' }}>
        <div style={{ width: '50%', paddingLeft: '4rem', display: 'flex', flexDirection: 'column' }}>
          <InputLabel style={titleStyles}>{t('location:lblLocation')}</InputLabel>

          <Typography style={textStyles}>
            {t('location:lblBranch')}:
          </Typography>
          <p style={subTextStyles}>{state.address.branch}</p>

          <Typography style={textStyles}>
            {t('location:lblAddress1')}:
          </Typography>
          <p style={subTextStyles}>{state.address.address1}</p>
          {state.address.address2 === undefined || state.address.address2 === '' ? (
            null
          ) : (
            <React.Fragment>
              <Typography style={textStyles}>
                {t('location:lblAddress2')}:
              </Typography>
              <p style={subTextStyles}>{state.address.address2}</p>
            </React.Fragment>
          )}

          <Typography style={textStyles}>
            {t('location:lblCity')}:
          </Typography>
          <p style={subTextStyles}>{state.address.city}</p>
          <Typography style={textStyles}>
            {t('location:lblProvince')}:
          </Typography>
          <p style={subTextStyles}>{state.address.province}</p>
          <Typography style={textStyles}>
            {t('location:lblPostalCode')}:
          </Typography>
          <p style={subTextStyles}>{state.address.postalCode}</p>
        </div>
        <div style={{ width: '50%', paddingLeft: '4rem' }}>
          {showServices ? (
            <React.Fragment>
              <InputLabel style={titleStyles}>{t('location:lblServices')}</InputLabel>
              {serviceList.map((entry) => (
                <div key={entry.id}>
                  <Typography style={subTextStyles}>
                    <span
                      style={spanStyles}
                      id='hover-notchecked'
                      onClick={() => handleRemoveService(entry)}> X</span>
                    {' '}{entry.name}
                  </Typography>
                </div>
              ))}
            </React.Fragment>
          ) : null}
          {showMembers ? (
            <React.Fragment>

              <InputLabel
                className='margin-extra'
                style={titleStyles}>{t('location:lblTeamMembers')}</InputLabel>

              {teamMembersList.map((entry) => (
                <div key={entry.id}>
                  <Typography style={subTextStyles}>
                    <span
                      id='hover-notchecked'
                      onClick={() => handleRemoveTeamMember(entry)}
                      style={spanStyles}>X</span>
                    {entry.first_name} {entry.last_name}
                  </Typography>
                </div>
              ))}
            </React.Fragment>
          ) : null}

          {state.healthZonesServed.length > 0 ? (
            <React.Fragment>
              <InputLabel
                className='margin-extra'
                style={titleStyles}>{'Health Zones'}
              </InputLabel>
              {state.healthZonesServed.map((zone, idx) => (
                <div key={idx}>

                  <Typography style={subTextStyles}>
                    <span
                      id='hover-notchecked'
                      onClick={() => handleRemoveHealthZone(zone)}
                      style={spanStyles}>X</span>
                    {zone}
                  </Typography>
                </div>
              ))}
            </React.Fragment>
          ) : null}


        </div>
      </div>
      {errorMessage && (
        <Typography className={classes.error}>{errorMessage}</Typography>
      )}
      <Grid style={moreMarginStyles} container justifyContent='flex-end'>
        <Grid item>
          <Button
            style={{ padding: '.5rem 0' }}
            id='btn-back'
            onClick={handleBack}>{t('common:btnBack')}</Button>
          <Button variant='contained' color='primary' onClick={onSubmit}>
            {t('form:submit')}
          </Button>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

SummaryForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  success: PropTypes.func,
  error: PropTypes.func,
};
