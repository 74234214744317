import React, { useState } from 'react'
import './hoursOfServiceForm.scss';
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";


const serviceDay = ({ day, state, setState, open24 }) => {

  const [value] = useState(['09:00', '17:00']);

  const handleOnChange = (value) => {
    setState({
      ...state,
      hoursOfService: {
        ...state.hoursOfService,
        [day]: value
      }
    })
  }

  const handleDayClick = () => {
    if (open24) {
      return
    }

    if (state.hoursOfService[day] === null) {
      setState({
        ...state,
        hoursOfService: {
          ...state.hoursOfService,
          [day]: value
        }
      });
    } else {
      setState({
        ...state,
        hoursOfService: {
          ...state.hoursOfService,
          [day]: null
        }
      });
    }
  }

  return (
    <div className='ServiceDay fade-in'>
      <h2
        className={state.hoursOfService[day] !== null ? '' : 'inact'}
        onClick={handleDayClick}>
        {day[0] + day[1]
        }
      </h2>
      <div className='ServiceDay__times'>
        {state.hoursOfService[day] !== null ? (
          <React.Fragment>

            <TimeRangePicker
              disabled={open24 ? true : false}
              value={state.hoursOfService[day]}
              onChange={(value) => handleOnChange(value)}
              rangeDivider=""
              disableClock={true}
              clearIcon={null}
            />

          </React.Fragment>
        ) : <div className='ServiceDay__times__na'><p>Not Available</p></div>}

      </div>
    </div>
  )
}

export default serviceDay
