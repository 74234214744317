import React from 'react';

import useStyles from './styles';

export default function MyClientsPage() {
  const classes = useStyles();

  return (
    <form>
      <div className={classes.root}>
        <h2>My Clients</h2>
      </div>
    </form>
  );
}
