import React from 'react';

import Button from '@material-ui/core/Button';

import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';

import useAuthDataContext from '../../../../auth/hooks/useAuthDataContext';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export default function PswOptions() {
  const classes = useStyles();
  const { t } = useTranslation('menuItems');
  const { goToPage } = useAuthDataContext();

  function handleClickDashboard() {
    goToPage('/dashboard');
  }

  function handleClickMyClients() {
    goToPage('/my-clients');
  }

  function handleMessageClick() {
    goToPage('/messages');
  }

  function handleServiceDiscoveryClick() {
    goToPage('/service-discovery');
  }

  return (
    <div className={classes.root}>
      <div hidden>
        <Button
          color='primary'
          className={classes.linkButton}
          startIcon={<HomeRoundedIcon />}
          onClick={handleClickDashboard}
        >
          {t('menuItems:lblHome')}
        </Button>
      </div>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<PersonOutlineRoundedIcon />}
        onClick={handleClickMyClients}
      >
        {t('menuItems:lblMyClients')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<MessageRoundedIcon />}
        onClick={handleMessageClick}
      >
        {t('menuItems:lblMessages')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<ExploreRoundedIcon />}
        onClick={handleServiceDiscoveryClick}
      >
        {t('menuItems:lblDiscover')}
      </Button>
    </div>
  );
}
