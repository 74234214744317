import React from 'react'

const profileSubHeaderImage = () => {
    return (
        <div>
            <img
                style={{ objectFit: 'none' }}
                height='200' width='100%' src={'https://wallpaperaccess.com/full/112906.jpg'} alt={'Logo'} />
        </div>
    );
}

export default profileSubHeaderImage
