import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PhoneNumberMask from '../../common/form/phone-number-mask';
import SuspenseView from '../../suspense-view';
import { updateUserProfileDetails } from '../../../api/user';
import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { moreMarginStyles, buttonStyles, dialogContentStyles, fieldTitleStyles } from '../customStyles/customStyles';

export default function EditProfileDialog({ show, handleClose, handleToastSuccess, handleToastFail }) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const { t, ready } = useTranslation(['common', 'error', 'form']);
  const classes = useStyles();

  const { userDetails, setUserDetailsState } = useAuthDataContext();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmCancelModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmCancelModel = () => {
    setOpenCancel(false);
  };

  const requiredTranslated = t('form:required');
  const EditProfileSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
    first_name: Yup.string().required(requiredTranslated),
    last_name: Yup.string().required(requiredTranslated),
    contact_number: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[ ]([0-9]{3})[-]([0-9]{4})$/,
        t('form:invalidPhoneNumber')
      )
      .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    if (values && !values.isCancelButton) {
      const result = await updateUserProfileDetails(userDetails.id, values);
      if (result.success) {
        let updatedUserDetails = {
          success: true,
          data: values,
        };
        handleToastSuccess();
        await setUserDetailsState(updatedUserDetails);
        handleClose();
      } else {
        let errorMessage = t(result.errorMessage);
        actions.setStatus({ msg: errorMessage });
        handleToastFail()
      }
    } else {
      if (values.isDirty) {
        openConfirmCancelModel();
      } else {
        handleClose();
      }
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }


  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='update-user-profile-dialog-title'
      >
        <DialogTitle className='Stepper__title' id='update-user-profile-dialog-title'>
          {t('common:lblEditProfile')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={userDetails}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            validationSchema={EditProfileSchema}
          >
            {({
              errors,
              touched,
              values,
              status,
              handleChange,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <h2 style={fieldTitleStyles}>{t('common:lblEmail')}</h2>
                <Field
                  disabled
                  className={classes.textField}
                  type='text'
                  id='email'
                  name='email'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.email}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.email && touched.email ? (
                  <div className={classes.error}>{errors.email}</div>
                ) : null}

                <h2 style={fieldTitleStyles}>{t('common:lblFirstName')}</h2>
                <Field
                  className={classes.textField}
                  type='text'
                  name='first_name'
                  id='first_name'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.first_name}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.first_name && touched.first_name ? (
                  <div className={classes.error}>{errors.first_name}</div>
                ) : null}
                <h2 style={fieldTitleStyles}>{t('common:lblLastName')}</h2>
                <Field
                  className={classes.textField}
                  type='text'
                  name='last_name'
                  id='last_name'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.last_name}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.last_name && touched.last_name ? (
                  <div className={classes.error}>{errors.last_name}</div>
                ) : null}
                <h2 style={fieldTitleStyles}>{t('common:lblPhoneNumber')}</h2>
                <Field
                  className={classes.textField}
                  type='text'
                  id='contact_number'
                  name='contact_number'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={'' || values.contact_number}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: PhoneNumberMask }}
                />
                {errors.contact_number && touched.contact_number ? (
                  <div className={classes.error}>{errors.contact_number}</div>
                ) : null}

                {status && status.msg && (
                  <Typography className={classes.error}>
                    {status.msg}
                  </Typography>
                )}

                <Grid style={moreMarginStyles} container justifyContent='flex-end'>
                  <Button
                    id='btn-back'
                    variant='text'
                    color='inherit'
                    onClick={async () => {
                      values = {
                        isCancelButton: true,
                        isDirty: dirty,
                      };
                      onSubmit(values, {});
                    }}
                    aria-label='Cancel'
                  >
                    {t('common:btnCancel')}
                  </Button>
                  <Dialog
                    open={openCancel}
                    onClose={closeConfirmCancelModel}
                    aria-labelledby='form-dialog-title'
                  >
                    <DialogContent style={dialogContentStyles}>{t('form:discardChanges')}</DialogContent>
                    <DialogActions>
                      <Button
                        style={buttonStyles}
                        type='submit'
                        onClick={async () => {
                          closeConfirmCancelModel();
                          handleClose();
                        }}
                      >
                        {t('common:yes')}
                      </Button>
                      <Button
                        style={buttonStyles}
                        onClick={closeConfirmCancelModel} autoFocus>
                        {t('common:no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    id='btn-hover-fx'
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={!dirty || isSubmitting}
                  >
                    {t('common:btnSubmit')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

EditProfileDialog.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handleToastSuccess: PropTypes.func,
  handleToastFail: PropTypes.func,
};
