import React from 'react'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import PropTypes from 'prop-types';
import './revealPassword.scss';


const RevealPassword = ({ passwordVis, setPasswordVis, transform }) => {

    const iconStyles = {
        fontSize: '2.5rem',
        transform: transform
    }

    const handleClick = () => {
        setPasswordVis(!passwordVis)
    }

    return (
        <React.Fragment>
            {passwordVis ? (
                <AiFillEyeInvisible
                    style={iconStyles}
                    className='RevealPassword-icon'
                    onClick={handleClick}
                />
            ) : (
                <AiFillEye
                    style={iconStyles}
                    className='RevealPassword-icon'
                    onClick={handleClick}
                />
            )}

        </React.Fragment>
    )
}

RevealPassword.propTypes = {
    passwordVis: PropTypes.bool,
    setPasswordVis: PropTypes.func,
    transform: PropTypes.string,

}

export default RevealPassword
