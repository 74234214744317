import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IoIosArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

// import formClasses from '../../components/common/form/form.module.scss';
// import classNames from 'classnames';

import TextField from '../../components/common/form/texField/textField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import useStyles from './styles';

const btnStyles = {
  boxShadow: 'none',
  background: 'rgb(26, 80, 161)',
  color: 'white',
  padding: '.8rem 1.3rem'
}

export default function ForgotPasswordForm({ t }) {
  const classes = useStyles();
  const { goToPage, resetPassword } = useAuthDataContext();

  const initialValues = { email: '' };

  const requiredTranslated = t('form:required');
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    const { success, errorMessage } = await resetPassword(values.email);
    if (success) {
      goToPage('/forgot-password/?success=true');
    } else {
      actions.setStatus({ msg: t(errorMessage) });
    }
  };

  return (
    <div className='SignInPage-Container'>
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, isValid }) => (
          <Form
            className='SignInPage-Container__form'
          >
            <div className='SignInPage-Container__form__title'>
              {t('authentication:resetPassword')}
            </div>
            <Field
              name='email'
              required
              component={TextField}
              inputPlaceholder={t('authentication:phEnterEmail')}
              placeholder={t('authentication:email')}
            />

            {status && status.msg && (
              <Typography className={classes.error}>{status.msg}</Typography>
            )}

            <Button
              style={btnStyles}
              id='btn-back'
              variant='contained'
              color='secondary'
              type='submit'
              disabled={!isValid || isSubmitting}
            >
              {t('form:submit')}
            </Button>
            <div style={{ width: '100%', textAlign: 'start' }}>
              <Link to='/signin'>
                <IoIosArrowBack className='SignInPage-Container__btn-back' />
              </Link>
              <p style={{ color: 'rgb(26, 80, 161)', fontWeight: '600' }}>Back to signin</p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  t: PropTypes.func,
};
