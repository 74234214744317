import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import { useContext } from 'react';
import { ServiceContext } from '../serviceContext';

import * as Yup from 'yup';

import { Formik, Form } from 'formik';
// import { TextField as FormikTextField } from 'formik-material-ui';

import LocationListItemView from './location-list-item-view';
import SuspenseView from '../../../suspense-view';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import '../stepperStyles.scss';

import { getProviderLocationList } from '../../../../api/provider-location';

import { fieldTitleStylesLrg } from '../../customStyles/customStyles';

// Get the services list
const useFetch = (providerId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      const { success, data, errorMessage } = await getProviderLocationList(
        providerId
      );
      if (success) {
        await setData(data);
      } else {
        await setError(errorMessage);
      }
    };

    fetchData();
  }, [providerId]);

  return { data, error };
};

export default function LocationsForm({ handleNext, handleBack }) {

  const { state, setState } = useContext(ServiceContext)
  const { t, ready } = useTranslation(['common', 'service']);
  const classes = useStyles();


  const initialValues = {
    name: state.service ? state.service.name : '',
    summary: state.service ? state.service.summary : '',
    // files: state.service ? state.service.files : '',
    serviceCategory: state.service ? state.service.serviceCategory : '',
    serviceCategorySecondary: state.service ? state.service.serviceCategorySecondary : '',
    educationalResources: state.service ? state.service.educationalResources : '',
    eligibilityCriteria: state.service ? state.service.eligibilityCriteria : '',
    geographicalAreaServed: '',
    insurableDetails: state.service ? state.service.insurableDetails : '',
    insurableService: state.service ? state.service.insurableDetails : ''
  };


  // const initialValues = {
  //   geographicalAreaServed: serviceState.geographicalAreaServed
  //     ? serviceState.geographicalAreaServed
  //     : '',
  // };

  const requiredTranslated = t('form:required');

  let validationSchema = Yup.object().shape({
    geographicalAreaServed: Yup.string().required(requiredTranslated),
  });

  const handleToggle = (value) => () => {
    if (state.locations.some((location) => location.id === value.id)) {
      setState({
        ...state,
        locations: state.locations.filter((location) => location.id !== value.id)
      })
    } else {
      setState({
        ...state,
        locations: [...state.locations, value]
      })
    }

  };

  const onSubmit = () => {
    handleNext();
  };

  const response = useFetch(state.providerId);

  if (!response.data) {
    if (response.error) {
      return (
        <form>
          <div>{t('common:lblLoading')}</div>
        </form>
      );
    }

    return <SuspenseView />;
  }

  const responseData = response.data;

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <h2 style={fieldTitleStylesLrg}>{t('service:lblSelectLocations')} ({(state.locations.length)})</h2>
            <List className={classes.root}>
              {responseData.map((entry) => (
                <LocationListItemView
                  key={entry.id}
                  location={entry}
                  handleToggle={handleToggle}
                  state={state}
                />
              ))}
            </List>
            <br />
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Button
                  id='btn-back'
                  style={{ padding: '.6rem 0' }}
                  onClick={handleBack}>{t('common:btnBack')}
                </Button>
                <Button
                  id='btn-hover-fx'
                  disabled={!isValid || isSubmitting}
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={handleNext}
                >
                  {t('common:btnNext')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

LocationsForm.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func
};
