import React from 'react';
import PropTypes from 'prop-types';

// TODO: Do we need to bring in all languages?
//import moment from 'moment/min/moment-with-locales';
import moment from 'moment';
import 'moment/locale/fr';
import { getCurrentLanguage } from '../../../i18n/i18n';
import { getDateFormatStringForLocale } from '../../../utils/dateUtils';
import Typography from '@material-ui/core/Typography';

import ClientOptions from './sub-header-options/clientOptions';
import PswOptions from './sub-header-options/pswOptions';
import ProviderAdminOptions from './sub-header-options/providerAdminOptions';

import { getUserRole } from '../../../utils/userUtils';
import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';

import { useStyles } from './styles';
import { useMediaQuery } from '../../pages/company-details/mediaQueries';

import {
  VALUE_ROLE_NAME_ADMIN,
  VALUE_ROLE_NAME_STAFF,
  VALUE_ROLE_NAME_SUPPORT,
} from '../../common/constants';

const ShowSubHeaderOptions = ({ userDetails }) => {
  if (!userDetails) {
    return null;
  }

  let userType = getUserRole(userDetails);

  if (
    userType === VALUE_ROLE_NAME_ADMIN ||
    userType === VALUE_ROLE_NAME_STAFF
  ) {
    return <ProviderAdminOptions />;
  }

  if (userType === VALUE_ROLE_NAME_SUPPORT) {
    return <PswOptions />;
  }

  return <ClientOptions />;
};

ShowSubHeaderOptions.propTypes = {
  userDetails: PropTypes.object,
};

const ShowCurrentDate = () => {
  const currentDate = moment();
  let locale = getCurrentLanguage();
  currentDate.locale(locale);
  let dateFormat = getDateFormatStringForLocale(locale);

  return <Typography style={{ fontFamily: 'Poppins, sans-serif' }}>{currentDate.format(dateFormat)}</Typography>;
};

function SubHeader() {

  const isMedia = useMediaQuery("(max-width: 900px)");
  const classes = useStyles();
  const { userDetails } = useAuthDataContext();

  return (

    <div className={classes.root} id='subheader'>
      <div>
        <ShowSubHeaderOptions userDetails={userDetails} />
      </div>
      <div className={classes.inputCenter}>
        {isMedia ? null : (
          <ShowCurrentDate classes={classes} />
        )}
      </div>
    </div>
  );
}

export default SubHeader;
