import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import formClasses from '../../components/common/form/form.module.scss';
import classNames from 'classnames';

import TextField from '../../components/common/form/texField/textField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SuspenseView from '../../components/suspense-view';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAuthDataContext from '../../auth/hooks/useAuthDataContext';
import useStyles from './styles';

export default function ResetPasswordPage() {
  const classes = useStyles();
  const { confirmResetPassword, goToPage } = useAuthDataContext();
  const { t, ready } = useTranslation([
    'authentication',
    'common',
    'error',
    'form',
  ]);

  const params = useParams();
  const email = params.confirmEmail;

  const initialValues = {
    email: email,
    code: '',
    password: '',
    confirmPassword: '',
  };
  const requiredTranslated = t('form:required');

  const ConfirmResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required(requiredTranslated),
    code: Yup.string().required(requiredTranslated),
    password: Yup.string()
      .required(requiredTranslated)
      .min(8, t('form:minimumLength', { value: 8 })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('form:passwordsDoNotMatch'))
      .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    const { success, errorMessage } = await confirmResetPassword(
      values.email,
      values.code,
      values.password
    );
    if (success) {
      goToPage('/signin');
    } else {
      actions.setStatus({ msg: t(errorMessage) });
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={formClasses.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={ConfirmResetPasswordSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, isValid }) => (
          <Form
            className={classNames(formClasses.paper, formClasses.paperSmall)}
          >
            <div className={formClasses.title}>
              {t('authentication:resetPassword')}
            </div>
            <Field
              name='email'
              type='email'
              required
              component={TextField}
              label={t('authentication:email')}
              disabled={true}
              disableUnderline={true}
            />
            <Field
              name='code'
              required
              component={TextField}
              placeholder={t('authentication:confirmationCode')}
            />
            <Field
              name='password'
              required
              component={TextField}
              placeholder={t('authentication:password')}
              type='password'
            />
            <Field
              name='confirmPassword'
              component={TextField}
              placeholder={t('authentication:confirmPassword')}
              type='password'
            />

            {status && status.msg && (
              <Typography className={classes.error}>{status.msg}</Typography>
            )}

            <Button
              className={classes.submitButton}
              variant='contained'
              color='secondary'
              type='submit'
              disabled={!isValid || isSubmitting}
            >
              {t('form:submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

ResetPasswordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      confirmEmail: PropTypes.string,
    }),
  }),
};
