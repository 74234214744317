import React, { useContext } from 'react'
import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import SelectInput from '../../../../common/select/selectInput';
import { ServiceContext } from '../../serviceContext';
import PropTypes from 'prop-types';
import HoursOfServiceForm from '../../hoursOfServiceForm/hoursOfServiceForm';
import * as options from './options';



const MentalHealthForm = ({ handleBack, handleNext }) => {

  const { state, setState } = useContext(ServiceContext)

  let serviceState = state.service;

  const initialValues = {
    freeConsult: serviceState.freeConsult
      ? serviceState.freeConsult : '',
    ageSpeciality: serviceState.ageSpeciality
      ? serviceState.ageSpeciality : [],
    areasOfPractice: serviceState.areasOfPractice
      ? serviceState.areasOfPractice : [],
    yearsInPractice: serviceState.yearsInPractice
      ? serviceState.yearsInPractice : '',
    hoursOfService: serviceState.hoursOfService
      ? serviceState.hoursOfService : 'TBD',
  };

  const onSubmit = (values) => {

    // Set the service values from the form
    setState({
      ...state,
      service: {
        ...state.service,
        freeConsult: values.freeConsult,
        ageSpeciality: values.ageSpeciality,
        areasOfPractice: values.areasOfPractice,
        yearsInPractice: values.yearsInPractice,
      }
    });

    handleNext();
  };


  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <h2 className='title-small margin-remove'>Free Consult</h2>
            <Field
              id='additional-field-styles'
              component={SelectInput}
              type='select'
              name='freeConsult'
              margin='normal'
              variant='outlined'
              isMulti={false}
              placeholder={'Do you offer a free consultation?'}
              options={options.freeConsult}
              formName={'freeConsult'}
            />
            <h2 className='title-small margin-remove margin-top'>Age Speciality</h2>
            <Field
              id='additional-field-styles'
              component={SelectInput}
              type='select'
              name='ageSpeciality'
              margin='normal'
              variant='outlined'
              isMulti={true}
              placeholder={'Select your age specialities'}
              options={options.ageSpeciality}
              formName={'ageSpeciality'}

            />
            <h2 className='title-small margin-remove margin-top'>Areas of Practice</h2>
            <Field
              id='additional-field-styles'
              component={SelectInput}
              type='select'
              name='areasOfPractice'
              margin='normal'
              variant='outlined'
              isMulti={true}
              placeholder={'Select your areas of practice'}
              options={options.areasOfPractice}
              formName={'areasOfPractice'}
            />
            <h2 className='title-small margin-top'>Years in Practice</h2>
            <p
              style={{ color: 'gray', fontWeight: '600', fontSize: '1.2rem' }}
              className='margin-remove'>* Enter the year you began your practice
            </p>

            <Field
              style={{ width: '100%' }}
              id='additional-field-styles'
              component={FormikTextField}
              type='number'
              name='yearsInPractice'
              margin='normal'
              variant='outlined'
              placeholder={'Enter a year...'}
            />
            <h2 className='title-small margin-top'>Hours of Service</h2>
            <HoursOfServiceForm />
            <div
              className='margin-top'
              style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className='btn-blue invert-btn-colors margin-right'
                onClick={handleBack}
              >
                Back
              </button>
              <button
                type='submit'
                className='btn-blue'
                disabled={!isValid || isSubmitting}
              >
                Next
              </button>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  )
}

MentalHealthForm.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default MentalHealthForm
