import React, { useState, useMemo, useEffect } from "react";
import { ServiceContext } from "../serviceContext";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { StyleRoot } from "radium";
import { animations } from "../../../animations/animations";
import PropTypes from 'prop-types';
import Select from "react-select";
import * as options from "./serviceOptions";
import { inputStyles, inputStylesErr } from '../../../common/select/styles';
import AddServiceForm from "../addServiceForm";
import './serviceTypeChecker.scss';

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid rgb(234, 239, 247)",
  borderRadius: "20px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "row",
  p: 4,
};

const errorStyles = {
  color: 'red',
  fontSize: '1rem',
  marginTop: '.5rem'
}

const ServiceTypeSelect = ({ open, handleClose, providerId, error, success, setLastUpdated }) => {

  const [showServiceDialog, setShowServiceDialog] = useState(false);

  const openAddServiceDialog = () => {
    setShowServiceDialog(true);
  };


  const closeAddServiceDialog = () => {
    setShowServiceDialog(false);
  };

  //custom errors for service categories 
  const [categoryErrors, setCategoryErrors] = useState({
    primary: '',
    secondary: ''
  })

  useEffect(() => {
    if (handleClose) {
      resetErrors()
    }
  }, [handleClose])

  const resetErrors = () => setCategoryErrors({ primary: '', secondary: '' })

  const initialState = {
    // Initialize the data from each step
    providerId: providerId,

    service: {},
    locations: [],
    hoursOfService: {
      Monday: ["09:00", "17:00"],
      Tuesday: ["09:00", "17:00"],
      Wednesday: ["09:00", "17:00"],
      Thursday: ["09:00", "17:00"],
      Friday: ["09:00", "17:00"],
      Saturday: null,
      Sunday: null,
    }
  };

  const [state, setState] = useState(initialState);


  const selectOptions = useMemo(() => {
    if (state.service.serviceCategory === "Healthcare") {
      return options.healthCareOptions;
    } else if (state.service.serviceCategory === "Mental Health") {
      return options.mentalHealthOptions;
    } else if (state.service.serviceCategory === "Recreational Activities") {
      return options.recActivities;
    } else if (state.service.serviceCategory === "Transportation") {
      return options.transportationOptions;
    } else if (state.service.serviceCategory === "In Home Support") {
      return options.inHomeSupportOptions;
    } else if (state.service.serviceCategory === "Housing") {
      return options.housingOptions;
    } else if (state.service.serviceCategory === "Financial") {
      return options.financialOptions;
    } else if (state.service.serviceCategory === "Legal") {
      return options.legalOptions;
    } else if (state.service.serviceCategory === "End of Life Care") {
      return options.endOfLifeOptions;
    } else if (state.service.serviceCategory === "Resources for Caregivers") {
      return options.resourcesCaregiversOptions;
    } else {
      return []
    }
  }, [state.service.serviceCategory]);

  const handlePrimaryValueChange = (value) => {
    if (value !== null && state.service.serviceCategorySecondary === "") {
      setState({
        ...state,
        service: {
          ...state.service,
          serviceCategory: value.value
        }
      })
    } else if (value !== null && state.service.serviceCategorySecondary !== "") {
      setState({
        ...state,
        service: {
          ...state.service,
          serviceCategory: value.value,
          serviceCategorySecondary: ''
        }
      })
    } else {
      setState({
        ...state,
        service: {
          ...state.service,
          serviceCategory: '',
          serviceCategorySecondary: ''
        }
      })
    }
  };

  const handleSecondaryValueChange = (value) => {
    value !== null
      ?
      setState({
        ...state,
        service: {
          ...state.service,
          serviceCategorySecondary: value.value
        }
      })
      : setState({
        ...state,
        service: {
          ...state.service,
          serviceCategorySecondary: ''
        }
      })
  };

  const setSelectValue = (options, value) => {
    if (value === "") {
      return "";
    } else {
      return options.find((option) => option.value === value);

    }
  };

  const handleShowWizard = () => {
    if (!state.service.serviceCategory || state.serviceCategory === '') {
      setCategoryErrors({
        ...categoryErrors,
        primary: 'Service Category is a required field*'
      })
    } else if (!state.service.serviceCategorySecondary || state.serviceCategorySecondary === '') {
      setCategoryErrors({
        primary: '',
        secondary: 'Secondary Service Category is a required field*'
      })
    } else {
      resetErrors();
      openAddServiceDialog();
    }
  }

  return (
    <React.Fragment>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <StyleRoot>
                <ServiceContext.Provider value={{ state, setState }}>
                  <div className='ServiceTypeChecker'>
                    <h1 className='title-lrg' style={animations.bounceInUp}>What is your service?</h1>
                    <p style={animations.bounceInLeft}>
                      {" "}
                      * Please select the most appropriate service categories.
                    </p>
                    <div
                      className='margin-bottom-extra'
                      style={animations.bounceInLeft}
                    >
                      <h3 className='title-med margin-remove'>Primary:</h3>
                      <Select
                        styles={categoryErrors.primary === '' ? inputStyles : inputStylesErr}
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Select or search categories"
                        options={options.primaryOptions}
                        value={setSelectValue(options.primaryOptions, state.service.serviceCategory)}
                        onChange={(value) => handlePrimaryValueChange(value)}
                      />
                      <p style={errorStyles}>{categoryErrors.primary}</p>
                      <div>
                        <h3 className='title-med margin-remove'>Secondary:</h3>
                        <Select
                          styles={categoryErrors.secondary === '' ? inputStyles : inputStylesErr}
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          isDisabled={state.service.serviceCategory === '' || !state.service.serviceCategory ? true : false}
                          placeholder={state.service.serviceCategory === '' || !state.service.serviceCategory ? "Select or search sub-categories" : `Select or search from: ${state.service.serviceCategory} subcategories`}
                          options={selectOptions}
                          value={setSelectValue(selectOptions, state.service.serviceCategorySecondary)}
                          onChange={(value) => handleSecondaryValueChange(value)}
                        />
                        <p style={errorStyles}>{categoryErrors.secondary}</p>
                      </div>

                    </div>
                    <button
                      className='btn-blue invert-btn-colors'
                      style={animations.tada}
                      onClick={handleShowWizard}
                    >
                      Next
                    </button>
                  </div>
                  <div hidden>
                    <AddServiceForm
                      show={showServiceDialog}
                      handleClose={closeAddServiceDialog}
                      handleCloseModal={handleClose}
                      providerId={providerId}
                      setLastUpdated={setLastUpdated}
                      success={success}
                      error={error}
                    />
                  </div>
                </ServiceContext.Provider>
              </StyleRoot>
            </Box>
          </Fade>
        </Modal>
      </div>
    </React.Fragment>
  );
};

ServiceTypeSelect.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  providerId: PropTypes.number,
  error: PropTypes.func,
  success: PropTypes.func,
  setLastUpdated: PropTypes.func
};


export default ServiceTypeSelect;
