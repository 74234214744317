import React from 'react';
import SubHeaderImage from './sub-header-image/subheaderimage';
import NavigationTabs from './navigation-tabs/navigationTabs';

import DetailsPanel from './details-panel';
import './companyDetailsPage.scss';

import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import { getProviderId } from '../../../utils/userUtils';

import { useMediaQuery } from './mediaQueries'

export default function CompanyDetailsPage() {
  const { userDetails } = useAuthDataContext();
  const providerId = getProviderId(userDetails);

  const isMedia = useMediaQuery("(max-width: 900px)");

  return (
    <div className="CompanyDetailsPage">

      <div className="CompanyDetailsPage__col-1">
        <SubHeaderImage />
      </div>

      {isMedia ? (
        <div className="CompanyDetailsPage__tablet-container">
          <div className="CompanyDetailsPage__tablet-container__col-2">
            <DetailsPanel providerId={providerId} />
          </div>

          <div className="CompanyDetailsPage__tablet-container__col-3">
            <NavigationTabs providerId={providerId} />
          </div>
        </div>
      ) : (
        <div className="CompanyDetailsPage__container-for-col-2-and-3">
          <div className="CompanyDetailsPage__container-for-col-2-and-3__col-2">
            <DetailsPanel providerId={providerId} />
          </div>

          <div className="CompanyDetailsPage__container-for-col-2-and-3__col-3">
            <NavigationTabs providerId={providerId} />
          </div>
        </div>
      )}

    </div>

  );
}
