import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '80vw',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '5rem',
  },
  rootMobile: {
    width: '90vw',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
    flexWrap: 'wrap'
  },
  list: {
    width: '100%'
  },
}));

export default useStyles;
