import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';


const discardChangesStyles = {
  fontSize: '1.5rem',
  fontWeight: '600',
  color: 'rgb(26, 80, 161)',
  marginBottom: '1rem'

}

const discardLabelStyles = {
  fontSize: '1.2rem',
  color: 'rgb(26, 80, 161)',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: '600',
  textTransform: 'capitalize'
}

/**
 * Customized dialog title
 */
const DialogTitle = ({ children, onClose, ...other }) => {
  const { t } = useTranslation(['common', 'form']);
  const classes = useStyles();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmModel = () => {
    setOpenCancel(false);
  };

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <div>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={openConfirmModel}
          >
            <CloseIcon />
          </IconButton>
          <Dialog
            open={openCancel}
            onClose={closeConfirmModel}
            aria-labelledby='form-dialog-title'
          >
            <DialogContent style={discardChangesStyles}>{t('form:discardChanges')}</DialogContent>
            <DialogActions>
              <Button type='submit' onClick={onClose} style={discardLabelStyles}>
                {t('common:yes')}
              </Button>
              <Button onClick={closeConfirmModel} autoFocus style={discardLabelStyles}>
                {t('common:no')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default DialogTitle;
