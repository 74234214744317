import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip'
import { Avatar } from '@material-ui/core';

import { tooltipClasses } from '@mui/material';
import '../../locationFormPopup.scss';

import { useContext } from 'react';
import { LocationContext } from '../../locationContext';

const divStylesChecked = {
  margin: '1rem',
  padding: '.8rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'auto',
  borderRadius: '35px',
  alignItems: 'center',
  opacity: '1',
  color: 'white',
  backgroundColor: 'rgb(26, 80, 161)',
  fontSize: '1.3rem'
}

const divStyles = {
  margin: '1rem',
  padding: '1rem 3rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 'auto',
  borderRadius: '35px',
  opacity: '.7',
  color: 'rgb(26, 80, 161)',
  backgroundColor: 'rgb(234, 239, 247)',
  fontSize: '1.3rem'
}

const spanStyles = {
  marginRight: '1rem',
  fontSize: '1.8rem'
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    width: '20 rem',
    fontSize: '1rem',
    border: '1px solid rgb(234, 239, 247)',
    borderRadius: '15px',
    padding: '.5rem 2rem',
    fontFamily: 'Poppins, sans-serif'
  },
}));


export default function TeamMemberListItemView({
  details,
  handleToggle,

}) {

  const { state } = useContext(LocationContext)
  // Set the local attributes
  let firstName = details.first_name;
  let lastName = details.last_name;
  let fullName = firstName + ' ' + lastName;

  const { user_type, email, contact_number } = details;

  const avatarInitials = firstName[0] + lastName[0]

  return (
    <React.Fragment>

      <HtmlTooltip
        enterNextDelay={500}
        title={
          <div className='LocationFormPopup'>
            <Avatar>{avatarInitials}</Avatar>
            <h3>{fullName}</h3>
            <span style={user_type === 'ADMIN' ? { backgroundColor: 'green' } : { backgroundColor: 'orange' }}>{user_type}</span>
            <p>{email}</p>
            <p>{contact_number}</p>
          </div>
        }
      >

        <div
          id='hover-notchecked'
          style={state.teamMembers.some(member => member.id === details.id) ? divStylesChecked : divStyles}
          onClick={handleToggle(details)}>
          <h3>{state.teamMembers.some(member => member.id === details.id) ? <span style={spanStyles}>&#10003;</span> : null}{fullName}</h3>
        </div>

      </HtmlTooltip>
    </React.Fragment>
  );
}

TeamMemberListItemView.propTypes = {
  details: PropTypes.object,
  handleToggle: PropTypes.func,
};
