import { awsApiRequest } from '../utils/func';
import { unmaskPhoneNumber } from '../../utils/numberUtils';

export async function getProviderDetails(providerId) {
  try {
    return await awsApiRequest({
      method: 'GET',
      path: `/providers/${providerId}`,
    });
  } catch (error) {
    return error;
  }
}

export async function editProviderDetails(providerId, values) {
  try {
    const body = {
      name: values.name,
      description: values.description,
      website: values.website,
      contact_email: values.contact_email,
      contact_number: unmaskPhoneNumber(values.contact_number),
    };

    return await awsApiRequest({
      method: 'PUT',
      path: `/providers/${providerId}`,
      params: {
        body: body,
      },
    });
  } catch (error) {
    return error;
  }
}
