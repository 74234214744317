import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    background: 'rgb(234, 239, 247)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputCenter: {
    height: 50,
    margin: theme.spacing(0, 3, 0, 0.5),
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
