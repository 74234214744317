import { makeStyles } from '@material-ui/core/styles';
const poppinsFont = 'Poppins, sans-serif'

const useStyles = makeStyles((theme) => ({
  locationsLabel: {
    margin: theme.spacing(0, 0.5, 0, 2),
    float: 'left',
  },
  buttonDiv: {
    //margin: theme.spacing(0, 3, 0, 0.5),
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  addButton: {
    textTransform: 'none',
  },
  paginator: {
    justifyContent: 'center',
    marginBottom: '2rem',
    width: '100%',
  },
  paginatorMedia: {
    justifyContent: 'center',
    marginBottom: '2rem',
    width: '100vw',
  }
}));

export const typographyStyles = {
  fontSize: '1.5rem',
  color: 'rgb(26, 80, 161)',
  fontWeight: '600',
  fontFamily: poppinsFont
}

export const divStyles = {
  width: '83%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
}

export const divStylesMedia = {
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
}

export default useStyles;
