import React from 'react';

import useAuthDataContext from '../hooks/useAuthDataContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
    const { isSignedIn } = useAuthDataContext();
    const location = useLocation();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isSignedIn ? <Outlet /> : <Navigate location={location} to={{
                    pathname: '/signin',
                    state: { from: location },
                    }} 
    />;
}

export default PrivateRoute;
