import React from 'react'
import './mobileHeader.scss'
import PropTypes from 'prop-types';

import { getUserRole } from '../../../utils/userUtils';
import ProviderAdminOptions from './mobileHeaderOptions/providerAdminOptions';
import ClientOptions from './mobileHeaderOptions/clientOptions';
import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import SignIn from './mobileHeaderOptions/signIn';

import {
  VALUE_ROLE_NAME_ADMIN,
  VALUE_ROLE_NAME_STAFF,
  VALUE_ROLE_NAME_SUPPORT,
} from '../../common/constants';


const ShowSubHeaderOptions = ({ userDetails }) => {
  if (!userDetails) {
    return null;
  }

  let userType = getUserRole(userDetails);

  if (
    userType === VALUE_ROLE_NAME_ADMIN ||
    userType === VALUE_ROLE_NAME_STAFF
  ) {
    return <ProviderAdminOptions />;
  }

  if (userType === VALUE_ROLE_NAME_SUPPORT) {
    return null //TODO: ADD PSW OPTIONS;
  }

  return <ClientOptions />;
};

ShowSubHeaderOptions.propTypes = {
  userDetails: PropTypes.object,
};


const MobileHeader = () => {
  const { userDetails } = useAuthDataContext();
  // hides or reveals mobile nav content when clicked on hamburger icon //


  return (
    <React.Fragment>
      {!userDetails ? (
        <SignIn />
      ) : null}

      <ShowSubHeaderOptions userDetails={userDetails} />
    </React.Fragment>
  )
}

MobileHeader.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func,
  userDetails: PropTypes.object,
};


export default MobileHeader;