import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Toast from '../../../components/common/snackbar/toast';

import SuspenseView from '../../suspense-view';
import { moreMarginStyles, fieldTitleStyles } from '../customStyles/customStyles';

import { editProviderService } from '../../../api/provider-services';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../utils/toastUtils';

import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export default function EditServiceDialog({
  show,
  handleClose,
  details,
  setLastUpdated,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const { t, ready } = useTranslation(['common', 'service', 'error']);
  const classes = useStyles();

  /**
   * Methods for handling the confirmation dialog
   */
  const [openCancel, setOpenCancel] = useState(false);
  const openConfirmCancelModel = () => {
    setOpenCancel(true);
  };

  const closeConfirmCancelModel = () => {
    setOpenCancel(false);
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:msgSuccessServiceAdded')
  );
  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:msgServiceUpdateError')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  const requiredTranslated = t('form:required');
  const EditServiceSchema = Yup.object().shape({
    name: Yup.string().required(requiredTranslated),
    summary: Yup.string().required(requiredTranslated),
    hours_of_service: Yup.string().required(requiredTranslated),
    eligibility_criteria: Yup.string().required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    if (values && !values.isCancelButton) {
      const result = await editProviderService(
        details.provider_id,
        details.id,
        values
      );
      if (result.success) {
        handleSuccess();
        handleClose();
        setLastUpdated();
      } else {
        let errorMessage;
        if (result.statusCode === 400) {
          errorMessage = t('error:serverDefaultError', {
            name: values.name,
          });
        } else {
          errorMessage = t(result.errorMessage);
          handleError();
        }

        actions.setStatus({ msg: errorMessage });
      }
    } else {
      if (values.isDirty) {
        openConfirmCancelModel();
      } else {
        handleClose();
      }
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='add-service-dialog-title'
      >
        <DialogTitle className='Stepper__title' id='add-service-dialog-title'>
          {t('provider:lblEditDetails')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={details}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            validationSchema={EditServiceSchema}
          >
            {({
              errors,
              touched,
              values,
              status,
              handleChange,
              isSubmitting,
              isValid,
            }) => (
              <Form>
                <h3 style={fieldTitleStyles}>{t('service:lblServiceName')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='name'
                  id='name'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={values.name}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.name && touched.name ? <div>{errors.name}</div> : null}
                <h3 style={fieldTitleStyles}>{t('service:lblSummary')}</h3>
                <Field
                  multiline
                  rows={3}
                  className={classes.textField}
                  type='text'
                  name='summary'
                  id='summary'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={values.summary}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.summary && touched.summary ? (
                  <div>{errors.summary}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('service:lblInsurableDetails')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='insurable_details'
                  id='insurable_details'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={values.insurable_details}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.insurable_details && touched.insurable_details ? (
                  <div>{errors.insurable_details}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('service:lblHoursOfService')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='hours_of_service'
                  id='hours_of_service'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={values.hours_of_service}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.hours_of_service && touched.hours_of_service ? (
                  <div>{errors.hours_of_service}</div>
                ) : null}
                <h3 style={fieldTitleStyles}>{t('service:lblEligibilityCriteria')}</h3>
                <Field
                  className={classes.textField}
                  type='text'
                  name='eligibility_criteria'
                  id='eligibility_criteria'
                  margin='normal'
                  variant='outlined'
                  component={TextField}
                  value={values.eligibility_criteria}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.eligibility_criteria && touched.eligibility_criteria ? (
                  <div>{errors.eligibility_criteria}</div>
                ) : null}
                {status && status.msg && (
                  <Typography className={classes.error}>
                    {status.msg}
                  </Typography>
                )}

                <Grid style={moreMarginStyles} container justify='flex-end'>
                  <Button id='btn-back' onClick={handleClose} className={classes.button}>
                    {t('common:btnCancel')}
                  </Button>
                  <Dialog
                    open={openCancel}
                    onClose={closeConfirmCancelModel}
                    aria-labelledby='form-dialog-title'
                  >
                    <DialogContent>{t('form:discardChanges')}</DialogContent>
                    <DialogActions>
                      <Button
                        type='submit'
                        onClick={async () => {
                          closeConfirmCancelModel();
                          handleClose();
                        }}
                      >
                        {t('common:yes')}
                      </Button>
                      <Button onClick={closeConfirmCancelModel} autoFocus>
                        {t('common:no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    id='btn-hover-fx'
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    disabled={!isValid || isSubmitting}
                  >
                    {t('service:lblEditService')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Toast toastList={list} />
    </div>
  );
}

EditServiceDialog.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  details: PropTypes.object,
  setLastUpdated: PropTypes.func,
};
