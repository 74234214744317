import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SuspenseView from '../../../../suspense-view';
import Toast from '../../../../common/snackbar/toast';

import { removeProviderServiceTeamMember } from '../../../../../api/provider-service-team';
import { formatPhoneNumber } from '../../../../../utils/numberUtils';
import {
  setHandleSuccessProperties,
  setHandleErrorProperties,
} from '../../../../../utils/toastUtils';

import { useTranslation } from 'react-i18next';
import { buttonStyles } from '../../../../dialogs/customStyles/customStyles';

import { FaPhoneAlt, FaTrash } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { RiBuildingFill } from 'react-icons/ri'
import CustomIcon from '../../../../../components/customIcons/CustomIcons'
import { MdMessage, MdModeEditOutline } from 'react-icons/md'
import './serviceTeamCard.scss'

import {
  VALUE_ROLE_NAME_ADMIN,
  VALUE_ROLE_NAME_STAFF,
  VALUE_ROLE_NAME_SUPPORT,
  VALUE_ROLE_NAME_VOLUNTEER,
} from '../../../../common/constants';

const dialogContentStyles = {
  fontSize: '1.5rem',
  fontWeight: '600',
  color: 'rgb(26, 80, 161)',
  marginBottom: '1.5rem',
  marginTop: '1rem',
  marginLeft: '1rem'
}

export default function ServiceTeamListItemView({ details, setLastUpdated }) {
  const { t, ready } = useTranslation(['common', 'serviceTeam', 'error']);

  /**
   * Methods for handling the delete confirmation dialog
   */
  const [openDelete, setOpenDelete] = useState(false);

  const openConfirmDeleteModel = () => {
    setOpenDelete(true);
  };

  const closeConfirmDeleteModel = () => {
    setOpenDelete(false);
  };

  const handleConfirmYes = async (providerId, userId) => {
    const { success, errorMessage } = await removeProviderServiceTeamMember(
      providerId,
      userId
    );
    if (success) {
      setLastUpdated();
      handleSuccess();
    } else {
      // TODO: Show error message on the screen
      console.log(errorMessage);
      handleError();
    }
  };

  // Methods for success fail snackbars
  const [list, setList] = useState([]);
  let successProperties = setHandleSuccessProperties(
    t('common:lblSuccess'),
    t('common:lblSuccessTeamMemberDeleted')
  );

  const handleSuccess = () => {
    setList([...list, successProperties]);
  };

  let errorProperties = setHandleErrorProperties(
    t('common:lblError'),
    t('common:msgTeamMemberDeleteError')
  );
  const handleError = () => {
    setList([...list, errorProperties]);
  };

  // User Role Name Localization
  const localizeUserType = (userType) => {
    if (userType === VALUE_ROLE_NAME_ADMIN) {
      return t('serviceTeam:lblRoleNameAdmin');
    }

    if (userType === VALUE_ROLE_NAME_STAFF) {
      return t('serviceTeam:lblRoleNameStaff');
    }

    if (userType === VALUE_ROLE_NAME_SUPPORT) {
      return t('serviceTeam:lblRoleNameSupportWorker');
    }

    if (userType === VALUE_ROLE_NAME_VOLUNTEER) {
      return t('serviceTeam:lblRoleNameVolunteer');
    }
  };

  // Set the local attributes
  let userId = details.user_id;
  let providerId = details.provider_id;

  let firstName = details.first_name;
  let lastName = details.last_name;
  let avatarInitials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  let fullName = firstName + ' ' + lastName;
  let userType = localizeUserType(details.user_type);
  let contactNumber = formatPhoneNumber(details.contact_number);

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <Container>

      <div className='ServiceTeamCard'>
        <div className='ServiceTeamCard__row1'>
          <div className='ServiceTeamCard__row1__col1'>
            {/*<img
                style={{ height: '4.5rem', width: '4.5rem', objectFit: 'cover', borderRadius: '50%' }}
            src='#' alt='profile' /> */}
            <Avatar aria-label='recipe'>
              {avatarInitials}
            </Avatar>
          </div>
          <div className='ServiceTeamCard__row1__col2'>
            <h3>{fullName}</h3>
            <p>{userType}</p>
            <span>{t('serviceTeam:lblTeamMember')}</span>
          </div>
        </div>
        <div className='ServiceTeamCard__row2'>
          <p>{t('serviceTeam:lblContactInformation')}</p>
          <div className='ServiceTeamCard__row2__content'>
            <span><FaPhoneAlt /></span><p>{contactNumber}</p>
          </div>
          <div className='ServiceTeamCard__row2__content'>
            <span><MdEmail /></span><p>{details.email}</p>
          </div>
          <div className='ServiceTeamCard__row2__content'>
            <span><RiBuildingFill /></span><p>{t('serviceTeam:lblTempLocation')}</p>
          </div>
        </div>
        <div className='ServiceTeamCard__row3'>
          <div className='ServiceTeamCard__row3__col1'>
            <CustomIcon
              icon={<MdMessage />}
              iconColor='white'
              BgColor='rgb(56, 172, 201)' />
          </div>
          <div className='ServiceTeamCard__row3__col2'>
            <CustomIcon
              icon={<MdModeEditOutline />}
              iconColor='rgb(56, 172, 201)'
              BgColor='rgb(212, 246, 255)' />
          </div>
          <div className='ServiceTeamCard__row3__col3'>
            <Tooltip
              title={t('serviceTeam:lblRemoveTeamMember')}
              enterDelay={300}
            >
              <IconButton
                aria-label={t('serviceTeam:lblRemoveTeamMember')}
                onClick={openConfirmDeleteModel}
              >
                <CustomIcon
                  icon={<FaTrash />}
                  iconColor='rgb(56, 172, 201)'
                  BgColor='rgb(212, 246, 255)'
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Dialog
          open={openDelete}
          onClose={closeConfirmDeleteModel}
          maxWidth={'xs'}
          aria-labelledby='service-team-removal-dialog-title'
          aria-describedby='service-team-removal-dialog-description'
        >
          <h2 style={dialogContentStyles} id='service-team-removal-dialog-title'>
            {t('serviceTeam:lblConfirmDelete')}
          </h2>
          <DialogContent>
            <DialogContentText style={buttonStyles} id='service-team-removal-dialog-description'>
              {t('serviceTeam:lblConfirmDeleteMessage')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={buttonStyles}
              onClick={handleConfirmYes.bind(this, providerId, userId)}
            >
              {t('common:yes')}
            </Button>
            <Button style={buttonStyles} onClick={closeConfirmDeleteModel} autoFocus>
              {t('common:no')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* not sure where the best place to put Toast is */}
        <Toast toastList={list} dismissTime={5000} />
      </div>


    </Container>
  );
}

ServiceTeamListItemView.propTypes = {
  details: PropTypes.object,
  setLastUpdated: PropTypes.func,
};

