import React from 'react';

import ProfileNavigationTabs from './navigation-tabs/navigationTabs';
import ProfileSubHeaderImage from './profileSubHeaderImage/profileSubHeaderImage';

import useAuthDataContext from '../../../auth/hooks/useAuthDataContext';
import { useMediaQuery } from '../company-details/mediaQueries';
import ProfileDetailsPanel from './profileDetailsPanel/profileDetailsPanel'
import './profilePage.scss';

export default function ClientDetailsPage() {
  const { userDetails } = useAuthDataContext();

  const isMedia = useMediaQuery("(max-width: 900px)");
  const userId = userDetails.id;

  return (
    <div className="ProfileDetailsPage">

      <div className="ProfileDetailsPage__col-1">
        <ProfileSubHeaderImage />
      </div>

      {isMedia ? (
        <div className="ProfileDetailsPage__tablet-container">
          <div className="ProfileDetailsPage__tablet-container__col-2">
            <ProfileDetailsPanel userDetails={userDetails} />
          </div>

          <div className="ProfileDetailsPage__tablet-container__col-3">
            <ProfileNavigationTabs userId={userId} />
          </div>
        </div>
      ) : (
        <div className="ProfileDetailsPage__container-for-col-2-and-3">
          <div className="ProfileDetailsPage__container-for-col-2-and-3__col-2">
            <ProfileDetailsPanel userDetails={userDetails} />
          </div>

          <div className="ProfileDetailsPage__container-for-col-2-and-3__col-3">
            <ProfileNavigationTabs userId={userId} />
          </div>
        </div>
      )}

    </div>
  );
}
