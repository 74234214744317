import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addNewUserNeed } from '../../../../api/user-need';
import { RiHandHeartFill } from 'react-icons/ri';
import './dialogStyles.scss';
import ServUsLogo from './assets/ServUsIconWHITE.png';

import Grid from '@material-ui/core/Grid';

const textStyles = {
  marginLeft: '2.5rem',
  marginTop: '-1rem',
  fontSize: '1.4rem',
  color: 'gray',
  fontWeight: '600'
}


const addServiceDialog = ({ show, handleClose, fullName, userId, providerServices, userServices, setUserServices }) => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const [selectedService, setSelectedService] = useState(null)
  const [err, setErr] = useState('')


  const filteredServices = useMemo(() => {
    return providerServices?.filter((ps) => {
      return !userServices?.find((service) => ps.name === service.name)
    })
  })

  const handleSelectService = (service) => {
    if (selectedService !== null && selectedService.id === service.id) {
      setSelectedService(null)
    } else {
      setSelectedService(service)
    }
  }


  const handleAddServiceToUser = async () => {
    if (selectedService === null) {
      setErr('Please Select A Service')
    } else {
      const res = await addNewUserNeed(userId, selectedService)
      setUserServices([...userServices, res.data])
      handleClose()
      if (err !== '') {
        setErr('')
      }
      setSelectedService(null)
    }
  }

  const handleCloseModal = () => {
    if (selectedService !== null) {
      setSelectedService(null)
      handleClose()
    }
    handleClose()
  }

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        aria-labelledby='invite-client-dialog-title'
      >
        <div>
          <DialogTitle className='Stepper__title' id='invite-client-dialog-title'>
            <span style={{ fontSize: '2rem' }}> {`Add New Service For: ${fullName}`}</span>
          </DialogTitle>
          {providerServices && filteredServices && filteredServices.length > 0 ? (
            <p style={textStyles}>Selected: {selectedService !== null ? <span>&#10003; {selectedService.name}</span> : <span>None</span>}</p>
          ) : null}
        </div>

        <DialogContent>

          {providerServices && filteredServices && filteredServices.length > 0 ? (
            filteredServices?.map((service) => (
              <React.Fragment key={service.id}>
                <div onClick={() => handleSelectService(service)}
                  className='ServiceNeedDialogText'
                  id={selectedService && selectedService.id === service.id ? 'Provider-Service-TeamMember-Card-active' : 'Provider-Service-TeamMember-Card-inactive'}
                >
                  <div className='ServiceNeedDialogText__col-1'>
                    <img src={ServUsLogo} alt={ServUsLogo} />
                  </div>
                  <div className='ServiceNeedDialogText__col-2'>
                    <div className='ServiceNeedDialogText__col-2__row'>
                      <span><RiHandHeartFill /></span>
                      <h3>{service.name}</h3>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          ) : (
            <h3 style={{ fontSize: '1.8rem', margin: '2rem 0rem', color: 'gray' }}>All available services have been assigned.</h3>
          )}
          <p style={{ marginLeft: '1rem', color: 'red', fontSize: '1.1rem' }}>{err}</p>

          <Grid container justifyContent='flex-end'>
            <Button
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                padding: '.6rem 1.5rem',
                color: 'rgb(26, 80, 161)',
                backgroundColor: 'rgb(234, 239, 247)'
              }}
              id='btn-back'
              onClick={handleCloseModal}
              variant='text'
              color='inherit'
              aria-label='Close'
            >
              {'Close'}
            </Button>
            {providerServices && filteredServices && filteredServices.length > 0 ? (
              <Button
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  padding: '.6rem 1.5rem',
                  color: '#fff',
                  backgroundColor: 'rgb(26, 80, 161)'
                }}
                id='btn-back'
                onClick={handleAddServiceToUser}
                variant='text'
                color='inherit'
                aria-label='Submit'
              >
                {'Submit'}
              </Button>
            ) : null}

          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

addServiceDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  providerServices: PropTypes.array,
  userServices: PropTypes.array,
  setUserServices: PropTypes.func
};

export default addServiceDialog
