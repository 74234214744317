import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { getEnvVariable } from '../../../../utils/environmentVariables';
import SuspenseView from '../../../suspense-view';
import { useFormikContext } from 'formik';
import { inputStyles } from '../../../common/select/styles';
import './addressSearch.scss';
import { LocationContext } from '../locationContext';

export const AddressSearch = ({ isLoaded, loadError }) => {

  const { setFieldValue } = useFormikContext()
  const { state } = useContext(LocationContext)

  if (loadError) {
    return 'Error';
  }

  if (!isLoaded) {
    return <SuspenseView />;
  }

  const handleSetValues = async (values) => {
    const { label, value } = values

    setFieldValue('address1', label.slice(0, label.indexOf(',')))

    try {
      const geocodeResponse = await geocodeByPlaceId(value.place_id)

      const postalCode = geocodeResponse[0]?.address_components?.filter(
        type => type.types[0] === 'postal_code'
      )[0].long_name

      const city = geocodeResponse[0]?.address_components?.filter(
        type => type.types[0] === 'locality'
      )[0].long_name

      const province = geocodeResponse[0]?.address_components?.filter(
        type => type.types[0] === 'administrative_area_level_1'
      )[0].short_name

      setFieldValue('postalCode', postalCode)
      setFieldValue('city', city)
      setFieldValue('province', province)
      setFieldValue('latitude', geocodeResponse[0]?.geometry.location.lat())
      setFieldValue('longitude', geocodeResponse[0]?.geometry.location.lng())

    } catch (err) {
      console.log(err)
    }
  }

  const handleSetValuesSecondary = (values) => {
    const { label } = values
    setFieldValue('address2', label.slice(0, label.indexOf(',')))
  }

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={getEnvVariable('REACT_APP_API_MAP_KEY')}
        minLengthAutocomplete={2}
        selectProps={{
          placeholder: 'Enter an address',
          defaultInputValue: state.address && state.address.address1 ? state.address.address1 : '',
          onChange: (value) => handleSetValues(value),
          styles: inputStyles,
          noOptionsMessage: () => null,
          components: { DropdownIndicator: () => null, IndicatorSeparator: () => null }
        }}

        autocompletionRequest={{
          types: ['address'],
          componentRestrictions: {
            country: ['ca']
          }
        }}
      />
      <h3 style={{ marginBottom: '-1rem', marginTop: '1.5rem', fontWeight: '600', fontSize: '1.3rem', color: 'rgb(26, 80, 161)' }}>Address Line 2 (optional)</h3>
      <GooglePlacesAutocomplete
        apiKey={getEnvVariable('REACT_APP_API_MAP_KEY')}
        minLengthAutocomplete={2}
        selectProps={{
          placeholder: 'Enter a secondary address',
          defaultInputValue: state.address && state.address.address2 ? state.address.address2 : '',
          onChange: (value) => handleSetValuesSecondary(value),
          styles: inputStyles,
          noOptionsMessage: () => null,
          components: { DropdownIndicator: () => null, IndicatorSeparator: () => null }
        }}

        autocompletionRequest={{
          types: ['address'],
          componentRestrictions: {
            country: ['ca']
          }
        }}
      />
    </div>
  )
}

AddressSearch.propTypes = {
  isLoaded: PropTypes.bool,
  loadError: PropTypes.object,
};

export default AddressSearch;