//===========CUSTOM STYLES================//

//== STYLES FOR FORMS AND DISCARD CHANGES MENU ===//
export const fieldTitleStyles = {
    fontWeight: '600',
    color: 'rgb(26, 80, 161)',
    fontSize: '1.3rem',
    marginBottom: '-1rem',
    marginTop: '1rem'
}

export const fieldTitleStylesLrg = {
    fontWeight: '600',
    color: 'rgb(26, 80, 161)',
    fontSize: '1.8rem',
    marginBottom: '.5rem',
    marginTop: '1rem'
}

export const dialogContentStyles = {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: 'rgb(26, 80, 161)',
    marginBottom: '1.5rem'
}

export const buttonStyles = {
    fontSize: '1.2rem',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '600',
    color: 'rgb(26, 80, 161)',
    textTransform: 'capitalize'
}

export const moreMarginStyles = { //used for extra margin for buttons
    marginTop: '1rem',
    marginBottom: '1rem'
}
// ==== some styles already exist in stepperStyles.scss ===== //
        // id='btn-back' for cancel or back button //
        // id='btn-hover-fx' for hover animation on buttons //
        // className='Stepper__title' for all form titles
//======================================//