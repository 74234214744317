import React, { useEffect, useState } from 'react'
import '../serviceDiscoveryPage.scss';
import PropTypes from 'prop-types';
import Logo from '../assets/ServUsIconWHITE.png';

import { AiOutlineContacts } from 'react-icons/ai';
import CustomIcons from '../../../customIcons/CustomIcons';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';

import { BsCameraVideo, BsPatchCheckFill } from 'react-icons/bs';
import { BiLink } from 'react-icons/bi';
import { getProviderServiceList } from '../../../../api/provider-services';
import ServiceCarousel from '../service-carousel/serviceCarousel';
import { Avatar } from '@mui/material';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  height: 'auto',
  bgcolor: 'background.paper',
  overflow: 'scroll',
  borderRadius: '5px',
  boxShadow: 24,
  border: '2px solid rgb(234, 239, 247)',
  p: 4,
  padding: '2rem',
};

const ServiceCard = ({ location, provider, active, handleActive, idx }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [services, setServices] = useState(null)

  useEffect(async () => {
    const res = await getProviderServiceList(provider.provider.id)
    setServices(res.data)
  }, [])

  const handleContactClick = () => {
    if (active) {
      handleOpen()
    }
  }

  const formattedPhoneNumber =
    '(' + provider.provider.contact_number.slice(0, 3) + ") " +
    provider.provider.contact_number.slice(3, 6) + '-' +
    provider.provider.contact_number.slice(6);

  return (
    <React.Fragment>
      <div
        onClick={() => handleActive(location)}
        id={active && active.id === location.id ? 'card-active' : ''}
        className='ServiceDiscoveryPage__container__cards__card'
      >

        <div className='ServiceDiscoveryPage__container__cards__card__col-1'>
          <img src={Logo} alt={Logo} />
        </div>

        <div className='ServiceDiscoveryPage__container__cards__card__col-2'>
          <div className='ServiceDiscoveryPage__container__cards__card__col-2__row-1'>
            <div className='ServiceDiscoveryPage__container__cards__card__col-2__row-1__titles'>
              <h3>{provider.provider.name}</h3>
            </div>
          </div>

          <div className='ServiceDiscoveryPage__container__cards__card__col-2__row-2'>
            <h4>Test Service {idx + 1} </h4>
            <p>{location.address1}</p>
            <p>{provider.provider.contact_email}</p>
            <p>{provider.provider.website}</p>
          </div>
          <div className='ServiceDiscoveryPage__container__cards__card__col-2__row-3'>
            <span onClick={handleContactClick}>
              <CustomIcons
                icon={<AiOutlineContacts />}
                iconColor={'rgb(234, 239, 247)'}
                BgColor={'rgb(26, 80, 161)'}
              />
            </span>

          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className='Modal-Service'>
              <div className='Modal-Service__titles'>

                <Avatar
                  style={{ border: '4px solid #fff' }}
                  alt="some text"
                  src="https://www.logodesign.net/logo/financial-bars-inside-arrow-534ld.png"
                  sx={{ width: 75, height: 75 }}
                />
                <h2>Test Service {idx + 1}</h2>
                <h4>Provided By: {provider.provider.name}</h4>
                {active ? (
                  <p>{active.address1} {active.city}, {active.postal_code} {active.province}, {active.country}</p>
                ) : <p>loading...</p>}
              </div>


              <ServiceCarousel services={services} />

              <div className='Modal-Service__focus'>
                <h2>Clinical Focus:</h2>
                <div className='Modal-Service__focus__container'>

                  <div className='Modal-Service__focus__container__row'>
                    <span><BsPatchCheckFill /></span>
                    <p>{'Anxiety'}</p>
                  </div>
                  <div className='Modal-Service__focus__container__row'>
                    <span><BsPatchCheckFill /></span>
                    <p>{'Depression'}</p>
                  </div>
                  <div className='Modal-Service__focus__container__row'>
                    <span><BsPatchCheckFill /></span>
                    <p>{'Mental Fatigue'}</p>
                  </div>
                </div>
              </div>

              <div className='Modal-Service__contact'>
                <h2>Contact:</h2>
                <div className='Modal-Service__contact__container'>
                  <div className='Modal-Service__contact__container__row'>
                    <span><AiOutlineMail /></span>
                    <p>{provider.provider.contact_email}</p>
                  </div>
                  <div className='Modal-Service__contact__container__row'>
                    <span><BiLink /></span>
                    <p id='hover-underline'>{provider.provider.website}</p>
                  </div>
                  <div className='Modal-Service__contact__container__row'>
                    <span><AiOutlinePhone /></span>
                    <p>{formattedPhoneNumber}</p>
                  </div>
                  <div
                    id='hover-underline'
                    onClick={() => alert('clicked')}
                    className='Modal-Service__contact__container__row'>
                    <span><BsCameraVideo /></span>
                    <p>{'Schedule An Appointment'}</p>
                  </div>
                </div>
              </div>

            </div>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  )
}

ServiceCard.propTypes = {
  location: PropTypes.object,
  provider: PropTypes.object,
  active: PropTypes.object,
  handleActive: PropTypes.func,
  idx: PropTypes.number,
}

export default ServiceCard;
