import React from 'react'
import PropTypes from 'prop-types';
import './tierCard.scss'

const TierCard = ({ tier, className, icon }) => {

    const { title, subheader, price, description, buttonText } = tier

    return (
        <div className={className}>
            <h2>{title}</h2>
            <div className='TierCard__subhead'>
                <span>{icon}</span> <h5>{subheader}</h5>
            </div>
            <hr />
            <div className='TierCard__price'>
                <p>${price}</p><span>/mo</span>
            </div>

            <div className='TierCard__desc'>
                {description.map((desc) => (
                    <p key={desc}>{desc}</p>
                ))}
            </div>
            <button className='TierCard__btn'>{buttonText}</button>
        </div>
    )
}

TierCard.propTypes = {
    tier: PropTypes.object,
    className: PropTypes.string,
    icon: PropTypes.node
}

export default TierCard
