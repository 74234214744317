import React from 'react';
import logo from '../Rectangle-Logo.png';

function SubHeaderImage() {
  return (
    <div>
      <img height='200' width='100%' src={logo} alt={'Logo'} />
    </div>
  );
}

export default SubHeaderImage;
