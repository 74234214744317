import React from 'react';
import Button from '@material-ui/core/Button';
import useAuthDataContext from '../../../../auth/hooks/useAuthDataContext';

import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

import { AiOutlineHome, AiOutlineUser, } from 'react-icons/ai'
import { BsBuilding } from 'react-icons/bs'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { HiDocumentReport } from 'react-icons/hi'

export default function ProviderAdminOptions() {
  const classes = useStyles();
  const { t } = useTranslation('menuItems');
  const { goToPage } = useAuthDataContext();

  function handleClickDashboard() {
    goToPage('/dashboard');
  }

  function handleClickCompanyDetails() {
    goToPage('/company-details');
  }

  function handleClickClients() {
    goToPage('/company-clients');
  }

  function handleMessageClick() {
    goToPage('/messages');
  }

  return (
    <div className={classes.root}>
      <div hidden>
        <Button
          color='primary'
          className={classes.linkButton}
          startIcon={<AiOutlineHome />}
          onClick={handleClickDashboard}
        >
          {t('menuItems:lblHome')}
        </Button>
      </div>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<BsBuilding />}
        onClick={handleClickCompanyDetails}
      >
        {t('menuItems:lblCompanyDetails')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<AiOutlineUser />}
        onClick={handleClickClients}
      >
        {t('menuItems:lblClients')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<BiMessageSquareDetail />}
        onClick={handleMessageClick}
      >
        {t('menuItems:lblMessages')}
      </Button>
      <Button
        color='primary'
        className={classes.linkButton}
        startIcon={<HiDocumentReport />}
        disabled
      >
        {t('menuItems:lblReports')}
      </Button>
    </div>
  );
}
