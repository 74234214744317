import React from 'react';
import PropTypes from 'prop-types';

import './customIcon.scss'

const CustomIcon = ({ icon, iconColor, BgColor }) => {

    const iconStyles = {
        color: iconColor,
        backgroundColor: BgColor
    }
    return (
        <div className='CustomIcon' style={iconStyles}>
            {icon}
        </div>
    )
}

CustomIcon.propTypes = {
    icon: PropTypes.element,
    iconColor: PropTypes.string,
    BgColor: PropTypes.string
};

export default CustomIcon