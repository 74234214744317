import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: '1px solid rgb(234, 239, 247)',
  },
  navMobile: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid rgb(234, 239, 247)',
  },
  tabItem: {
    color: 'rgb(26, 80, 161)',
    textTransform: 'capitalize',
    fontSize: '1.1rem',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
  }
}));

export default useStyles;
